import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import './../css/UserRegisterSuccessComponent.css'
import Constants from '../Constants';
import mailCheckLogo from './../mail_check.png'


export default function UserRegisterSuccessComponent() {


   return (
    <div id="user-registration-wrapper-id" class="user-registration-wrapper">

      <div id="user-registration-success-id" class="user-registration-success">

        <img src={mailCheckLogo} className="App-logo" alt="logo" />
        <h1>Please confirm the registration email.</h1>
        <h2>To complete your registration, please check your email and click on confirmation link</h2>


      </div>

    </div>
  );
}