import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useOutletContext } from 'react-router-dom';
import { useLanguage } from '../../../homepage/components/LanguageContext';

const OpeningTimes = () => {
  const { user} = useOutletContext();
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const daysDe = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
  const [times, setTimes] = useState({
    1: { beforeLunchStart: '', beforeLunchEnd: '', afterLunchStart: '', afterLunchEnd: '' },
    2: { beforeLunchStart: '', beforeLunchEnd: '', afterLunchStart: '', afterLunchEnd: '' },
    3: { beforeLunchStart: '', beforeLunchEnd: '', afterLunchStart: '', afterLunchEnd: '' },
    4: { beforeLunchStart: '', beforeLunchEnd: '', afterLunchStart: '', afterLunchEnd: '' },
    5: { beforeLunchStart: '', beforeLunchEnd: '', afterLunchStart: '', afterLunchEnd: '' },
    6: { beforeLunchStart: '', beforeLunchEnd: '', afterLunchStart: '', afterLunchEnd: '' },
  });

  const convertToTime = (minutes) => {
    const hours = String(Math.floor(minutes / 60)).padStart(2, '0');
    const mins = String(minutes % 60).padStart(2, '0');
    return `${hours}:${mins}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_JAVA_BASE_URL+'/doctor-center-slot/apt-slots/${user.centers[0].id}');
        if (response.ok) {
          const data = await response.json();

          const prefilledTimes = { ...times }; // Create a copy of current times

          data.dayWiseSlots.forEach((slot) => {
            prefilledTimes[slot.dow] = {
              beforeLunchStart: slot.beforeLunchSt !== null ? convertToTime(slot.beforeLunchSt) : '',
              beforeLunchEnd: slot.beforeLunchEn !== null ? convertToTime(slot.beforeLunchEn) : '',
              afterLunchStart: slot.afterLunchSt !== null ? convertToTime(slot.afterLunchSt) : '',
              afterLunchEnd: slot.afterLunchEn !== null ? convertToTime(slot.afterLunchEn) : '',
            };
          });

          setTimes(prefilledTimes); 
        } else {
          console.error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []); 

  const handleTimeChange = (day, period, time, value) => {
    setTimes((prev) => ({
      ...prev,
      [day]: { ...prev[day], [period]: value },
    }));
  };
  const convertToMinutes = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const slotData = Object.keys(times).reduce((acc, day) => {
      const { beforeLunchStart, beforeLunchEnd, afterLunchStart, afterLunchEnd } = times[day];
      if (beforeLunchStart && beforeLunchEnd) {
        acc.push({
          dow: Number(day),
          stTime: convertToMinutes(beforeLunchStart),
          enTime: convertToMinutes(beforeLunchEnd),
          inventory: 4,
        });
      }

      if (afterLunchStart && afterLunchEnd) {
        acc.push({
          dow: Number(day),
          stTime: convertToMinutes(afterLunchStart),
          enTime: convertToMinutes(afterLunchEnd),
          inventory: 4, 
        });
      }

      return acc;
    }, []);

    try {
      const response = await fetch(process.env.REACT_APP_JAVA_BASE_URL+`/doctor-center-slot/upsert/DOW_WHITELISTING/${user.centers[0].id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(slotData),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('API Response:', data);
        alert('Slots saved successfully!');
      } else {
        console.error('Error saving slots');
        alert('Failed to save slots');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while saving slots');
    }
  };

  const { language,langStrings} = useLanguage();
  const ST = langStrings.DOCEDIT.EDUCATIONINFO;

  

  return (
    <Form onSubmit={handleSubmit}>
      {language == 'English'? days.map((day, index) => (
        <Row className="mb-3 align-items-center" key={index}>
          <Col xs={12} md={2}>
            <Form.Label>{day}</Form.Label>
          </Col>
          <Col xs={12} md={5} className="mt-2 mt-md-0">
            <Form.Group controlId={`beforeLunch-${day}`}>
              <Form.Label>Before Lunch</Form.Label>
              <Row>
                <Col>
                  <Form.Control
                    type="time"
                    placeholder="Start Time"
                    value={times[index + 1].beforeLunchStart}
                    onChange={(e) => handleTimeChange(index + 1, 'beforeLunchStart', 'beforeLunch', e.target.value)}
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="time"
                    placeholder="End Time"
                    value={times[index + 1].beforeLunchEnd}
                    onChange={(e) => handleTimeChange(index + 1, 'beforeLunchEnd', 'beforeLunch', e.target.value)}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col xs={12} md={5} className="mt-2 mt-md-0">
            <Form.Group controlId={`afterLunch-${day}`}>
              <Form.Label>After Lunch</Form.Label>
              <Row>
                <Col>
                  <Form.Control
                    type="time"
                    placeholder="Start Time"
                    value={times[index + 1].afterLunchStart}
                    onChange={(e) => handleTimeChange(index + 1, 'afterLunchStart', 'afterLunch', e.target.value)}
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="time"
                    placeholder="End Time"
                    value={times[index + 1].afterLunchEnd}
                    onChange={(e) => handleTimeChange(index + 1, 'afterLunchEnd', 'afterLunch', e.target.value)}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
      )):
      daysDe.map((day, index) => (
        <Row className="mb-3 align-items-center" key={index}>
          <Col xs={12} md={2}>
            <Form.Label>{day}</Form.Label>
          </Col>
          <Col xs={12} md={5} className="mt-2 mt-md-0">
            <Form.Group controlId={`beforeLunch-${day}`}>
              <Form.Label>Vor dem Mittagessen</Form.Label>
              <Row>
                <Col>
                  <Form.Control
                    type="time"
                    placeholder="Start Time"
                    value={times[index + 1].beforeLunchStart}
                    onChange={(e) => handleTimeChange(index + 1, 'beforeLunchStart', 'beforeLunch', e.target.value)}
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="time"
                    placeholder="End Time"
                    value={times[index + 1].beforeLunchEnd}
                    onChange={(e) => handleTimeChange(index + 1, 'beforeLunchEnd', 'beforeLunch', e.target.value)}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col xs={12} md={5} className="mt-2 mt-md-0">
            <Form.Group controlId={`afterLunch-${day}`}>
              <Form.Label>Nach dem Mittagessen</Form.Label>
              <Row>
                <Col>
                  <Form.Control
                    type="time"
                    placeholder="Start Time"
                    value={times[index + 1].afterLunchStart}
                    onChange={(e) => handleTimeChange(index + 1, 'afterLunchStart', 'afterLunch', e.target.value)}
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="time"
                    placeholder="End Time"
                    value={times[index + 1].afterLunchEnd}
                    onChange={(e) => handleTimeChange(index + 1, 'afterLunchEnd', 'afterLunch', e.target.value)}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
      ))
      }
      <Button style={{backgroundColor:'#f35b2a'}} type="submit">
        {ST.label2}
      </Button>
    </Form>
  );
};

export default OpeningTimes;
