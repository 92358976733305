import { useState, useEffect } from 'react';
import { MultiSelect } from "react-multi-select-component";
import './MultiSelectCompo2.css';

var _ = require('lodash');

export default function MultiSelectCompo2({
    id, 
    name, 
    label, 
    defaultValue, 
    dataAr,
    setFieldState
}) {
  const [transformedAr, setTransformedAr] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    let ar = []
    console.log(dataAr);
    dataAr.forEach(function(each){
      let newObj = {
        label: each.name,
        value: each.id
      }
      ar.push(newObj);
    })
    setTransformedAr(ar);
  }, [dataAr])




function getFieldDataForFacetsInForm(ar){
    let res = [];
    ar.forEach(function(each){
        res.push(each.value);
    })
    return res;
}
function changeHandler(e, name){
    setSelectedValues(e);
    setFieldState(e);
}
  return (
      <MultiSelect
        options={transformedAr}
        value={selectedValues}
        // label={label}
        onChange={(e) => changeHandler(e, name)}
        hasSelectAll={false}
        overrideStrings={{
          selectSomeItems: label,
        }}
      />
  );
}
