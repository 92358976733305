import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import { useLanguage } from '../../../homepage/components/LanguageContext';

const AboutInfo = () => {
  const { user } = useOutletContext();

  const publicInsurance = [
    { value: 'ÖGK', label: 'ÖGK' },
    { value: 'SVS', label: 'SVS' },
    { value: 'BVAEB', label: 'BVAEB' },
    { value: 'KFA', label: 'KFA' },
  ];

  const privateInsurance = [
    { value: 'Wahlarzt', label: 'Wahlarzt' },
    { value: 'Privat', label: 'Privat' },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const { langStrings} = useLanguage();
  const ST = langStrings.DOCEDIT.INSURANCEINFO;

  return (
    <div className="p-4 bg-white rounded shadow-sm">
      <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formlanguage">
          
          <Form.Label>{ST.label}</Form.Label>
          <Select 
            options={privateInsurance}
            isMulti
            placeholder="Private Insurance"
            
            styles={{
              control: (base) => ({
                ...base,
                height: 'calc(1.5em + 0.75rem + 2px)', // Bootstrap's form-control height
                border: '1px solid #ced4da', // Bootstrap's border
                borderRadius: '0.375rem', // Bootstrap's border-radius
                fontSize: '1rem', // Bootstrap's font-size
                width: '100%', // Full-width
                paddingRight: '0.75rem', // Bootstrap padding
                boxShadow: 'none', // Remove shadow
                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out', // Bootstrap transition
              }),
              input: (base) => ({
                ...base,
                fontSize: '1rem', // Matching Bootstrap font-size
                color: '#495057', // Bootstrap input text color
              }),
              menu: (base) => ({
                ...base,
                zIndex: 1000, // Ensure dropdown shows above other content
                backgroundColor: 'white',
                border: '1px solid #ced4da',
                borderRadius: '0.375rem', // Match Bootstrap's dropdown menu styling
                boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)', // Light shadow for the dropdown
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected ? '#0d6efd' : 'white', // Bootstrap primary color for selected
                color: state.isSelected ? 'white' : '#495057', // White text when selected, black otherwise
                // ':hover': {
                //   backgroundColor: '#e9ecef', // Bootstrap-like hover effect
                // },
              }),
              multiValue: (base) => ({
                ...base,
                backgroundColor: '#e9ecef', // Light gray background for selected values
                borderRadius: '0.375rem',
              }),
              multiValueLabel: (base) => ({
                ...base,
                fontSize: '0.875rem', // Match Bootstrap label sizing for multi-select
                padding: '0.25rem 0.5rem',
              }),
              multiValueRemove: (base) => ({
                ...base,
                color: '#495057', // Bootstrap text color
                ':hover': {
                  backgroundColor: '#ced4da', // Hover effect for removing tag
                  color: 'black',
                },
              }),
            }}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formlanguage">
          
          <Form.Label>{ST.label1}</Form.Label>
          <Select 
            options={publicInsurance}
            isMulti
            placeholder="Public Insurance"
            
            styles={{
              control: (base) => ({
                ...base,
                height: 'calc(1.5em + 0.75rem + 2px)', // Bootstrap's form-control height
                border: '1px solid #ced4da', // Bootstrap's border
                borderRadius: '0.375rem', // Bootstrap's border-radius
                fontSize: '1rem', // Bootstrap's font-size
                width: '100%', // Full-width
                paddingRight: '0.75rem', // Bootstrap padding
                boxShadow: 'none', // Remove shadow
                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out', // Bootstrap transition
              }),
              input: (base) => ({
                ...base,
                fontSize: '1rem', // Matching Bootstrap font-size
                color: '#495057', // Bootstrap input text color
              }),
              menu: (base) => ({
                ...base,
                zIndex: 1000, // Ensure dropdown shows above other content
                backgroundColor: 'white',
                border: '1px solid #ced4da',
                borderRadius: '0.375rem', // Match Bootstrap's dropdown menu styling
                boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)', // Light shadow for the dropdown
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected ? '#0d6efd' : 'white', // Bootstrap primary color for selected
                color: state.isSelected ? 'white' : '#495057', // White text when selected, black otherwise
                // ':hover': {
                //   backgroundColor: '#e9ecef', // Bootstrap-like hover effect
                // },
              }),
              multiValue: (base) => ({
                ...base,
                backgroundColor: '#e9ecef', // Light gray background for selected values
                borderRadius: '0.375rem',
              }),
              multiValueLabel: (base) => ({
                ...base,
                fontSize: '0.875rem', // Match Bootstrap label sizing for multi-select
                padding: '0.25rem 0.5rem',
              }),
              multiValueRemove: (base) => ({
                ...base,
                color: '#495057', // Bootstrap text color
                ':hover': {
                  backgroundColor: '#ced4da', // Hover effect for removing tag
                  color: 'black',
                },
              }),
            }}
          />
        </Form.Group>
        
        <Button style={{ backgroundColor: '#f35b2a' }} type="submit">
          {ST.label2}
        </Button>
      </Form>
    </div>
  );
};

export default AboutInfo;
