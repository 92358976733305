import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useSignIn, useIsAuthenticated, useAuthUser } from 'react-auth-kit';
import { useNavigate, Link } from "react-router-dom";

import Constants from '../../../Constants';
import './css/ManageDoctorProfile.css'

export default function ManageDoctorProfile() {
  return (
    <>
      <ul class="doc-profile"> 
        <li class="doc-profile-list"><Link to="profile-settings"> Personal Information  <i class="zmdi zmdi-chevron-right"></i></Link></li> {/* Display Name, Profile Image, Languages Spoken, Status (Add listing / Verified / Remove Listing) */}
        <li class="doc-profile-list"><Link to="manage-center"> Manage my center <i class="zmdi zmdi-chevron-right"></i></Link></li> {/* Display Name, Profile Image, Languages Spoken, Status (Add listing / Verified / Remove Listing) */}
        <li class="doc-profile-list"><Link to="manage-slot"> Manage my slots <i class="zmdi zmdi-chevron-right"></i></Link></li> {/* Display Name, Profile Image, Languages Spoken, Status (Add listing / Verified / Remove Listing) */}
        <li class="doc-profile-list"><Link to="about-me"> About Me <i class="zmdi zmdi-chevron-right"></i></Link></li> {/* Display Name, Profile Image, Languages Spoken, Status (Add listing / Verified / Remove Listing) */}
        <li class="doc-profile-list"><Link to="thought-leadership"> Thought leadership <i class="zmdi zmdi-chevron-right"></i></Link></li> {/* Display Name, Profile Image, Languages Spoken, Status (Add listing / Verified / Remove Listing) */}
      </ul>
    </>
  )
}