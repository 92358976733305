import React, { useState, useRef } from "react";
import Select, { components } from "react-select";

const Input = (props) => <components.Input {...props} isHidden={false} />;

const EditableSelect = ({ options, onInputChange, onChange, placeholder }) => {
  const [value, setValue] = useState();
  const [inputValue, setInputValue] = useState("");
  const selectRef = useRef();

  const handleInputChange = (newValue, { action }) => {
    if (action === "input-change") {
      setInputValue(newValue);
      onInputChange(newValue);
    }
  };

  const handleChange = (option) => {
    setValue(option);
    setInputValue(option ? option.label : "");
    onChange(option);
  };

  const DropdownIndicator = () => null;

  return (
    <Select
      ref={selectRef}
      options={options}
      isClearable={true}
      value={value}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleChange}
      placeholder={placeholder}
      controlShouldRenderValue={false}
      components={{ Input,DropdownIndicator }}
      styles={{
        control: (base) => ({
          ...base,
          height: '60px', 
          border: '1px solid black',
          borderRadius: '0px',
          width: '100%',
          fontSize:  '19px',
          paddingLeft: '40px',
          fontWeight: 'bold'

        }),
        input: (base) => ({
          ...base,
          fontSize: '25px',
          fontWeight: 'bold',
          zIndex:2,
        }),
        menu: (base) => ({
          ...base,
          opacity: 1,
          backgroundColor: 'white',
          color: 'black',
          zIndex:2,
        }),
        option: (base, state) => ({
          ...base,
          opacity: 1,
          color: state.isSelected ? 'white' : 'black',
          zIndex:2,
        }),
      }}
      
    />
  );
};

export default EditableSelect;
