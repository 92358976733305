import DoctorRegisterSuccessComponent from "../components/UserRegisterSuccessComponent";
import HamburgerComponent from "../components/HamburgerComponent";
import ZocdocLogoComponent from "../components/ZocdocLogoComponent";
import UserLoginComponent from "../components/UserLoginComponent";

export default function Home() {
    return (
        <div class="homepage" id="homepage-id">
            <ZocdocLogoComponent />
            <HamburgerComponent />
            <DoctorRegisterSuccessComponent />
            <UserLoginComponent />
        </div>
    )
}