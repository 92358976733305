import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios'; 
import { useLanguage } from '../../../homepage/components/LanguageContext';

const AboutInfo = () => {
  const { user } = useOutletContext();
  console.log(user);
  const [about, setAbout] = useState(user.about || "");
  const [newPatients, setNewPatients] = useState(user.info?.['1_a_n_p'] || "No");
  const [waitingTime, setWaitingTime] = useState(user.info?.['2_a_w_t'] || 0);
  const { langStrings} = useLanguage();
  const ST = langStrings.DOCEDIT.ABOUTINFO;
  const patientOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedData = {
      about,
      info: {
        '1_a_n_p': newPatients, 
        '2_a_w_t': waitingTime, 
      }
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_JAVA_BASE_URL}/doctor?doctorId=${user.id}`, 
        updatedData, 
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      alert("Doctor details updated successfully");
      console.log('Update successful:', response.data);
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };


  
  
  

  return (
    <div className="p-4 bg-white rounded shadow-sm">
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formAbout">
          <Form.Label>{ST.label}</Form.Label>
          <Form.Control
            as="textarea"
            rows={8} 
            value={about}
            placeholder="Tell us about yourself"
            onChange={(e) => setAbout(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formNewPatients">
          <Form.Label>{ST.label1}</Form.Label>
          <Select 
            value={patientOptions.find(option => option.value === newPatients)}
            onChange={(option) => setNewPatients(option.value)}
            options={patientOptions}
            placeholder="New Patient Acceptance"
            styles={{
              control: (base) => ({
                ...base,
                height: 'calc(1.5em + 0.75rem + 2px)', 
                border: '1px solid #ced4da', 
                borderRadius: '0.375rem', 
                fontSize: '1rem', 
                width: '100%', 
                paddingRight: '0.75rem',
                boxShadow: 'none', 
                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
              }),
              input: (base) => ({
                ...base,
                fontSize: '1rem',
                color: '#495057',
              }),
              menu: (base) => ({
                ...base,
                zIndex: 1000,
                backgroundColor: 'white',
                border: '1px solid #ced4da',
                borderRadius: '0.375rem',
                boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected ? '#0d6efd' : 'white',
                color: state.isSelected ? 'white' : '#495057',
              }),
              multiValue: (base) => ({
                ...base,
                backgroundColor: '#e9ecef',
                borderRadius: '0.375rem',
              }),
              multiValueLabel: (base) => ({
                ...base,
                fontSize: '0.875rem',
                padding: '0.25rem 0.5rem',
              }),
              multiValueRemove: (base) => ({
                ...base,
                color: '#495057',
                ':hover': {
                  backgroundColor: '#ced4da',
                  color: 'black',
                },
              }),
            }}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formWaitingTime">
          <Form.Label>{ST.label2}</Form.Label>
          <Form.Control
            type="number"
            value={waitingTime}
            placeholder="Enter average waiting time (in Days)"
            onChange={(e) => setWaitingTime(e.target.value)}
          />
        </Form.Group>

        <Button style={{ backgroundColor: '#f35b2a' }} type="submit">
          {ST.label3}
        </Button>
      </Form>
    </div>
  );
};

export default AboutInfo;
