import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FaBars } from 'react-icons/fa';
import './NavbarForgot.css'; 
import { useLanguage } from './LanguageContext';
import englighFlag from "./../../../../src/images/english_flag.svg";
import germanFlag from "./../../../../src/images/german_flag.png";
import { useNavigate, useLocation } from 'react-router-dom';



function NavBarForgot() {
  const { language, langStrings, toggleLanguage } = useLanguage();
  const navigate =  useNavigate();
  const location = useLocation();
  
  const takeToContactUs = ()=>{
    navigate('/contact-us', { state: { from: location } });
  }
  
  const takeToAreYouDoc = ()=>{
    navigate('/new-are-you-a-doctor', { state: { from: location } });
  }

  const ST = langStrings.HOMEPAGE;
  return (
    <Navbar expand="lg" fixed="top" className="bg-body-tertiary custom-navbar">
      <Container fluid>
        <Navbar.Brand href="/" className="navbar-brand">
        <img src='./BrowserIcon.png' height={40} width={40} />
        Arztme
        
        </Navbar.Brand>
   
        <Navbar.Toggle aria-controls="navbarScroll" className="ms-auto">
          <FaBars size={24} color="black" />  
        </Navbar.Toggle>
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-2 my-lg-0" navbarScroll>
      
          </Nav>
          <Nav className="nav-links">
            <Nav.Link href="#" className="nav-link-custom">
              {language === 'English' ? (
                <img onClick={toggleLanguage} className="navImgEng1" src={englighFlag} alt="English flag" />
              ) : (
                <img onClick={toggleLanguage} className="navImgGer1" src={germanFlag} alt="German flag" />
              )}
            </Nav.Link>
            <Nav.Link onClick={() => takeToAreYouDoc()} className="nav-link-custom">{ST.NAV_BAR_SECTION.DOCTOR_OR_THERAPIST_BTN.label}</Nav.Link>
            <Nav.Link onClick={() => takeToContactUs()} className="nav-link-custom">{ST.NAV_BAR_SECTION.HELP.label}</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBarForgot;
