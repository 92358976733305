import Constants from '../../../Constants';

const userDataGetUrl = process.env.REACT_APP_JAVA_BASE_URL + Constants.USER_DATA_GET_REL_URL;

export default async function getUserData(userId) {
  let reqUrl = reqUrlFromQuery(userDataGetUrl,userId);

  let resp = await callGetDataHandler(reqUrl);



  return resp;
}

function reqUrlFromQuery(userLoginUrl, userId) {
    return userLoginUrl + '?userId=' + userId;
}

async function callGetDataHandler(url) {

  let resp = await callUserGetApi(url);

  let respJson = await resp.json();
  

  if (resp && resp.status === 200)
    return { success: true, respJson: respJson };
  else
    return { success: false, respJson: respJson };

}

const callUserGetApi = async (url) => {

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  });
  let resp = await response;

  return resp;
}
