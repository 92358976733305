import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert, Card } from 'react-bootstrap';
import NavbarForgot from './NavbarForgot.jsx';
import Footer from './Footer.jsx';
import { useLanguage } from './LanguageContext';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, SetError] = useState(false);
  const { language, langStrings } = useLanguage();
  const ST = langStrings.FORGOTPAGE;

  const handleSubmit = async(e) => {
    SetError(false);
    e.preventDefault();
    const url = process.env.REACT_APP_JAVA_BASE_URL + process.env.REACT_APP_FORGOT_PASSWORD + "?email=" + email + "&language=" + language;
    try {
      const response = await fetch(url,{
        method:'POST',
        headers:{
          'Content-Type': 'application/json'
        },
      });
      if(response.ok){
        setSubmitted(true);
      }else{
        console.log('Failed to send Email');
      }
    } catch (error) {
      SetError(true);
      console.log(`Eroor`,error)
    }
  };

  return (
    <div className='bg-light'>
      <NavbarForgot />
      <Container className="fluid d-flex justify-content-center align-items-center min-vh-100 py-5">
        <Row className="w-100">
          <Col xs={12} md={8} lg={6} className="mx-auto">
            <Card className="p-4 shadow-sm" style={{ minHeight: '400px' }}>
              <Card.Body>
                <h3 className="text-center mb-4">{ST.label}</h3>
                {
                  error && (
                    <Alert variant="danger" className="text-center">
                      {ST.label4}
                    </Alert>
                  )
                }
                {submitted ? (
                  <Alert variant="success" className="text-center">
                    {ST.label3}
                  </Alert>
                ) : (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formEmail" className="mb-3">
                      <Form.Label>{ST.label1}</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Button style={{ backgroundColor: '#f35b2a', fontWeight: 'bold', boxShadow:'none' }} type="submit" className="w-100 fw-bold">
                      {ST.label2}
                    </Button>
                  </Form>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default ForgotPassword;
