let Constants = {
    ES_BASE_URL: 'http://3.79.182.219:9200',
    
    DOCTOR_REGISTRATION_REL_URL: '/doctor',
    USER_LOGIN_REL_URL: '/user/login',
    USER_DATA_GET_REL_URL: '/user/id',
    USER_PROFILE_UPDATE_REL_URL: '/user/uploadFile',
    DOCTOR_PROFILE_UPDATE_REL_URL: '/doctor/uploadFile',
    DOCTOR_PROFILE_URL: "/manage-doctor-profile",
    DOCTOR_PROFILE:{
        PROFILE_SETTINGS: "/profile-settings",
        MANAGE_CENTER: "/manage-center",
        MANAGE_SLOT: "/manage-slot",
        ABOUT_ME: "/about-me"

    },
    ENUMS:{
        EDIT_COMPONENT_TYPES:{
            TEXT : 'TEXT',
            MULTISELECT: 'MULTISELECT'
        }
    }
}

module.exports = Constants;

