var _ = require('lodash');

export default function SingleSelectComponent({
    name,
    arialabel,
    defaultValue,
    dataArAsObj,
    onChangeHandler
}) {

    function getMyCompo(){
        //'SingleSelectCompo select got name: ' + name + " values: " + JSON.stringify(dataArAsObj));
        if(dataArAsObj && !Array.isArray(dataArAsObj)){
            return (Object.keys(dataArAsObj).map(
                (prop) => {
                    return (
                        <option key={prop} value={prop} > {dataArAsObj[prop]} </option>
                    )
                }));
        } else if(dataArAsObj && Array.isArray(dataArAsObj)){
            return (dataArAsObj.map(
                (each) => {
                    return (
                        <option key={each.id} value={each.id} > {each.name} </option>
                    )
                }));
        } else
            return null;
    }

    return (
        <select name={name} class="form-select" aria-label={arialabel} onChange={onChangeHandler}>
            <option selected>{defaultValue}</option>
            {getMyCompo()}
        </select>
    );
}
