import React from 'react'
import Footer from './Footer';
import NavBarList from '../../docsearch/components/NavBarList'
import { useLanguage } from './LanguageContext.js';


const Impressium = () => {
  const { language, langStrings, toggleLanguage } = useLanguage();
  const ST = langStrings.IMPRESSIUMPAGE;
  return (
    <div>
    <NavBarList />
    <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
      <div className="container p-5 rounded shadow bg-white text-dark" style={{ width: '80%', height: 'auto', minHeight: '60vh' }}>
        <h2 className="text-center mb-4">{ST.label}</h2>
        <p>
          
        </p>
        <p>
          
        </p>
        <p>
          
        </p>
      </div>
    </div>
    <Footer/>
    </div>
  )
}

export default Impressium