import { useState, useEffect } from 'react';
import Constants from '../../../../Constants';
import { useNavigate } from "react-router-dom";
import './../css/ManageUserProfile.css'
var _ = require('lodash');

export default function AddressDiv(props) {
  const [searchTextTop, setSearchTextTop] = useState("Please register your details.");

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");

  let [addressDivVal, setAddressDivVal] = useState(1);

  const [templateData, setTemplateData] = useState(); //useState({state:[],city:[],country:[],countryData:[]});

  const [doctorData, setDoctorData] = useState(props.doctorData);

  let onceCountrySet = false;
  let onceStateSet = false;
  let onceCitySet = false;

  useEffect(() => {
    setDoctorData(props.doctorData);
  }, [props.doctorData]);

  const templateApiUrl = process.env.REACT_APP_JAVA_BASE_URL + process.env.REACT_APP_TEMPLATE_API_REL_URL

  const fetchTemplateApiData = async (url) => {


    const data = await callTemplateApi(url);
    setCountries(data.countryData);
    setTemplateData(data);
  }

  const callTemplateApi = async (url) => {

    const response = await fetch(url);
    const body = await response.json();
   
    return body;
  }

  // componentDidMount
  useEffect(() => {
    fetchTemplateApiData(templateApiUrl)
  }, [])


  const handleCountryChange = (e) => {
   
    setCountry(e.target.value);

    let countryIdx = countries.findIndex(country => country.id == e.target.value);

    setStates(countries[countryIdx].states);
    setCities("");

    setState("");
    setCity("");
  };

  const handleStateChange = (e) => {
    
    setState(e.target.value);

    let stateIdx = states.findIndex(state => state.id == e.target.value);

    setCities(states[stateIdx].cities);
    setCity("");
  };

  const handleCityChange = (e) => {
   
    setCity(e.target.value);
  };


  function handleCtaClick() {
    
    setAddressDivVal(++addressDivVal);
  }

  function addressDetails(doctor){

    

    if( !doctor || !doctor.centers || doctor.centers.length == 0 || !templateData)
      return {};

   
    let countryObj = templateData.country.find(country => country.id == doctor.centers[0].countryId);
    let stateObj = templateData.state.find(state => state.id == doctor.centers[0].stateId);
    let cityObj = templateData.city.find(city => city.id == doctor.centers[0].cityId);

    let obj = {
      country: countryObj && countryObj.name,
      state: stateObj && stateObj.name,
      city: cityObj && cityObj.name
    };

    if(doctor.centers){
        let center = doctor.centers[0];
        obj.center = center;
    }

   

    return obj;

    /*
            "id": 12345,
            "doctorId": 9,
            "countryId": 1,
            "stateId": 3,
            "cityId": 2,
            "address": "B-211122223333",
            "street": "Alpha2 main road",
            "area": "Alpha2",
            "pincode": 201304,
            "lat": 11.11,
            "lng": 22.22,
            "phone": "919891166236",
            "createdAt": "2024-08-11T09:34:38.800+00:00",
            "updatedAt": "2024-08-11T09:34:38.800+00:00",
            "status": 1
    */

  }
    

  let doctorAddress = addressDetails(doctorData);


  function handleFormSubmit(e){

    function callbackFunc(err, res){
        if(err){
            alert("Some issue. Could not save details. Please try again later");
        } else{
            handleCtaClick();
        }
    }
    props.handleFormSbt(e,callbackFunc);
  }

  //'doctor : ' + JSON.stringify(doctorData) + ' doctorAddress : ' + JSON.stringify(doctorAddress));

  if(addressDivVal % 2 == 1)

    return (
      <div class="manage-profile-item phone-number">

      <div class="title"><span> Address </span></div>
      <div class="cta"><span id='cancel' onClick={handleCtaClick}> Add/Edit </span></div>
      <div class="value"><span> &nbsp;</span></div>
      
      <div class="value"><span> Country: {_.get(doctorAddress,'country', '')} </span></div>
      <div class="value"><span> State: {_.get(doctorAddress,'state', '')} </span></div>
      <div class="value"><span> City: {_.get(doctorAddress,'city', '')} </span></div>
      <div class="value"><span> Address: {_.get(doctorAddress,'center.address', '')} </span></div>
      <div class="value"><span> Street: {_.get(doctorAddress,'center.street', '')} </span></div>
      <div class="value"><span> Area: {_.get(doctorAddress,'center.area', '')} </span></div>
      <div class="value"><span> Pincode: {_.get(doctorAddress,'center.pincode', '')} </span></div>

      <div class="value"><span> &nbsp;</span></div>

      <div class="helptext"><span> Expand to see your address or edit it.</span></div>
      </div>
    )
  else return (

    
    <div class="manage-profile-item phone-number">

      <div class="title"><span> Address </span></div>
      <div class="cta"><span id='cancel' onClick={handleCtaClick}> Cancel </span></div>

      
      <form class="user-profile-update-form" onSubmit={handleFormSubmit} method="post">
        
        <input type="hidden" name="center.id" value={_.get(doctorAddress,'center.id', '')} class="user-reg-textbox" placeholder="" />
        <input type="hidden" name="center.doctorId" value={_.get(doctorAddress,'center.doctorId', '')} class="user-reg-textbox" placeholder="" />

        <div class="user-profile-update-div">
          <label class="labelc" htmlFor="center.countryId">Country</label>
          <i class="zmdi zmdi-hc-lg"></i>
          {/*"haha chaaap diya country")*/}
          <select onChange={handleCountryChange} name="center.countryId" class="user-reg-textbox" placeholder="Please select your country" >
            <option value="">Select Country</option>
            {countries ? (countries.map(
              (country) => {
              
                let countryId =  _.get(doctorAddress,'center.countryId', '');

                if(countryId == country.id){
                    if( !onceCountrySet ){
                        onceCountrySet = true;
                        var lol = {
                            target: {
                                value: countryId
                            }
                        };

                        //setStates(country.states);
                        //setTimeout(handleCountryChange, 100, lol);
                    }
                    return( <option  key={country.id} value={country.id} > {country.name} </option> )
                    //return( <option selected key={country.id} value={country.id} > {country.name} </option> ) 
                }
                else   
                    return( <option key={country.id} value={country.id} > {country.name} </option> )
                
              })) : <option selected key={_.get(doctorAddress,'center.countryId', '')} value={_.get(doctorAddress,'center.countryId', '')} > {doctorAddress.country} </option>

            }
          </select>
        </div>

        <div class="user-profile-update-div">
          <label class="labelc" htmlFor="center.stateId">State</label>
          <i class="zmdi zmdi-hc-lg"></i>
          {/*"haha chaaap diya state")*/}
          <select disabled={country == ""} onChange={handleStateChange} name="center.stateId" class="user-reg-textbox" placeholder="Please select your state" >
            <option value="">Select State</option>

           {/* <option selected key={_.get(doctorAddress,'center.stateId', '')} value={_.get(doctorAddress,'center.stateId', '')} > {doctorAddress.state} </option> */}
            
            {states ? (states.map(
              (state) => {
               
                
                let stateId =  _.get(doctorAddress,'center.stateId', '');

                
                if(stateId == state.id){
                    if( !onceStateSet ){
                        onceStateSet = true;
                        var lol = {
                            target: {
                                value: stateId
                            }
                        };
                       
                        //setTimeout(handleStateChange, 100, lol);
                    }
                    return( <option selected key={state.id} value={state.id} > {state.name} </option> ) 
                }
                else   
                    return( <option key={state.id} value={state.id} > {state.name} </option> )

              })) : <option selected key={_.get(doctorAddress,'center.stateId', '')} value={_.get(doctorAddress,'center.stateId', '')} > {doctorAddress.state} </option>

            }
          </select>
        </div>

        <div class="user-profile-update-div">
          <label class="labelc" htmlFor="center.cityId">City</label>
          <i class="zmdi zmdi-hc-lg"></i>
          {/*"haha chaaap diya city")*/}
          <select disabled={state == ""} onChange={handleCityChange} name="center.cityId" class="user-reg-textbox" placeholder="Please select your city" >
            <option value="">Select City</option>
            {cities ? (cities.map(
              (city) => {
               

                let cityId =  _.get(doctorAddress,'center.cityId', '');
                if(cityId == city.id){

                    if( !onceCitySet ){
                        onceCitySet = true;
                        var lol = {
                            target: {
                                value: cityId
                            }
                        };
                        //setTimeout(handleCityChange, 100, lol);
                    }
                    return( <option selected key={city.id} value={city.id} > {city.name} </option> ) 
                }
                else   
                    return( <option key={city.id} value={city.id} > {city.name} </option> )

              })) : null

            }
          </select>
        </div>


        <div class="user-profile-update-div">
          <label class="labelc" htmlFor="address">Address</label>
          <i class="zmdi zmdi-hc-lg"></i>
          <input type="text" name="center.address" defaultValue={_.get(doctorAddress,'center.address', '')} class="user-reg-textbox" placeholder="Please enter your address" />
        </div>

        <div class="user-profile-update-div">
          <label class="labelc" htmlFor="street">Street</label>
          <i class="zmdi zmdi-hc-lg"></i>
          <input type="text" name="center.street" defaultValue={_.get(doctorAddress,'center.street', '')} class="user-reg-textbox" placeholder="Please enter your street" />
        </div>

        <div class="user-profile-update-div">
          <label class="labelc" htmlFor="area">Area</label>
          <i class="zmdi zmdi-hc-lg"></i>
          <input type="text" name="center.area" defaultValue={_.get(doctorAddress,'center.area', '')} class="user-reg-textbox" placeholder="Please enter your area" />
        </div>

        <div class="user-profile-update-div">
          <label class="labelc" htmlFor="pincode">Pincode</label>
          <i class="zmdi zmdi-hc-lg"></i>
          <input type="text" name="center.pincode" defaultValue={_.get(doctorAddress,'center.pincode', '')} class="user-reg-textbox" placeholder="Please enter your pincode" />
        </div>

        <div class="user-profile-update-div">
          <label class="labelc" htmlFor="phone">Phone</label>
          <i class="zmdi zmdi-hc-lg"></i>
          <input type="text" name="center.phone" defaultValue={_.get(doctorAddress,'center.phone', '')} class="user-reg-textbox" placeholder="Please enter phone number for patients to contact" />
        </div>

        <div class="user-registration-item-div">
          <button type="submit" class="text-search-submit" id="user-register-submit" placeholder="" > Save</button>
        </div>
      </form>
    </div>
  );
}