import Constants from '../../../Constants';

const doctorProfileImageUploadUrl = process.env.REACT_APP_JAVA_BASE_URL + Constants.DOCTOR_PROFILE_UPDATE_REL_URL;

export default async function updateDoctorProfileImageData(e, doctorId, fileImg) {
    

    // Prevent the browser from reloading the page
    e.preventDefault();

    // Read the form data
    const formData = new FormData();
    formData.append("File" , fileImg);

    let resp = await callUpdateApiHandler(doctorProfileImageUploadUrl + '?' + 'doctorId=' + doctorId, formData);

    // 'SID THiS IS THE API RESP');
    // resp);

    return resp;
  }


  async function callUpdateApiHandler(url, body) {
    let resp = await callUpdateApi(url, body);

    let respJson = await resp;//resp.json();

    if (resp && resp.status === 200)
      return { success: true, respJson: respJson };
    else
      return { success: false, respJson: respJson };
  }

  const callUpdateApi = async (url, body) => {

    // Post with fetchAPI
    const response = await fetch(url, {
      method: 'POST',
      body: body  /*,
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },*/
    });
    let resp = await response;

    return resp;
  }