import { useState, useEffect } from 'react';
import Constants from '../../../Constants';
import { useNavigate } from "react-router-dom";
import './css/ManageUserProfile.css'

export default function AddressDiv(props) {
  const [searchTextTop, setSearchTextTop] = useState("Please register your details.");

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");

  let [addressDivVal, setAddressDivVal] = useState(1);

  const [templateData, setTemplateData] = useState(); //useState({state:[],city:[],country:[],countryData:[]});

  const [userData, setUserData] = useState(props.userData);

  useEffect(() => {
    setUserData(props.userData);
  }, [props.userData]);

  const templateApiUrl = process.env.REACT_APP_JAVA_BASE_URL + process.env.REACT_APP_TEMPLATE_API_REL_URL

  const fetchTemplateApiData = async (url) => {
 

    const data = await callTemplateApi(url);
    setCountries(data.countryData);
    setTemplateData(data);
  }

  const callTemplateApi = async (url) => {

    const response = await fetch(url);
    const body = await response.json();
    //body);
    return body;
  }

  // componentDidMount
  useEffect(() => {
    fetchTemplateApiData(templateApiUrl)
  }, [])


  const handleCountryChange = (e) => {
    
    setCountry(e.target.value);

    let countryIdx = countries.findIndex(country => country.id == e.target.value);

    setStates(countries[countryIdx].states);
    setCities("");

    setState("");
    setCity("");
  };

  const handleStateChange = (e) => {
   
    setState(e.target.value);

    let stateIdx = states.findIndex(state => state.id == e.target.value);

    setCities(states[stateIdx].cities);
    setCity("");
  };

  const handleCityChange = (e) => {
    
    setCity(e.target.value);
  };


  function handleCtaClick() {
   
    setAddressDivVal(++addressDivVal);
  }

  function addressDetails(user){

    if(!user || !templateData)
      return {};

    //'FINDINGSID user.countryId: ' + user.countryId + '   user.stateId: ' + user.stateId + '   user.cityId: ' +  user.cityId);
    let countryObj = templateData.country.find(country => country.id == user.countryId);
    let stateObj = templateData.state.find(state => state.id == user.stateId);
    let cityObj = templateData.city.find(city => city.id == user.cityId);

    return {
      country: countryObj && countryObj.name,
      state: stateObj && stateObj.name,
      city: cityObj && cityObj.name,
      address: user.address,
      pincode: user.pincode
    }
  }


  let userAddress = addressDetails(userData);

  //'user : ' + JSON.stringify(userData) + ' userAddress : ' + JSON.stringify(userAddress));

  if(addressDivVal % 2 == 1)

    return (
      <div class="manage-profile-item phone-number">

      <div class="title"><span> Address </span></div>
      <div class="cta"><span id='cancel' onClick={handleCtaClick}> Add/Edit </span></div>
      <div class="value"><span> &nbsp;</span></div>
      
      { (userAddress && userAddress.country) ?  (<div class="value"><span> Country: {userAddress.country} </span></div>) : '' }
      { (userAddress && userAddress.state) ?  (<div class="value"><span> State: {userAddress.state} </span></div>) : '' }
      { (userAddress && userAddress.city) ?  (<div class="value"><span> City: {userAddress.city} </span></div>) : '' }
      { (userAddress && userAddress.address) ?  (<div class="value"><span> Address: {userAddress.address} </span></div>) : '' }
      { (userAddress && userAddress.pincode) ?  (<div class="value"><span> Pincode: {userAddress.pincode} </span></div>) : '' }

      <div class="helptext"><span> Expand to see your address or edit it.</span></div>
      </div>
    )
  else return (

    
    <div class="manage-profile-item phone-number">

      <div class="title"><span> Address </span></div>
      <div class="cta"><span id='cancel' onClick={handleCtaClick}> Cancel </span></div>

      <form class="user-profile-update-form" onSubmit={props.handleFormSbt} method="post">
        <div class="user-profile-update-div">
          <label class="labelc" for="country">Country</label>
          <i class="zmdi zmdi-hc-lg"></i>
          {/*"haha chaaap diya country")*/}
          <select onChange={handleCountryChange} id="country" name="country" class="user-reg-textbox" placeholder="Please select your country" >
            <option value="">Select Country</option>
            {countries ? (countries.map(
              (country) => {
                
                return(
                  <option key={country.id} value={country.id} > {country.name} </option>
                )
                /*
                let opt = (userData && country.id == userData.countryId) ? 'selected' : '';
                if(opt == 'selected'){
                  'COUNTRY ALREADY SELECTED WAS : ' + country.name);
                  //setStates(country.states);
                }
                return <option key={country.id} value={country.id} selected> {country.name} </option>;

                 return (userData && country.id == userData.countryId) ? 
                  ( 
                  <option key={country.id} value={country.id} selected> {country.name} </option>
                ) : 
                 (
                  <option key={country.id} value={country.id} > {country.name} </option>
                )
                */
              })) : null

            }
          </select>
        </div>

        <div class="user-profile-update-div">
          <label class="labelc" for="state">State</label>
          <i class="zmdi zmdi-hc-lg"></i>
          {/*"haha chaaap diya state")*/}
          <select disabled={country == ""} onChange={handleStateChange} id="state" name="state" class="user-reg-textbox" placeholder="Please select your state" >
            <option value="">Select State</option>
            {states ? (states.map(
              (state) => {
                
                
                return(
                <option key={state.id} value={state.id} > {state.name} </option>
                )
              })) : null

            }
          </select>
        </div>

        <div class="user-profile-update-div">
          <label class="labelc" for="city">City</label>
          <i class="zmdi zmdi-hc-lg"></i>
          {/*"haha chaaap diya city")*/}
          <select disabled={state == ""} onChange={handleCityChange} id="city" name="city" class="user-reg-textbox" placeholder="Please select your city" >
            <option value="">Select City</option>
            {cities ? (cities.map(
              (city) => {
               
                return (
                  <option key={city.id} value={city.id} > {city.name} </option>
                )
              })) : null

            }
          </select>
        </div>


        <div class="user-profile-update-div">
          <label class="labelc" for="address">Address</label>
          <i class="zmdi zmdi-hc-lg"></i>
          <input type="text" id="address" name="address" class="user-reg-textbox" placeholder="Please enter your address" />
        </div>

        <div class="user-profile-update-div">
          <label class="labelc" for="pincode">Pincode</label>
          <i class="zmdi zmdi-hc-lg"></i>
          <input type="text" id="pincode" name="pincode" class="user-reg-textbox" placeholder="Please enter your address" />
        </div>

        <div class="user-registration-item-div">
          <button type="submit" class="text-search-submit" id="user-register-submit" placeholder="" > Save</button>
        </div>
      </form>
    </div>
  );
}