import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useLanguage } from './LanguageContext';
import NavBarList from '../../docsearch/components/NavBarList';
import Footer from './Footer';

const CookieManagement = () => {
  const { language, toggleLanguage } = useLanguage();  
  const [cookiePreferences, setCookiePreferences] = useState(localStorage.getItem('cookieConsent') == 'accepted');

  useEffect(() => {
    // const savedPreferences = JSON.parse(localStorage.getItem('cookiePreferences'));
    // if (savedPreferences) setCookiePreferences(savedPreferences);
  }, []);

  const handleSavePreferences = () => {

    if (cookiePreferences) {
      localStorage.setItem('cookieConsent', 'accepted'); 
      Cookies.set('languageSelect',language)
    } else {
      localStorage.setItem('cookieConsent', 'declined');
      if(language == 'German'){
        toggleLanguage();
      }
      Cookies.remove('languageSelect');
      
    }

    // localStorage.setItem('cookiePreferences', JSON.stringify(cookiePreferences));

    // Cookies.set('_LOLZZ', 'auth_token_here', { expires: 7, sameSite: 'Strict' });

    // if (cookiePreferences.functional) {
    //   Cookies.set('languageSelect', language, { expires: 7 });
    // } else {
    //   Cookies.remove('languageSelect');
    // }
    // alert("Preferences saved!");
  };

  return (
    <div>
      <NavBarList />
      <div className="container text-dark my-4">
        <div className="row">
          <div className="col-md-8 offset-md-2 mt-5 mb-5">
            <h2 className="text-center my-4">Manage Your Cookie Preferences</h2>
            <form onSubmit={(e) => {e.preventDefault();handleSavePreferences();}}className="p-4 border rounded shadow-sm bg-light">
              <div className="form-check mb-3">
              <p>We respect the privacy of our users. Your data, your choices.</p>
                <input type="checkbox" className="form-check-input" id="functionalCookies"
                  checked={cookiePreferences}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    setCookiePreferences(isChecked);
                  }}
                />
                
                <label className="form-check-label" htmlFor="functionalCookies">
                  Do you Consent to accept cookies?
                </label>
              </div>
              <button type="submit" onClick={handleSavePreferences} style={{backgroundColor:'#f35b2a',boxShadow:'none'}} className="btn btn-primary w-100 fw-bold ">
                Save Preferences
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CookieManagement;
