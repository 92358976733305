import React, { useState, useEffect } from 'react';
import { IoSearch, IoLocation } from "react-icons/io5";
import Select from 'react-select';
import { useNavigate,useLocation } from 'react-router-dom';
import axios from 'axios';  
import debounce from 'lodash/debounce';  
import { useLanguage } from './LanguageContext.js';
const SearchBar = ({
  detectedLocation
}) => {
  const { langStrings} = useLanguage();
  const ST = langStrings.HOMEPAGE;
  const defaultLocationOptions = [
    { value: 'Vienna', label: 'Vienna' },
    { value: 'Salzburg', label: 'Salzburg' },
    { value: 'Innsbruck', label: 'Innsbruck' },
    { value: 'Graz', label: 'Graz' },
    { value: 'Linz', label: 'Linz' },
    { value: 'Klagenfurt', label: 'Klagenfurt' },
    { value: 'St_pölten', label: 'St. Pölten' },
    { value: 'Bregenz', label: 'Bregenz' },
    { value: 'Villach', label: 'Villach' },
    { value: 'Wels', label: 'Wels' },
  ];
  const [specialtyOptions, setSpecialtyOptions] = useState();
  const [locationOptions, setLocationOptions] = useState(defaultLocationOptions);
  const [selectedSpecialty, setSelectedSpecialty] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectType,setSelectedType] = useState('');
  const [urlNikalo, UrlSetKaro] = useState(''); 
  const [placeholderText, setPlaceholderText] = useState('Searching for a Doctor');
  const [locationPlaceholder, setLocationPlaceholder] = useState(ST.SEARCH_SECTION.INPUT.label1);
  const [specialtyInputValue, setSpecialtyInputValue] = useState(''); 
  const [locationInputValue, setLocationInputValue] = useState(''); 
  const navigate = useNavigate();
  const location = useLocation(); 
  let SUGGEST_URL = process.env.REACT_APP_JAVA_BASE_URL+process.env.REACT_APP_SUGGEST_URL;
  const fetchDefaultSpecialtyOptions = async () => {
      try {
        const response = await axios.get(`${SUGGEST_URL}`);
        const data = response.data && response.data.data ? response.data.data : [];
        const options = data.map(item => ({
          value: item.value,
          label: item.title,
          url: item.url,
        }));
        setSpecialtyOptions(options);
      } catch (error) {
        console.error('Error fetching default specialty options:', error);
        setSpecialtyOptions([{ value: '', label: 'Error fetching options' }]);
      }
  };
  useEffect(()=>{
    fetchDefaultSpecialtyOptions();
  }, []);
  const fetchSpecialtyOptions = debounce((inputValue) => {
    if (inputValue) {
      axios.get(`${SUGGEST_URL}?text=${inputValue}&type=searchkey`)
        .then(response => {
          const data = response.data && response.data.data ? response.data.data : [];   
          if (data.length > 0) {
            const options = data.map(item => ({
              value: item.value,  
              label: item.title,
              url: item.url
            }));
            setSpecialtyOptions(options);
          } else {
            setSpecialtyOptions([{ value: '', label: 'No options found' }]);
          }
        })
        .catch(error => {
          console.error('Error fetching specialty options:', error);
          setSpecialtyOptions([{ value: '', label: 'Error fetching options' }]); 
        });
    } else {
      fetchDefaultSpecialtyOptions();
    }
  }, 300);
  const fetchLocationOptions = debounce((inputValue) => {
    if (inputValue) {
      axios.get(`${SUGGEST_URL}?text=${inputValue}&type=locationkey`)
        .then(response => {
          const data = response.data && response.data.data ? response.data.data : [];
          if (data.length > 0) {
            
            const options = data.map(item => ({
              value: item.value,  
              label: item.title, 
            }));
            setLocationOptions(options);
            setSelectedType(data[0].type);
          } else {
            setLocationOptions([{ value: '', label: 'No options found' }]);
          }
        })
        .catch(error => {
          console.error('Error fetching location options:', error);
          setLocationOptions([{ value: '', label: 'Error fetching options' }]); 
        });
    } else {
      setLocationOptions(defaultLocationOptions);
    }
  }, 100);
  let Myurl = '/new-search?';
  const leJaoFiltersPar = (e) => {
    e.preventDefault(); 
    const specialty = selectedSpecialty ? selectedSpecialty.value : '';
    const location = selectedLocation ? selectedLocation.value : '';
    const url = urlNikalo;
    Myurl += `searchkey=${encodeURIComponent(specialty)}`
    Myurl += `&locationkey=${encodeURIComponent(location)}`;
    if(url){
      Myurl +=  `&${url}`;
    }
    console.log(Myurl);
    navigate(Myurl);
  }
  const detectLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const locationName = await getCity(latitude, longitude);
          //alert(locationName);
          setSelectedLocation({ value: locationName, label: locationName });
          setLocationInputValue(locationName);
          setLocationPlaceholder(locationName);
        },
        (err) => {
          console.error('Error detecting location:', err);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };
  const getCity = async (latitude, longitude) => {
    const apiKey = process.env.REACT_APP_GEOCODING_API_KEY;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      console.log(data);
      if (data.status === "OK") {
        console.log('data okay hai sher');
        const addressComponents = data.results[0].address_components;
        const cityComponent = addressComponents.find(component => component.types.includes("locality"))
        console.log(cityComponent.long_name);
        return cityComponent ? cityComponent.long_name : "Unknown location";
      } else {
        console.error("Geocode was not successful for the following reason:", data.status);
        return "Unknown location";
      }
    } catch (error) {
      console.error("Error fetching city name:", error);
      return "Unknown location";
    }
  };
  useEffect(() => {
    detectLocation();
  }, []);
  const DropdownIndicator = () => null;
  useEffect(() => {
    const updatePlaceholder = () => {
      if (window.innerWidth > 1440) {
        setPlaceholderText('Searching for a Doctor');
      } else {
        setPlaceholderText('Searching for a Doctor');
      }
    };
    updatePlaceholder();
    window.addEventListener('resize', updatePlaceholder);
    return () => window.removeEventListener('resize', updatePlaceholder);
  }, []);
  
    

  return (
    <div className="container ">
      <div className="row justify-content-center">
        <form className="row " method="get" style={{ width: '100%',paddingTop:'0px' }}>
          <div className="col-lg-4 col-md-6 col-sm-12 position-relative mb-3 no-gap">
            <IoSearch style={{ zIndex: '99', position: 'absolute', left: '14px', top: '50%', transform: 'translateY(-50%)', fontSize: '35px', color: '#f35b2a' }} />
            <Select
              components={{ DropdownIndicator }} 
              styles={{
                control: (base) => ({
                  ...base,
                  height: '60px', 
                  border: '1px solid black',
                  borderRadius: '0px',
                  width: '100%',
                  fontSize:  window.innerWidth<430? '19px':'20px',
                  paddingLeft: '40px',
                  fontWeight: 'bold'
                }),
                input: (base) => ({
                  ...base,
                  fontSize: '25px',
                  zIndex: 99,

                }),
                menu: (base) => ({
                  ...base,
                  opacity: 1,
                  backgroundColor: 'white',
                  color: 'black',
                  zIndex: 99,
                }),
                option: (base, state) => ({
                  ...base,
                  opacity: 1,
                  zIndex: 99,
                  color: state.isSelected ? 'white' : 'black',
                }),
              }}
              onInputChange={(newValue) => {
                setSpecialtyInputValue(newValue); 
                fetchSpecialtyOptions(newValue); 
              }}
              onChange={(option) => {
                setSelectedSpecialty(option);
                setSpecialtyInputValue(option ? option.label : '');
                setSelectedType(option ? option.type : '');
                
                UrlSetKaro(option ? option.url : '');
              }}
              options={specialtyOptions}  
              placeholder={placeholderText}
            />
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 position-relative mb-3 no-gap">
            <IoLocation style={{ zIndex: '1', position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', fontSize: '35px', color: '#f35b2a' }} />
            <Select
              components={{ DropdownIndicator }} 
              styles={{
                control: (base) => ({
                  ...base,
                  height: '60px',
                  border: '1px solid black',
                  borderRadius: '0px',
                  fontSize: '20px',
                  width: '100%',
                  paddingLeft: '40px',
                  fontWeight: 'bold'
                }),
                input: (base) => ({
                  ...base,
                  fontSize: '25px',
                  fontWeight: 'bold'
                }),
                menu: (base) => ({
                  ...base,
                  opacity: 1,
                  backgroundColor: 'white',
                  color: 'black',
                }),
                option: (base, state) => ({
                  ...base,
                  opacity: 1,
                  color: state.isSelected ? 'white' : 'black',
                }),
              }}
              onInputChange={(newValue) => {
                setLocationInputValue(newValue); 
                fetchLocationOptions(newValue);
              }}
              onChange={(option) => {
                setSelectedLocation(option); 
                setLocationInputValue(option ? option.label : '');
              
              }}
              options={locationOptions}  
              placeholder={locationPlaceholder}
            />
          </div>

          <div className="col-lg-2 col-md-12 col-sm-12 no-gap width-reduce">
            <button 
              onClick={leJaoFiltersPar}
              type="submit" 
              name="text-search-submit-btn" 
              className="btn btn-primary w-100"
              style={{ height: '60px', fontSize: '25px', fontWeight: 'bold', backgroundColor: '#f35b2a', border: 'solid', borderWidth: '1px', borderColor: 'black', borderRadius: '0px', boxShadow:'none' }}
            >
              {ST.SEARCH_SECTION.INPUT.label2}
            </button>
          </div>
        </form>
      </div>
      
      <style jsx>{`
        @media (min-width: 1024px) {
          .no-gap {
            margin: 0 !important;
            padding: 0 !important;
          }
          .custom-shift{
            margin-left:2px;
          }
        }
        
        @media (min-width: 1920px) {
          .custom-shift {
            transform: translateX(-145px);
            
          }
        }
        @media (min-width: 1440px) {
          .custom-shift {
            transform: translateX(65px);
            padding:0px;
            margin-right:0px;
          }
          
          .width-reduce{
            width: 25%;
          }
        }
        @media(max-width: 768px) {
          .try-shift{
          padding-top:0px;
          }
        }
        
          @media (min-width: 1920px) {
          .custom-shift {
            transform: translateX(-133px);
            margin-top:50px;
            margin-bottom:50px;
            margin-right:37px;
          }
        }
      `}</style>
    </div>
  );
};

export default SearchBar;


