import React from 'react';
import './ImageScroll.css';

const ImageScroll = ({user}) => {
  
  const Images = (user.bgImage?.split(',')||null);
  console.log(Images);
  const profileImagePrefix = process.env.REACT_APP_JAVA_BASE_URL + process.env.REACT_APP_USER_PROFILE_IMAGE_CONTENT_REL_PATH;
  return (
    
    <div className="image-scroll-container">
        {Images  ? 
        <div className="image-scroll-wrapper">
          {
        Images.map((image, index) => {
          return (
            <img 
              src={`${profileImagePrefix}${image}`}
              alt={`Image ${index + 1}`} 
            />
          );
        }) 
      }
        </div>
        : 
       <div className="image-scroll-wrapper">
        <img src="/images.png" alt="Image 1" />
        <img src="/images 1.png" alt="Image 2" />
        <img src="/images 2.png" alt="Image 3" />
        <img src="/images 3.png" alt="Image 4" />
        <img src="/images 4.png" alt="Image 5" />
        <img src="/images 5.png" alt="Image 6" />
        </div>
      }
    </div>
    
  );
};

export default ImageScroll;
