import React, { useEffect, useState } from 'react';
import ProfileHeader from './ProfileHeader';
import ProfileSidebar from './ProfileSidebar';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './ProfilePage.css';
import { useLocation, Outlet } from 'react-router-dom';
import { FaArrowRight, FaArrowLeft, FaArrowUp, FaArrowDown } from 'react-icons/fa';
import NavBarDocEdit from '../NavBarDocEdit';
import Footer from '../../../homepage/components/Footer';

const ProfilePage = () => {
  const location = useLocation();
  const initialUser = location.state?.user;
  const [user, setUser] = useState(initialUser);
  const docLocation = location.state?.docLocation;

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };
  
  useEffect(() => {
    const handleResize = (() => {
      setIsMobile(window.innerWidth < 768);
    });
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <NavBarDocEdit />
      <Container className="profile-container">
        <Button 
          className="toggle-btn d-md-none" 
          onClick={toggleSidebar}
        >
          {sidebarVisible ? <FaArrowUp /> : <FaArrowDown />}
        </Button>
        <Row>
          <Col md={3} className={`bg-light sidebar ${sidebarVisible ? 'd-block' : 'd-none d-md-block'}`}>
            <ProfileSidebar user={user} docLocation={docLocation} toggleSidebar={toggleSidebar} />
          </Col>
          <Col md={isMobile && sidebarVisible ? 12 : 9}>
            <ProfileHeader user={user} docLocation={docLocation} />
            <Outlet context={{ user, docLocation, setUser }} />
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default ProfilePage;
