//import DocSearchComponent from "./../components/DocSearchComponent";
import HamburgerComponent from "../components/HamburgerComponent.js";
import ZocdocLogoComponent from "../components/ZocdocLogoComponent.js";

import UserRegisterNew from "../features_new/registration/components/UserRegisterNew.jsx"

export default function UserRegisterPageNew() {
    return (
        <div class="homepage" id="homepage-id">
            <UserRegisterNew />
        </div>
    )
}