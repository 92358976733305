import FinalDoctorRegisterComponent from "./../components/FinalDoctorRegisterComponent";
import HamburgerComponent from "./../components/HamburgerComponent";
import ZocdocLogoComponent from "./../components/ZocdocLogoComponent";

export default function FinalDoctorRegister() {
    return (
        <div class="homepage" id="homepage-id">
            <ZocdocLogoComponent />
            <HamburgerComponent />
            <FinalDoctorRegisterComponent />
        </div>
    )
}