import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Footer.css';
import { useLanguage } from './LanguageContext.js';
import { useSignOut,useIsAuthenticated, useAuthUser } from 'react-auth-kit';

const Footer = () => {
  const { language, langStrings, toggleLanguage } = useLanguage();
  const ST = langStrings.HOMEPAGE;

  const [Mobile,setMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const Resize = ()=>{
      setMobile(window.innerWidth < 768);
    }
    window.addEventListener('resize',Resize);
    return ()=> window.removeEventListener('resize',Resize);
  },[])

  const navigate = useNavigate();
  const location = useLocation();
  const leJaoHome = () => {
    navigate('/');
  };
  const TakeToHelp = () => {
    navigate('/contact-us');
  }
  let isAuthenticated = useIsAuthenticated();
  const authUser = useAuthUser();
  const takeToDocRegister = () => {
    if(isAuthenticated() && authUser().isUser){
      navigate('/new-doctor-register');
    }else{
      alert('Please login to continue');
      navigate('/user-login', { state: { from: 'List Your Practice' } });
    }
  }

  const takeToCareers = () => {
    navigate('/careers');
  }

  const takeToMarketting = () => {
    navigate('/marketing');
  }

  const takeToCookieManagement = () => {
    navigate('/cookie-management');
  }
 
  return (
    <footer style={{backgroundColor:'#24262b'}} className="footer text-white py-4">
      <div className="container">
        <div className="row">
          <div className="col-6 col-md-3 foo-co">
            <ul className="list-unstyled">
              <li><a onClick={leJaoHome} href="">{ST.FOOTER_SECTION.LINKS.label}</a></li>
              <li><a href="/about-us">{ST.FOOTER_SECTION.LINKS.label1}</a></li>
              <li><a style={{cursor:'pointer'}} href={`http://${process.env.REACT_APP_WORDPRESSIP}`}>{ST.FOOTER_SECTION.LINKS.label2}</a></li>
              <li><a onClick={TakeToHelp} href="">{ST.FOOTER_SECTION.LINKS.label3}</a></li>
            </ul>
          </div>
          <div className="col-6 col-md-3 foo-co">
            <ul className="list-unstyled">
              <li><a href="#">{ST.FOOTER_SECTION.LINKS.label4}</a></li>
              <li><a href="#">{ST.FOOTER_SECTION.LINKS.label5}</a></li>
              <li><a href="#">{ST.FOOTER_SECTION.LINKS.label6}</a></li>
              <li><a href="#">{ST.FOOTER_SECTION.LINKS.label7}</a></li>
            </ul>
          </div>
          <div className="col-6 col-md-3 foo-co">
            <ul className="list-unstyled">
              <li><a onClick={takeToCookieManagement} href="">{ST.FOOTER_SECTION.LINKS.label8}</a></li>
              <li><a href="#">{ST.FOOTER_SECTION.LINKS.label9}</a></li>
              <li><a href="#">{ST.FOOTER_SECTION.LINKS.label10}</a></li>
              <li><a href="/impressium">{ST.FOOTER_SECTION.LINKS.label11}</a></li>
            </ul>
          </div>
          <div className="col-6 col-md-3 foo-co">
            <ul className="list-unstyled">
              <li><a onClick={takeToDocRegister} href="">{ST.FOOTER_SECTION.LINKS.label12}</a></li>
              <li><a onClick={takeToMarketting} href=''>{ST.FOOTER_SECTION.LINKS.label13}</a></li>
              <li><a onClick={takeToCareers} href=''>{ST.FOOTER_SECTION.LINKS.label14}</a></li>
              {language === 'English' ? (
                <li onClick={toggleLanguage}><a href="">{ST.FOOTER_SECTION.LINKS.label15}</a></li>
              ) : (
                <li onClick={toggleLanguage}><a href="">{ST.FOOTER_SECTION.LINKS.label15}</a></li>
              )}
              
            </ul>
          </div>
        </div>
        <div className="row mt-4 justify-content-center">
          {
            
            window.innerWidth < 520?
            <div className="col-auto">
            <div className="social-links d-flex justify-content-center">
            <a href="https://www.facebook.com/people/Arztme/61568272712289/?sk=about" className="text-white"><i className="fab fa-facebook-f"></i></a>
            <a href="https://www.instagram.com/arztme/profilecard/?igsh=azVleGhzeGpyanBr" className="text-white"><i className="fab fa-instagram"></i></a>
            <a href="https://x.com/Arztme" className="text-white"><i className="fab fa-twitter"></i></a>
            <a href="https://medium.com/@Arztme" className="text-white"><i className="fab fa-medium"></i></a>
            <a href="https://www.linkedin.com/company/arztme/?viewAsMember=true" className="text-white"><i className="fab fa-linkedin-in"></i></a>
            </div>
            <div className="social-links d-flex justify-content-center">
              <a href="https://www.youtube.com/@Arztme" className="text-white"><i className="fab fa-youtube"></i></a>
              <a href="https://www.reddit.com/user/Inevitable-Art-9478/" className="text-white"><i className="fab fa-reddit"></i></a>
              <a href="https://www.quora.com/profile/Arztme/posts" className="text-white"><i className="fab fa-quora"></i></a>
              <a href="https://at.pinterest.com/doctorsalzburg" className="text-white"><i className="fab fa-pinterest"></i></a>
            </div>
          </div>:
             <div className="col-auto">
             <div className="social-links d-flex justify-content-center">
               <a href="https://www.facebook.com/people/Arztme/61568272712289/?sk=about" className="text-white"><i className="fab fa-facebook-f"></i></a>
               <a href="https://www.instagram.com/arztme/profilecard/?igsh=azVleGhzeGpyanBr" className="text-white"><i className="fab fa-instagram"></i></a>
               <a href="https://x.com/Arztme" className="text-white"><i className="fab fa-twitter"></i></a>
               <a href="https://medium.com/@Arztme" className="text-white"><i className="fab fa-medium"></i></a>
               <a href="https://www.linkedin.com/company/arztme/?viewAsMember=true" className="text-white"><i className="fab fa-linkedin-in"></i></a>
               <a href="https://www.youtube.com/@Arztme" className="text-white"><i className="fab fa-youtube"></i></a>
               <a href="https://www.reddit.com/user/Inevitable-Art-9478/" className="text-white"><i className="fab fa-reddit"></i></a>
               <a href="https://www.quora.com/profile/Arztme/posts" className="text-white"><i className="fab fa-quora"></i></a>
               <a href="https://at.pinterest.com/doctorsalzburg" className="text-white"><i className="fab fa-pinterest"></i></a>
             </div>
           </div>
          }
         
        </div>
      </div>
    </footer>
  );
};

export default Footer;
