import ProfileSettings from "../features/authentication/components/doctor_profile/ProfileSettings";
import AboutMe from "../features/authentication/components/doctor_profile/AboutMe";
import ManageCenter from "../features/authentication/components/doctor_profile/ManageCenter";
import HamburgerComponent from "../components/HamburgerComponent";
import ZocdocLogoComponent from "../components/ZocdocLogoComponent";
import Constants from "../Constants";

export default function ProfileSettingsPage() {

    function loadResponsibleComponent(){
        let href = window.location.href;
        if(href.indexOf(Constants.DOCTOR_PROFILE.PROFILE_SETTINGS) != -1){
            return <ProfileSettings />;
        } else if (href.indexOf(Constants.DOCTOR_PROFILE.ABOUT_ME) != -1){
            return <AboutMe />;
        } else if (href.indexOf(Constants.DOCTOR_PROFILE.MANAGE_CENTER) != -1){
            return <ManageCenter />;
        }
    }
    return (
        <div class="homepage" id="homepage-id">
            {loadResponsibleComponent()}
            {/* <ProfileSettings /> */}
        </div>
    )
}