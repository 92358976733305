import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import Constants from '../../../../Constants';
import { useSignIn, useIsAuthenticated, useAuthUser } from 'react-auth-kit';
import { useNavigate, Link } from "react-router-dom";

export default function AllOptions() {

    function heySid(){

    }

    return (
        <>
            <h2>
                Sid this is About Me Page. Hi I am Doctor Sandeep Sahay. I am best doctor in all of Greater Noida.
            </h2>
        </>
    )
}