import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useOutletContext } from 'react-router-dom';

const SocialInfo = () => {
  const { user } = useOutletContext();
  const [people, setPeople] = useState(user.social || "");
  const handleSubmit = (e) => {
    e.preventDefault();
   
  };
  return (
    <div className="p-4 bg-white rounded shadow-sm">
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formGraduation">
          <Form.Label>People in Network</Form.Label>
          <Form.Control
            as="textarea"
            rows={4} 
            value=""
            placeholder="People in Your Network"
            onChange={(e) => setPeople(e.target.value)}
          />
        </Form.Group>
        <Button style={{ backgroundColor: '#f35b2a' }} type="submit">
          Save Changes
        </Button>
      </Form>
    </div>
  )
}

export default SocialInfo