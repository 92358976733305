import UserLoginComponent from "../components/UserLoginComponent";
import HamburgerComponent from "../components/HamburgerComponent";
import ZocdocLogoComponent from "../components/ZocdocLogoComponent";

export default function UserLogin() {
    return (
        <div class="homepage" id="homepage-id">
            <UserLoginComponent />
        </div>
    )
}