import React, { useState,useCallback } from 'react';
import { createRoot } from 'react-dom/client';
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
  useMap,
  MapCameraChangedEvent
} from '@vis.gl/react-google-maps';

import {MarkerClusterer} from '@googlemaps/markerclusterer';
import {Marker} from '@googlemaps/markerclusterer'; //ye line nahi chahiye
import PoiMarker from './PoiMarker';
import './css/RenderMap.css';


var _ = require('lodash');

const RenderMap = (props) => {

  const API_KEY = "AIzaSyCvbO98mUlgO-JQ6Df3VnYQOhVe8Tp-okU";
  
  const locations = [
    {key: 'operaHouse', location: { lat: -33.8567844, lng: 151.213108  }},
    {key: 'tarongaZoo', location: { lat: -33.8472767, lng: 151.2188164 }},
    {key: 'manlyBeach', location: { lat: -33.8209738, lng: 151.2563253 }}
  ];

  const [docsLocAr, setDocsLocAr] = useState([{key: 'operaHouse', location: { lat: -33.8567844, lng: 151.213108  }}]);

  return (
    
      <APIProvider apiKey={API_KEY}  >
        <Map
          defaultCenter={{ lat: 47.80164, lng: 47.80164 }}
          defaultZoom={11}
          gestureHandling={'greedy'}
          disableDefaultUI={true}
          mapId='DEMO_MAP_ID'
        >
          {/* <PoiMarkersLoc pois={locations} /> */} 
          <PoiMarker srpData={props.srpData} selectedDoc={props.selectedDoc}/>
        </Map>
      </APIProvider>
 
  )
};





export default RenderMap;