import React, { useEffect, useState } from 'react'
import './ReviewCard.css'
import { Button,Modal } from 'react-bootstrap';
import './DocProfileView.css';
import { FaStar } from "react-icons/fa";
import { useIsAuthenticated } from 'react-auth-kit';




const ReviewCard = ({
  user,
  review,
  UserId,
  found,
  pageNumber,
  fetchReviews,
}) => {

    const container = {
        marginTop:'20px',
        paddingTop:'24px',
        backgroundColor: "var(--white)",
        boxShadow: "var(--sh01)",
        padding: "24px",
        borderRadius: "16px",
        backgroundColor:'#F4F4F4',
    };

    const [ReviewFormData, setReviewFormData] = useState({
      userId: UserId,
      doctorId:user.id,
      doctorCenterId:user.centers[0].id,
      rating: review.rating,
      ratingTitle: review.ratingTitle,
      ratingBody:review.ratingBody, 
    });
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleOpen = () => setShow(true);
    const [Sameid,setSameid] = useState(-1);
    const handleInputChange1 = (e) => {
      const { name, value } = e.target;
      setReviewFormData({
        ...ReviewFormData,
        [name]: value,
      });
    };
  
    const handleRatingChange1 = (e) => {
      setReviewFormData({
        ...ReviewFormData,
        rating: e.target.value,  
      });
  
    };
    let isAuthenticated = useIsAuthenticated();
   
    
    const handleSubmit1 = async (e) => {
      e.preventDefault();
      console.log(ReviewFormData);
      try {
        const response = await fetch(process.env.REACT_APP_JAVA_BASE_URL+`/review?reviewId=${review.id}&userId=${UserId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(ReviewFormData),
        });
        console.log(response);
        if (response.ok) {
          const result = await response.json();
          console.log('Review updated:', result);
          review.rating = ReviewFormData.rating;
          review.ratingTitle = ReviewFormData.ratingTitle;
          review.ratingBody = ReviewFormData.ratingBody;
          handleClose();
          fetchReviews(pageNumber);  
        } else {
          alert('Failed to update review');
          handleClose();
        }
      } catch (error) {
        console.error('Error:', error);
        handleClose();
      }
    };

    

    const findReview = async()=>{
      try {
        const response = await fetch(process.env.REACT_APP_JAVA_BASE_URL+`/my-review-doc?userId=${UserId}&doctorCenterId=${user.centers[0].id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          const result = await response.json();
          setSameid(result.id);
          console.log('Review fetched:', result);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
    
    useEffect(() => {
      findReview();
    },[]);
    const starslogo = [
    <div>
      <FaStar size={20} className='star-w'/>
      <FaStar size={20} className='star-w'/>
      <FaStar size={20} className='star-w'/>
      <FaStar size={20}className='star-w'/>
      <FaStar size={20} className='star-w'/>
  
    </div>,
    <div>
      <FaStar size={20} className='star-g'/>
      <FaStar size={20} className='star-w'/>
      <FaStar size={20} className='star-w'/>
      <FaStar size={20} className='star-w'/>
      <FaStar size={20} className='star-w'/>
    </div>,
    <div>
    <FaStar size={20} className='star-g'/>
    <FaStar size={20} className='star-g'/>
    <FaStar size={20} className='star-w'/>
    <FaStar size={20} className='star-w'/>
    <FaStar size={20} className='star-w'/>
      
  </div>,
  <div>
    <FaStar size={20} className='star-g'/>
    <FaStar size={20} className='star-g'/>
    <FaStar size={20} className='star-g'/>
    <FaStar size={20} className='star-w'/>
    <FaStar size={20} className='star-w'/>
  </div>,
  <div>
    <FaStar size={20} className='star-g'/>
    <FaStar size={20} className='star-g'/>
    <FaStar size={20} className='star-g'/>
    <FaStar size={20} className='star-g'/>
    <FaStar size={20} className='star-w'/>
  
    </div>,
    <div>
      <FaStar size={20} className='star-g'/>
      <FaStar size={20} className='star-g'/>
      <FaStar size={20} className='star-g'/>
      <FaStar size={20} className='star-g'/>
      <FaStar size={20} className='star-g'/>
      </div>,
    ]

    const KabBani = () => {
      const dateObj = new Date(review.updatedAt);
      const day = String(dateObj.getDate()).padStart(2, '0');
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const year = dateObj.getFullYear();
      const formattedDate = `${day}-${month}-${year}`;
      return formattedDate;
    };
  
  return (
    <div style={container} className="card flx col">
      <div className="header-layout">
        <div className="review-header">
          <div>{review.ratingTitle}</div>
          <div>{starslogo[review.rating]}</div>
        </div>
        {isAuthenticated() && review.id == Sameid?<div>
          <Button onClick={handleOpen} style={{backgroundColor: '#f35b2a',color:'white'}} className='review-btn'>
            Edit Your Review
          </Button>
        </div>:<></>}
        
      </div>
      <p className="p gry100">
         {review.ratingBody}
      </p>
      <span className="sub gry40">{KabBani()}</span>

      <Modal show={show} onHide={handleClose} size='lg' centered>
        <Modal.Header closeButton style={headerStyle}>
          <Modal.Title >
            Edit Your Review
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={reviewFormStyle}>
            <form onSubmit={handleSubmit1} action="">
          <div class="stars">
            <form action="">
            <input class="star star-5" id="star-5" type="radio" value='5' name="rating" onChange={handleRatingChange1}/>
            <label class="star star-5" for="star-5"></label>
            <input class="star star-4" id="star-4" type="radio" value='4' name="rating"  onChange={handleRatingChange1}/>
            <label class="star star-4" for="star-4"></label>
            <input class="star star-3" id="star-3" type="radio" value='3' name="rating"  onChange={handleRatingChange1}/>
            <label class="star star-3" for="star-3"></label>
            <input class="star star-2" id="star-2" type="radio" value='2' name="rating"  onChange={handleRatingChange1}/>
            <label class="star star-2" for="star-2"></label>
            <input class="star star-1" id="star-1" type="radio" value='1' name="rating"  onChange={handleRatingChange1}/>
            <label class="star star-1" for="star-1"></label>
            </form>
          </div>
            <textarea
              placeholder="Your Review Topic"
              style={textareaStyle1}
              name="ratingTitle"
              value={ReviewFormData.ratingTitle}
              onChange={handleInputChange1}
            />
            <textarea
              placeholder="Your Review Content"
              style={textareaStyle}
              name="ratingBody"
              value={ReviewFormData.ratingBody}
              onChange={handleInputChange1}
            />
            <Button variant="primary" type='submit' style={postButtonStyle}>
                Post Your Review
            </Button>
            </form>
          </div>
        </Modal.Body>
        
      </Modal>

    </div>
  )
}


const headerStyle = {
  color: 'black',
};

const reviewFormStyle = {
  maxHeight: '500px',
  backgroundColor: '#333',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const starRatingStyle = {
  fontSize: '24px',
  color: 'gold',
  marginBottom: '20px',
};

const textareaStyle1 = {
  width: '100%',
  height: '100px',
  padding: '10px',
  marginBottom: '20px',
  border: '1px solid #444',
  backgroundColor: '#333',
  color: 'white',
  borderRadius: '5px',
  resize: 'none',
};

const textareaStyle = {
  width: '100%',
  height: '200px',
  padding: '10px',
  marginBottom: '20px',
  border: '1px solid #444',
  backgroundColor: '#333',
  color: 'white',
  borderRadius: '5px',
  resize: 'none',
};

const addMediaButtonStyle = {
  backgroundColor: '#444',
  border: 'none',
  color: '#f35b2a',
  padding: '10px',
  cursor: 'pointer',
  borderRadius: '5px',
  marginBottom: '20px',
};

const cancelButtonStyle = {
  backgroundColor: '#fab8a4',
};

const postButtonStyle = {
  justifyContent: 'center',
  alignSelf: 'center',
  margin:'20px 0px',
  backgroundColor:'#f35b2a',
};

export default ReviewCard