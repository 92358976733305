import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import './css/AllOptions.css'
import Constants from '../../../../Constants';
import { useSignIn, useIsAuthenticated, useAuthUser } from 'react-auth-kit';
import { useNavigate, Link } from "react-router-dom";
import getDoctorData from '../../services/getDoctor';
import updateDoctorData from '../../services/updateDoctor';
import DoctorCenterAddressDiv from "./DoctorCenterAddressDiv";
import updateDoctorProfileImageData from '../../services/updateDoctorProfileImage';
import ToggledItemDisplayEdit from './../common/ToggledItemDisplayEdit';
import getTemplate from '../../services/getTemplate';
import {getCompoDataMultiselect} from './../common/ToggledItemDisplayEdit';
import utils from './../../../../utils';

export default function ManageCenter() {

  const [ManageDoctorProfileText, setManageDoctorProfileText] = useState("");

  const [doctor, setDoctor] = useState({});
  const [templateData, setTemplateData] = useState({});

  const [nameDivVal, setNameDivVal] = useState('Add');
  let [emailCtr, setEmailCtr] = useState(1);
  let [profileImageDivVal, setProfileImageDivVal] = useState(1);
  const [fileImg, setFileImg] = useState("");

  const navigate = useNavigate();

  let isAuthenticated = useIsAuthenticated();
  const authUser = useAuthUser();
  

  function setAllStateCountersTo1() {
    setProfileImageDivVal(1);
    setEmailCtr(1);
    setNameDivVal('Add');
  }

  const profileImagePrefix = process.env.REACT_APP_JAVA_BASE_URL + process.env.REACT_APP_USER_PROFILE_IMAGE_CONTENT_REL_PATH;

  async function loadDoctorData() {

    
    var resp;
    if (isAuthenticated() && authUser().isDoctor && authUser().doctorDto.id)
      resp = await getDoctorData(authUser().doctorDto.id);

    if (resp.success) {
      //setManageDoctorProfileText(JSON.stringify(resp.respJson));
      setDoctor(resp.respJson);
      
    } else {
      //Differentiated error handling
      let dataLoadErrorMsg = "Did not got data from doctor get api. Plz try again later";
      setManageDoctorProfileText(dataLoadErrorMsg);
      alert(dataLoadErrorMsg);
    }
  }

  async function loadTemplateData(){
    let r = await getTemplate();
    if(r.success){
        setTemplateData(r.respJson);
    } else {
        let dataLoadErrorMsg = "Did not got data from templateApi. Please try again loading the page";
        alert(dataLoadErrorMsg);
    }
  }

  async function handleFormSubmit(e, cb ) {
   
    var resp;
    if (isAuthenticated() && authUser().isDoctor && authUser().doctorDto.id)
      resp = await updateDoctorData(e, authUser().doctorDto.id);

    if (resp.success) {
    
      setAllStateCountersTo1();
      
      setDoctor(resp.respJson);
      cb(null, resp.respJson);
    } else {
      alert('Could not save due to some error');
      cb(new Error("Some error"));
    }
  }

  // componentDidMount
  useEffect(() => {
    loadDoctorData()
    loadTemplateData()
  }, [])


  function nameDiv() {

    function nameDivClick(e) {
      //if (e.target.value == 'Add' || e.target.value == 'Edit' ) {
      if (nameDivVal == 'Add' || nameDivVal == 'Edit') {
        setNameDivVal('Cancel');
      } else {
        setNameDivVal('Add');
      }
    }

    if (nameDivVal == 'Add' || nameDivVal == 'Edit') {
      return (<div class="manage-profile-item profile-name">
        <div class="title"><span>Name</span></div>
        {(doctor&& doctor.displayName == '') ?
          (<div class="cta"><span id='idAdd' onClick={nameDivClick}>Add</span></div>) : (<div class="cta" onClick={nameDivClick} ><span id='idEdit' >Edit</span></div>)}
        <div class="value"><span>{doctor.displayName}</span></div>
        <div class="helptext"><span>Your name as seen by patients</span></div>
      </div>);
    } else {
      return (
        <div class="manage-profile-item profile-name">
          <div class="title"><span>Name</span></div>
          <div class="cta"><span id='idCancel' onClick={nameDivClick}>Cancel</span></div>

          <form class="user-profile-update-form" onSubmit={handleFormSubmit} method="post">

            <div class="user-profile-update-div">
              <label class="labelc" for="displayName">Display Name</label><br />
              <i class="zmdi zmdi-hc-lg"></i>
              <input type="text" id="displayName" name="displayName" class="user-profile-update-textbox" placeholder="Please enter your full name doctor" />
            </div>
            <div class="user-profile-update-div">
              <button type="submit" class="user-profile-update-submit" id="user-profile-update-submit" placeholder="" > Save </button>
            </div>

          </form>
        </div>
      );
    }
  }


  function profileImageDiv() {

    function handleCtaClick() {
    
      setProfileImageDivVal(++profileImageDivVal);
    }

    function handleTextChange(){

    }

    async function handleProfileImageSubmit(e) {
     
      var resp;
      if (isAuthenticated() && authUser().isDoctor && authUser().doctorDto.id)
        resp = await updateDoctorProfileImageData(e, authUser().doctorDto.id, fileImg);
  
      if (resp.success) {
        
        setAllStateCountersTo1();
        loadDoctorData();
      } else {
        alert('Could not save profile image due to some error');
        
      }
    }
    
    function handleFileChange(e) {
     
      if (e.target.files && e.target.files[0]) setFileImg(e.target.files[0]);
    }

    if (eval(profileImageDivVal) % 2 == 1) {
      return (<div class="manage-profile-item">
        <div class="title"><span> Add Image: </span></div>
        <div class="cta"><span onClick={handleCtaClick}> Add/Edit </span></div>
        <div class="value">{(doctor.profileImage)? <img src={profileImagePrefix + doctor.profileImage} /> : '' }</div>
        <div class="helptext"><span> This is your profile picture. </span></div>
      </div>);
    } else {
      return (
        <div class="manage-profile-item">
          <div class="title"><span> Add Image: </span></div>
          <div class="cta"><span onClick={handleCtaClick}> Cancel </span></div>

          <form class="user-profile-update-form" onSubmit={handleProfileImageSubmit} method="post">
            <div class="user-profile-update-div">
              <label class="labelc" for="profile-image"></label>
              <i class="zmdi zmdi-hc-lg"></i>
              <input type="file" onChange={handleFileChange} />
              <input type="text" id="profile-image" name="profile-image" class="user-profile-update-textbox" onChange={handleTextChange} placeholder="Please enter" />
            </div>
            <div class="user-profile-update-div">
              <button type="submit" class="user-profile-update-submit" id="user-profile-update-submit" placeholder="" > Save </button>
            </div>
          </form>
        </div>
      );
    }

  }

  
  //getCompoDataMultiselect(id, title, cta, ctaToggle, helptext, valueSelected, valueSuperset, selectDefaultValue)
  function languageDiv(){
    let divData = getCompoDataMultiselect(
        'languages_spoken',
        'Languages Spoken',
        'Edit',
        'Cancel',
        'Languages you can speak for conversing with patients',
        doctor.languagesSpoken, 
        templateData.languages,
        'Select languages'
      );
    
    return (
        <ToggledItemDisplayEdit doctor={doctor} data={divData} handleFormSubmit={handleFormSubmit} /> 
    )
  }


  function professionDiv(){
    let divData = getCompoDataMultiselect(
        'profession',
        'Profession',
        'Edit',
        'Cancel',
        'Your professions / specialisations',
        doctor.profession, 
        templateData.profession,
        'Select Professions'
      );
    
    return (
        <ToggledItemDisplayEdit doctor={doctor} data={divData} handleFormSubmit={handleFormSubmit} /> 
    )
  }
  

  return (

    <div id="manage-user-profile-wrapper-id" class="manage-user-profile-wrapper">

      <h1>Hey Doctor, this page lets you manage your centers</h1>
      <div id="manage-user-profile-id" class="manage-user-profile">

        <h2>{ManageDoctorProfileText}</h2>

     {/*   {profileImageDiv()}  */}
     {/*   {nameDiv()}  */}
        {languageDiv()}
        {professionDiv()}

        <DoctorCenterAddressDiv handleFormSbt={handleFormSubmit} doctorData={doctor} /> {/* TEMPORARY HACK */}
        
      </div>

    </div>
  );
}