import { useState, useEffect } from 'react';
import utils from '../../../utils.js';
import { useParams, useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import Constants from '../../../Constants.js';
import queryString from 'query-string';
import { useSignIn, useIsAuthenticated, useAuthUser } from 'react-auth-kit';
import DoctorRegisterViewNew from './DoctorRegisterViewNew.jsx';

var _ = require('lodash');

export default function DoctorRegisterNew() {
    
  const [searchTextTop, setSearchTextTop] = useState("Dear Doctor, please mention your practice details");
  const [templateData, setTemplateData] = useState(utils.getEmptyTemplateData());
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [professionM, setprofessionM] = useState("");
  const [specialityM, setSpecialityM] = useState("");
  const [languagesSpokenM, setLanguagesSpokenM] = useState("");

  const templateApiUrl = process.env.REACT_APP_JAVA_BASE_URL + process.env.REACT_APP_TEMPLATE_API_REL_URL
  const doctorRegistrationUrl = process.env.REACT_APP_JAVA_BASE_URL + process.env.REACT_APP_DOCTOR_REGISTRATION_REL_URL;

  const navigate = useNavigate();
  
  const gotToNewPage=()=>{
    navigate("/user-register-success");
  }

  const signIn = useSignIn();
  let isAuthenticated = useIsAuthenticated();
  const authUser = useAuthUser();
  
  const fetchTemplateApiData = async (url) => {
   

    const data = await callTemplateApi(url);
    setCountries(data.countryData);

   
    setTemplateData(data);    
  }

  const callTemplateApi = async (url) => {

    const response = await fetch(url);
    const body = await response.json();
    //body);
    return body;
  }

  // componentDidMount
  useEffect(() => {
    fetchTemplateApiData(templateApiUrl)
  }, [])


  const handleCountryChange = (e) => {
    setCountry(e.target.value);

    let countryIdx = countries.findIndex(country => country.id == e.target.value);

    setStates(countries[countryIdx].states);
    setCities("");

    setState("");
    setCity("");
  };

  const handleStateChange = (e) => {
    setState(e.target.value);

    let stateIdx = states.findIndex(state => state.id == e.target.value);

    setCities(states[stateIdx].cities);
    setCity("");
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
  };



  async function handleDoctorRegisterFormSubmit(e) {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());
    let payloadObj = payloadFromFormData(formJson);
    setSearchTextTop("Registration submitted. Please check your email for next steps.");
    let resp = await callRegisterHandler(doctorRegistrationUrl, payloadObj);
    console.log(payloadObj)
    if (resp.success) {
      console.log('Registration Successfull')
    } else {
      //setSearchTextTop("Registration failed. Some issue. Please contact us or try again later");
      alert("Registration failed. Some issue. Please contact us or try again later");
    }
  }

  function getStringFromObj(arObj){
    let values = [];
    if(arObj && Array.isArray(arObj)){
      arObj.forEach(function(each){
        values.push(each.value);
      })
      return values.join(',');
    }
    return '';
  }
  function payloadFromFormData(form){
    let requestBody = {
      userId: authUser().userDto.id,
      title: form.title,
      firstname: form.firstname,
      lastname: form.lastname,
      profession: form.profession || getStringFromObj(professionM),
      speciality: form.speciality || getStringFromObj(specialityM),
      languagesSpoken: form.languagesSpoken || getStringFromObj(languagesSpokenM),
      centers:[]
    };

    let center = {
      practiceSize: form.practiceSize,
      countryId: form.country,
      stateId : form.state,
      cityId: form.city,
      address: form.address,
      street: form.street,
      area: form.area,
      pincode: form.pincode,
      mail: form.mail,
      isdCode: "+43",
      phone: form.phone,
    }
    requestBody.centers.push(center);
    return requestBody;
  }

  async function callRegisterHandler(url, body) {
    let resp = await callRegisterApi(url, body);
    if (resp && resp.status === 200)
      return { success: true };
    else
      return { success: false };
  }

  const callRegisterApi = async (url, body) => {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
    let resp = await response;
    return resp;
  }
    return (
        <div>
            <DoctorRegisterViewNew 
                handleDoctorRegisterFormSubmit = {handleDoctorRegisterFormSubmit}
                handleCountryChange = {handleCountryChange}
                handleStateChange = {handleStateChange}
                handleCityChange = {handleCityChange}
                templateData = {templateData}
                countries = {countries}
                states = {states}
                cities = {cities}
                setprofessionM = {setprofessionM}
                setSpecialityM = {setSpecialityM}
                setLanguagesSpokenM = {setLanguagesSpokenM}
            />
        </div>
    )
}