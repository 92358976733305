import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DocProfile from './DocProfile';
import { useIsAuthenticated, useAuthUser } from 'react-auth-kit';

const DocProfileParent = () => {
  let isAuthenticated = useIsAuthenticated();
  const authUser = useAuthUser();
  const { id } = useParams();
  const [doc, setDoc] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [dayWiseSlots1,setdayWiseSlots] = useState({});
  const [dateWiseSlots,setdateWiseSlots] = useState({});

  
  const findinfo = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_JAVA_BASE_URL}/doctor/id?doctorId=${id}&enrich=true`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );
      if (res.ok) {
        const result = await res.json();
        console.log('Result fetched:', result);
        setDoc(result); 
      } else {
        console.error('Failed to get info');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false); 
    }
  };

  const [User_Id, setUser_Id] = useState(-1);
  const getUserId = ()=>{
    if(isAuthenticated() ){
      setUser_Id(authUser().userDto.id);
    }
  }
  

  
  
  const startTime = () => {
    const dateObj = new Date();
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };
  const days = 7;
  
  
  const findSlots = async ()=>{
    try {
      const res = await fetch(
        `${process.env.REACT_APP_JAVA_BASE_URL}/doctor-center-slot/apt-slots/${doc.centers[0].id}?start=${startTime()}&days=${days}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );
      if (res.ok) {
        const result = await res.json();
        console.log('Result fetched:', result);
        setdayWiseSlots(result.dayWiseSlots);
        setdateWiseSlots(result.dateWiseSlots);
      } else {
        console.error('Failed to get slots');
      }
    } catch (error) {
      console.error('Error:', error);
    }finally {
      setLoading1(false); 
    }
  }
  useEffect(() => {
    getUserId();
  }, [isAuthenticated,authUser])

  useEffect(() => {
    
    findinfo();
  }, [id]);
  useEffect(() => {
    if (doc && doc.centers && doc.centers[0]?.id) {
      findSlots();
    }
  }, [doc]);
  if (loading||loading1) {
    return <p>Loading...</p>;
  }

  if (!doc) {
    return <p>No data found</p>; 
  }
  console.log(dayWiseSlots1)
  return (
    <DocProfile doc={doc} User_Id={User_Id} dayWiseSlots1={dayWiseSlots1} dateWiseSlots={dateWiseSlots} />
  );
};

export default DocProfileParent;