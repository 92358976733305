import React, { useState, useCallback, useRef, useEffect} from 'react';
import { createRoot } from 'react-dom/client';
import {
	APIProvider,
	Map,
	AdvancedMarker,
	Pin,
	useMap,
	MapCameraChangedEvent,
	InfoWindow
} from '@vis.gl/react-google-maps';

import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { Marker } from '@googlemaps/markerclusterer'; //ye line nahi chahiye
import './css/PoiMarker.css';
import Constants from './../../../../Constants';

var _ = require('lodash');


const PoiMarker = (props) => {

	const map = useMap();
	const [markers, setMarkers] = useState({});
	const clusterer = useRef(null);

	const [infoWindowShown, setInfoWindowShown] = useState(false);
	const [selectedMarker, setSelectedMarker] = useState("");

  let profileImagePrefix = process.env.REACT_APP_JAVA_BASE_URL + process.env.REACT_APP_USER_PROFILE_IMAGE_CONTENT_REL_PATH;


	// Initialize MarkerClusterer, if the map has changed
	useEffect(() => {
		if (!map) return;
		if (!clusterer.current) {
		  clusterer.current = new MarkerClusterer({map});
		}
	  }, [map]);
	

	// Update markers, if the markers array has changed
	useEffect(() => {
		clusterer.current?.clearMarkers();
		clusterer.current?.addMarkers(Object.values(markers));
	  }, [markers]);
	
	
	const setMarkerRef = (marker, key) => {
		if (marker && markers[key]) return;
		if (!marker && !markers[key]) return;
		
		//Commenting / Uncommenting below code block will enable/disable the location grouping in maps.
		/*
		setMarkers(prev => {
		  if (marker) {
			return {...prev, [key]: marker};
		  } else {
			const newMarkers = {...prev};
			delete newMarkers[key];
			return newMarkers;
		  }
		});
		*/
	};

	/*
	const handleMarkerClick = useCallback((ev) => {
		if(!map) return;
		if (!ev.latLng) return;
		'marker clicked:', ev.latLng.toString());
		'ev is : ' + JSON.stringify(ev));

		map.panTo(ev.latLng);
		//setSelectedMarker(marker);
		setInfoWindowShown(isShown => !isShown)
	});
	*/
	
	function getDocLocAddressStr(loc){
		let str='';
		let ar = [];
		if(loc.address)
			ar.push(loc.address);
		if(loc.street)
			ar.push(loc.street);
		if(loc.area)
			ar.push(loc.area);
		if(loc.cityStr)
			ar.push(loc.cityStr)
	
	
		str= ar.join(', ');
		return str;
	  }

	const handleMarkerClick = useCallback((poi, ev) => {
		

		if(!map) return;
		if (!poi) return;

		
		
		//map.panTo(ev.latLng);
		map.panTo(poi.location);
		setSelectedMarker(poi);
    //setInfoWindowShown(true);
		setInfoWindowShown(isShown => !isShown)
	});
	

	let locationAr = [];

	if (_.has(props, 'srpData.doctorList')) {
		
		locationAr = filteredlocation(props.srpData.doctorList);
    
    let setDone = false
    locationAr.forEach((loc) => {
      if(loc.data.isSelected){
        setDone = true;
        
        //map.setCenter(loc.location);
			  map.setZoom(12);
      }
    })

		if(!setDone && locationAr.length > 0){
			map.setCenter(locationAr[0].location);
			map.setZoom(12);
		}
    //setDone = false;
	} else {
		alert("NAHI MILI doctor list");
	}

	return (
		<div class = "poi">
			{locationAr.map((poi) => (
				<AdvancedMarker
					key={poi.key}
					position={poi.location}
					ref={marker => setMarkerRef(marker, poi.key)}
					
					clickable={true}
					onClick={(e) => handleMarkerClick(poi,e)}
				>
          {(poi && poi.data.isSelected) ?
					   (<Pin background={'#FBBC04'} glyphColor={'#000'} borderColor={'#000'} /> )
          :
            (<Pin background={'#ABCDEF'} glyphColor={'#222'} borderColor={'#333'} /> )
          }
				
				</AdvancedMarker>
			))}

			<div class="infowindowmodal">
				{infoWindowShown && selectedMarker && (
							<InfoWindow position={selectedMarker.location} >
								<div id="image-profile-id" class="image-profile-div">
								<picture>
									{/*<img src = "https://images.docfinder.at/QtvVRz-G0e/dr-wernfried-gappmayer-frauenarzt-5020-salzburg-2cc3.jpg?d=50X150" class="doc-listing-profile-logo" />*/}
									<img src={profileImagePrefix + selectedMarker.data.profileImage} class="doc-listing-profile-logo" />
								</picture>
								</div>
								<h1 style={{color: "black", fontSize: "3vh"}}>{selectedMarker.data.displayName}</h1>
								<h2 style={{color: "black", fontSize: "2vh"}}>{getDocLocAddressStr(selectedMarker.data.centers[0])}</h2>
								<button onClick={() => {setSelectedMarker("")}} >
									Close btn
								</button>
							</InfoWindow>
				)}
			</div>
		</div>
	);
};

function filteredlocation(doctorList) {
	//Remove all the doctors who do not have a valid lat / lng entry in their centers[0];

	let resultAr = [];

	doctorList.forEach(function (doctor) {
		let key = _.get(doctor, 'id', 0);
		let lat = _.get(doctor, 'centers[0].lat', 0);
		let lng = _.get(doctor, 'centers[0].lng', 0);

		if (key && lat && lng) {
			resultAr.push(getObj(key, lat, lng, doctor));

		}
	})

	//"filteredlocation markers finally are " + JSON.stringify(resultAr));

	return resultAr;
}

function getObj(key, lat, lng, doctor) {
	lat = Number(lat);
	lng = Number(lng);
	return {
		key: key,
		location: {
			lat: lat,
			lng: lng
		},
		data: doctor
	}
}


export default PoiMarker;