let _ = require('lodash');
//import _ from 'lodash';

var methodsObj = {
  getQueryString: function(data) {
    //console.log(`data`,data)

    var str = "?";
    var pairs = [];
    Object.keys(data).forEach(function inner(each) {
      // if(_.has(data,each)){
      //   if(data[each] == 0 || ((typeof(data[each]) == "string") && data[each] != ""))
      //     pairs.push(each + "=" + data[each]);
      // }



      // if(data[each] && data[each] != "")
      //   pairs.push(each + "=" + data[each]);


        if(data[each] != null && (data[each] || data[each] == 0 || ((typeof(data[each]) == "string") && data[each] != "")))
          pairs.push(each + "=" + data[each]);

    });

    str = str + pairs.join("&");

    if(pairs.length == 0)
      str = ''; 
    
    return str;
  },

  getStringFromEntityIdAndTemplateData: function (csv, arrayValues) {

    if (csv && arrayValues) {
      let actualAr = csv.split(',').map(item => item.trim());

      let parsedStr = [];

      actualAr.forEach(function fn(item) {
        arrayValues.find(function fn1(lang) {
          if (lang.id == item) {
            parsedStr.push(lang.name);
            return lang.name;
          }
        });
      });

      // 'amazon actualAr is :' + actualAr);
      // 'amazon parsedStr is: ' + parsedStr);
      let ans = parsedStr.join(', ');
      return ans;

    } else {
      return '';
    }

  },

  myCustomFormJsonParser: function (arrayEntries) {
    let output = {};

    arrayEntries.forEach(function fn(each) {
      if (_.has(output, each[0])) {
        output[each[0]] += ',' + each[1];
      } else {
        output[each[0]] = each[1];
      }
    })

    // 'myCustomFormJsonParser');
    // JSON.stringify(output));

    return output;
  },

  getDocLocAddressStr: function getDocLocAddressStr(loc){
    let str='';
    let ar = [];
    if(loc.address)
        ar.push(loc.address);
    if(loc.street)
        ar.push(loc.street);
    if(loc.area)
        ar.push(loc.area);
    if(loc.cityStr)
        ar.push(loc.cityStr)


    str= ar.join(', ');
    return str;
  },

  getDocLocAddressStr1: function getDocLocAddressStr1(loc){
    let str='';
    let ar = [];

    if(loc.area)
        ar.push(loc.area);
    if(loc.cityStr)
        ar.push(loc.cityStr)


    str= ar.join(', ');
    return str;
  },


  getEmptyTemplateData : function(){

    var tData = {};
    ["country",
      "state",
      "city",
      "countryData",
      "language",
      "speciality",
      "profession",
      "countryMap",
      "stateMap",
      "cityMap",
      "languageMap",
      "professionMap"].forEach(function(each){
        tData[each] = [];
      });

      return tData;
  },

  getCurrentDate: function(separator=''){
    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return `${date}${'-'}${month<10?`0${month}`:`${month}`}${'-'}${year}`
  }

}

module.exports = methodsObj;