import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import './ProfileContent.css';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import { useLanguage } from '../../../homepage/components/LanguageContext';

const Titleoptions = [
  { value: 'Mr.', label: 'Mr.' },
  { value: 'Mrs.', label: 'Mrs.' },
  { value: 'Prof.', label: 'Prof.' },
  { value: 'Dr.', label: 'Dr.' },
];

const BasicInfo = () => {
  const { user, docLocation, setUser } = useOutletContext();
  const [firstname, setFirstname] = useState(user.firstname);
  const [lastname, setLastname] = useState(user.lastname);
  const [title, setTitle] = useState(Titleoptions.find(option => option.value === user.title));
  const [professionOptions, setProfessionOptions] = useState([]);
  const [specialityOptions, setSpecialityOptions] = useState([]);
  const [profession, setProfession] = useState(null);
  const [speciality, setSpeciality] = useState([]);
  const [languagesSpoken, setLanguagesSpoken] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const { langStrings} = useLanguage();
  const ST = langStrings.DOCEDIT.BASICINFO;

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_JAVA_BASE_URL + '/template');
        const professionMap = response.data.professionMap;
        const specialityMap = response.data.specialityMap;
        const professionOptions = Object.keys(professionMap).map(key => ({
          value: key,
          label: professionMap[key],
        }));
        const specialityOptions = Object.keys(specialityMap).map(key => ({
          value: key,
          label: specialityMap[key],
        }));
        setProfessionOptions(professionOptions);
        setSpecialityOptions(specialityOptions);
        setProfession(professionOptions.find(option => option.value === user.profession));
        setSpeciality(user.speciality.split(',').map(item => specialityOptions.find(option => option.value === item)));
        const languagesFromApi = response.data.language.map(lang => ({
          value: lang.id.toString(),
          label: lang.name
        }));
        setLanguageOptions(languagesFromApi);
        const userLanguages = user.languagesSpoken.split(',').map(item => languagesFromApi.find(lang => lang.value === item));
        setLanguagesSpoken(userLanguages);
      } catch (error) {
        console.error('Error fetching languages:', error);
      }
    };

    fetchLanguages();
  }, [user.languagesSpoken]);

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    console.log(speciality);
    const updatedData = {
      firstname,
      lastname,
      title: title?.value || user.title,
      profession: profession?.value || user.profession,
      speciality: speciality.map(spec => spec?.value).join(',') || user.speciality,
      languagesSpoken: languagesSpoken.map(lang => lang?.value).join(',') || user.languagesSpoken,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_JAVA_BASE_URL}/doctor?doctorId=${user.id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify(updatedData)
        }
      );

      if (response.status === 200) {
        alert("Doctor details updated successfully");
        // setUser(prevUser => ({
        //   ...prevUser,
        //   firstname: updatedData.firstname,
        //   lastname: updatedData.lastname,
        //   title: updatedData.title,
        //   profession: updatedData.profession,
        //   speciality: updatedData.speciality.split(','),
        //   languagesSpoken: updatedData.languagesSpoken.split(',')
        // }));
      }
    } catch (error) {
      console.error('Error updating doctor data:', error);
      alert("Failed to update doctor details");
    }
  };
  return (
    <div className="p-4 bg-white rounded shadow-sm">
      <Form onSubmit={handleSaveChanges}>
        <Form.Group className="mb-3" controlId="formtitle">
            <Form.Label>{ST.label}</Form.Label>
            <Select 
              options={Titleoptions}
              defaultValue={Titleoptions.find(option => option.value === user.title)}
              onChange={(selectedOption) => setTitle(selectedOption)}
              placeholder="Select Your Title"
              styles={{
                control: (base) => ({
                  ...base,
                  height: 'calc(1.5em + 0.75rem + 2px)', // Bootstrap's form-control height
                  border: '1px solid #ced4da', // Bootstrap's border
                  borderRadius: '0.375rem', // Bootstrap's border-radius
                  fontSize: '1rem', // Bootstrap's font-size
                  width: '100%', // Full-width
                  paddingRight: '0.75rem', // Bootstrap padding
                  boxShadow: 'none', // Remove shadow
                  transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out', // Bootstrap transition
                }),
                input: (base) => ({
                  ...base,
                  fontSize: '1rem', // Matching Bootstrap font-size
                  color: '#495057', // Bootstrap input text color
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 1000, // Ensure dropdown shows above other content
                  backgroundColor: 'white',
                  border: '1px solid #ced4da',
                  borderRadius: '0.375rem', // Match Bootstrap's dropdown menu styling
                  boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)', // Light shadow for the dropdown
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected ? '#0d6efd' : 'white', // Bootstrap primary color for selected
                  color: state.isSelected ? 'white' : '#495057', // White text when selected, black otherwise
                }),
                multiValue: (base) => ({
                  ...base,
                  backgroundColor: '#e9ecef', // Light gray background for selected values
                  borderRadius: '0.375rem',
                }),
                multiValueLabel: (base) => ({
                  ...base,
                  fontSize: '0.875rem', // Match Bootstrap label sizing for multi-select
                  padding: '0.25rem 0.5rem',
                }),
                multiValueRemove: (base) => ({
                  ...base,
                  color: '#495057', // Bootstrap text color
                  ':hover': {
                    backgroundColor: '#ced4da', // Hover effect for removing tag
                    color: 'black',
                  },
                }),
              }}
            />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formfirstname">
          <Form.Label>{ST.label1}</Form.Label>
          <Form.Control
            type="text"
            value={firstname}
            placeholder="Enter first name"
            onChange={(e) => setFirstname(e.target.value)}  
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formlastname">
          <Form.Label>{ST.label2}</Form.Label>
          <Form.Control
            type="text"
            value={lastname}
            placeholder="Enter last name"
            onChange={(e) => setLastname(e.target.value)}  
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formprofession">
          
          <Form.Label>{ST.label3}</Form.Label>
          <Select 
            options={professionOptions}
            placeholder="select a profession"
            defaultValue={professionOptions.find(option => option.value === user.profession)}
            onChange={(selectedOption) => setProfession(selectedOption)}
            styles={{
              control: (base) => ({
                ...base,
                height: 'calc(1.5em + 0.75rem + 2px)', // Bootstrap's form-control height
                border: '1px solid #ced4da', // Bootstrap's border
                borderRadius: '0.375rem', // Bootstrap's border-radius
                fontSize: '1rem', // Bootstrap's font-size
                width: '100%', // Full-width
                
                paddingRight: '0.75rem', // Bootstrap padding
                boxShadow: 'none', // Remove shadow
                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out', // Bootstrap transition
              }),
              input: (base) => ({
                ...base,
                fontSize: '1rem', // Matching Bootstrap font-size
                color: '#495057', // Bootstrap input text color
              }),
              menu: (base) => ({
                ...base,
                zIndex: 1000, // Ensure dropdown shows above other content
                backgroundColor: 'white',
                border: '1px solid #ced4da',
                borderRadius: '0.375rem', // Match Bootstrap's dropdown menu styling
                boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)', // Light shadow for the dropdown
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected ? '#0d6efd' : 'white', // Bootstrap primary color for selected
                color: state.isSelected ? 'white' : '#495057', // White text when selected, black otherwise
                // ':hover': {
                //   backgroundColor: '#e9ecef', // Bootstrap-like hover effect
                // },
              }),
              multiValue: (base) => ({
                ...base,
                backgroundColor: '#e9ecef', // Light gray background for selected values
                borderRadius: '0.375rem',
              }),
              multiValueLabel: (base) => ({
                ...base,
                fontSize: '0.875rem', // Match Bootstrap label sizing for multi-select
                padding: '0.25rem 0.5rem',
              }),
              multiValueRemove: (base) => ({
                ...base,
                color: '#495057', // Bootstrap text color
                ':hover': {
                  backgroundColor: '#ced4da', // Hover effect for removing tag
                  color: 'black',
                },
              }),
            }}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formspeciality">
          
          <Form.Label>{ST.label4}</Form.Label>
          <Select 
            options={specialityOptions}
            isMulti
            defaultValue={speciality}
            onChange={(selectedOptions) => setSpeciality(selectedOptions)}
            placeholder="select a speciality"
            
            styles={{
              control: (base) => ({
                ...base,
                height: 'calc(1.5em + 0.75rem + 2px)', // Bootstrap's form-control height
                border: '1px solid #ced4da', // Bootstrap's border
                borderRadius: '0.375rem', // Bootstrap's border-radius
                fontSize: '1rem', // Bootstrap's font-size
                width: '100%', // Full-width
                
                paddingRight: '0.75rem', // Bootstrap padding
                boxShadow: 'none', // Remove shadow
                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out', // Bootstrap transition
              }),
              input: (base) => ({
                ...base,
                fontSize: '1rem', // Matching Bootstrap font-size
                color: '#495057', // Bootstrap input text color
              }),
              menu: (base) => ({
                ...base,
                zIndex: 1000, // Ensure dropdown shows above other content
                backgroundColor: 'white',
                border: '1px solid #ced4da',
                borderRadius: '0.375rem', // Match Bootstrap's dropdown menu styling
                boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)', // Light shadow for the dropdown
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected ? '#0d6efd' : 'white', // Bootstrap primary color for selected
                color: state.isSelected ? 'white' : '#495057', // White text when selected, black otherwise
                // ':hover': {
                //   backgroundColor: '#e9ecef', // Bootstrap-like hover effect
                // },
              }),
              multiValue: (base) => ({
                ...base,
                backgroundColor: '#e9ecef', // Light gray background for selected values
                borderRadius: '0.375rem',
              }),
              multiValueLabel: (base) => ({
                ...base,
                fontSize: '0.875rem', // Match Bootstrap label sizing for multi-select
                padding: '0.25rem 0.5rem',
              }),
              multiValueRemove: (base) => ({
                ...base,
                color: '#495057', // Bootstrap text color
                ':hover': {
                  backgroundColor: '#ced4da', // Hover effect for removing tag
                  color: 'black',
                },
              }),
            }}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formlanguage">
          
          <Form.Label>{ST.label5}</Form.Label>
          <Select 
            options={languageOptions}
            isMulti
            placeholder="Search"
            defaultValue={languagesSpoken}
            onChange={(selectedOptions) => setLanguagesSpoken(selectedOptions)}
            styles={{
              control: (base) => ({
                ...base,
                height: 'calc(1.5em + 0.75rem + 2px)', // Bootstrap's form-control height
                border: '1px solid #ced4da', // Bootstrap's border
                borderRadius: '0.375rem', // Bootstrap's border-radius
                fontSize: '1rem', // Bootstrap's font-size
                width: '100%', // Full-width
                paddingRight: '0.75rem', // Bootstrap padding
                boxShadow: 'none', // Remove shadow
                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out', // Bootstrap transition
              }),
              input: (base) => ({
                ...base,
                fontSize: '1rem', // Matching Bootstrap font-size
                color: '#495057', // Bootstrap input text color
              }),
              menu: (base) => ({
                ...base,
                zIndex: 1000, // Ensure dropdown shows above other content
                backgroundColor: 'white',
                border: '1px solid #ced4da',
                borderRadius: '0.375rem', // Match Bootstrap's dropdown menu styling
                boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)', // Light shadow for the dropdown
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected ? '#0d6efd' : 'white', // Bootstrap primary color for selected
                color: state.isSelected ? 'white' : '#495057', // White text when selected, black otherwise
                // ':hover': {
                //   backgroundColor: '#e9ecef', // Bootstrap-like hover effect
                // },
              }),
              multiValue: (base) => ({
                ...base,
                backgroundColor: '#e9ecef', // Light gray background for selected values
                borderRadius: '0.375rem',
              }),
              multiValueLabel: (base) => ({
                ...base,
                fontSize: '0.875rem', // Match Bootstrap label sizing for multi-select
                padding: '0.25rem 0.5rem',
              }),
              multiValueRemove: (base) => ({
                ...base,
                color: '#495057', // Bootstrap text color
                ':hover': {
                  backgroundColor: '#ced4da', // Hover effect for removing tag
                  color: 'black',
                },
              }),
            }}
          />
        </Form.Group>

        <Button style={{backgroundColor:'#f35b2a'}} type="submit">{ST.label6}</Button>
      </Form>
    </div>
  );
};

export default BasicInfo;
