import { useState, useEffect } from 'react';
import './css/ManageUserProfile.css'
import { useSignIn, useIsAuthenticated, useAuthUser } from 'react-auth-kit';
import { useNavigate, Link } from "react-router-dom";
import getUserData from './../services/getUser';
import updateUserData from './../services/updateUser';
import AddressDiv from "./AddressDiv";
import updateUserProfileImage from './../services/updateUserProfileImage';

export default function ManageUserProfile() {

  const [manageUserProfileText, setManageUserProfileText] = useState("");
  const [user, setUser] = useState({});

  const [nameDivVal, setNameDivVal] = useState('Add');
  const [phoneDivVal, setPhoneDivVal] = useState('Add');

  let [emailCtr, setEmailCtr] = useState(1);

  let [profileImageDivVal, setProfileImageDivVal] = useState(1);
  const [fileImg, setFileImg] = useState("");

  const navigate = useNavigate();
  const refreshPage=()=>{
    navigate("/manage-user-profile");
  }

  let isAuthenticated = useIsAuthenticated();
  const authUser = useAuthUser();
  

  function setAllStateCountersTo1() {
    setProfileImageDivVal(1);
    setEmailCtr(1);
    setNameDivVal('Add');
    setPhoneDivVal('Add');
  }

  const profileImagePrefix = process.env.REACT_APP_JAVA_BASE_URL + process.env.REACT_APP_USER_PROFILE_IMAGE_CONTENT_REL_PATH;

  async function loadUserData() {

    var resp;
    if (isAuthenticated() && authUser().isUser && authUser().userDto.id)
      resp = await getUserData(authUser().userDto.id);

    if (resp.success) {
      setUser(resp.respJson);
    } else {
      let dataLoadErrorMsg = "Did not got data from doctor get api. Plz try again later";
      setManageUserProfileText(dataLoadErrorMsg);
      alert(dataLoadErrorMsg);
    }
  }

  async function handleFormSubmit(e) {
   
    var resp;
    if (isAuthenticated() && authUser().isUser && authUser().userDto.id)
      resp = await updateUserData(e, authUser().userDto.id);

    if (resp.success) {
   
      setAllStateCountersTo1();
     
      setUser(resp.respJson);
    } else {
      alert('Could not save due to some error');
    }
  }

  // componentDidMount
  useEffect(() => {
    loadUserData()
  }, [])


  function dateOfBirthDiv() {

  }

  function nameDiv() {

    function nameDivClick(e) {
      //if (e.target.value == 'Add' || e.target.value == 'Edit' ) {
      if (nameDivVal == 'Add' || nameDivVal == 'Edit') {
        setNameDivVal('Cancel');
      } else {
        setNameDivVal('Add');
      }
    }


    if (nameDivVal == 'Add' || nameDivVal == 'Edit') {
      return (<div class="manage-profile-item profile-name">
        <div class="title"><span>Name</span></div>
        {(user && user.firstname == '' && user.lastname == '') ?
          (<div class="cta"><span id='idAdd' onClick={nameDivClick}>Add</span></div>) : (<div class="cta" onClick={nameDivClick} ><span id='idEdit' >Edit</span></div>)}
        <div class="value"><span>{user.firstname} {user.lastname}</span></div>
        <div class="helptext"><span>Your name for appointments</span></div>
      </div>);
    } else {
      return (
        <div class="manage-profile-item profile-name">
          <div class="title"><span>Name</span></div>
          <div class="cta"><span id='idCancel' onClick={nameDivClick}>Cancel</span></div>

          <form class="user-profile-update-form" onSubmit={handleFormSubmit} method="post">

            <div class="user-profile-update-div">
              <label class="labelc" for="firstname">First name</label><br />
              <i class="zmdi zmdi-hc-lg"></i>
              <input type="text" id="firstname" name="firstname" class="user-profile-update-textbox" placeholder="Please enter first name" />
            </div>
            <div class="user-profile-update-div">
              <label class="labelc" for="lastname">Last name</label>
              <i class="zmdi zmdi-hc-lg"></i>
              <input type="text" id="lastname" name="lastname" class="user-profile-update-textbox" placeholder="Please enter last name" />
            </div>
            <div class="user-profile-update-div">
              <button type="submit" class="user-profile-update-submit" id="user-profile-update-submit" placeholder="" > Save </button>
            </div>

          </form>
        </div>
      );
    }
  }

  function phoneDiv() {
    function phoneDivClick(e) {
      if (phoneDivVal == 'Add' || phoneDivVal == 'Edit') {
        setPhoneDivVal('Cancel');
      } else {
        setPhoneDivVal('Add');
      }
    }

    if (phoneDivVal == 'Add' || phoneDivVal == 'Edit') {
      return (<div class="manage-profile-item phone-number">
        <div class="title"><span> Phone Number </span></div>
        {(user && user.phone == '') ?
          (<div class="cta"><span id='idAdd' onClick={phoneDivClick}>Add</span></div>) : (<div class="cta" onClick={phoneDivClick} ><span id='idEdit' >Edit</span></div>)}
        <div class="value"><span> {user.phone} </span></div>
        <div class="helptext"><span>Your phone number for appointments submit</span></div>
      </div>);
    } else {
      return (
        <div class="manage-profile-item phone-number">
          <div class="title"><span> Phone Number </span></div>
          <div class="cta"><span id='idCancel' onClick={phoneDivClick}>Cancel</span></div>

          <form class="user-profile-update-form" onSubmit={handleFormSubmit} method="post">
            <div class="user-profile-update-div">
              <label class="labelc" for="phone"></label>
              <i class="zmdi zmdi-hc-lg"></i>
              <input type="text" id="phone" name="phone" class="user-profile-update-textbox" placeholder="Please enter your phone number" />
            </div>
            <div class="user-profile-update-div">
              <button type="submit" class="user-profile-update-submit" id="user-profile-update-submit" placeholder="" > Save </button>
            </div>
          </form>
        </div>
      );
    }
  }

  function sexDiv() {
    let sex = 'Not disclose';
  
    switch (Number(user.sex)) {
      case 1: sex = 'Male';
        break;
      case 2: sex = 'Female';
        break;
      case 3: sex = 'Not disclose';
        break;
      case 4: sex = 'Unhandled gender';
        break;
    }
    return (<div class="manage-profile-item">
      <div class="title"><span> Sex </span></div>
      <div class="cta"><span> &nbsp; </span></div>
      <div class="value"><span> {sex} </span></div>
      <div class="helptext"><span> Your gender as reflects in Doctors appointments </span></div>
    </div>);
  }

  function profileImageDiv() {

    function handleCtaClick() {
      
      setProfileImageDivVal(++profileImageDivVal);
    }

    function handleTextChange(){

    }

    async function handleProfileImageSubmit(e) {
      
      var resp;
      if (isAuthenticated() && authUser().isUser && authUser().userDto.id)
        resp = await updateUserProfileImage(e, authUser().userDto.id, fileImg);
  
      if (resp.success) {
       
        setAllStateCountersTo1();
        loadUserData();
      } else {
        alert('Could not save profile image due to some error');
       
      }
    }
    
    function handleFileChange(e) {
      
      if (e.target.files && e.target.files[0]) setFileImg(e.target.files[0]);
    }

    if (eval(profileImageDivVal) % 2 == 1) {
      return (<div class="manage-profile-item">
        <div class="title"><span> Add Image: </span></div>
        <div class="cta"><span onClick={handleCtaClick}> Add/Edit </span></div>
        <div class="value">{(user.profileImage)? <img src={profileImagePrefix + user.profileImage} /> : '' }</div>
        <div class="helptext"><span> This is your profile picture. </span></div>
      </div>);
    } else {
      return (
        <div class="manage-profile-item">
          <div class="title"><span> Add Image: </span></div>
          <div class="cta"><span onClick={handleCtaClick}> Cancel </span></div>

          <form class="user-profile-update-form" onSubmit={handleProfileImageSubmit} method="post">
            <div class="user-profile-update-div">
              <label class="labelc" for="profile-image"></label>
              <i class="zmdi zmdi-hc-lg"></i>
              <input type="file" onChange={handleFileChange} />
              <input type="text" id="profile-image" name="profile-image" class="user-profile-update-textbox" onChange={handleTextChange} placeholder="Please enter" />
            </div>
            <div class="user-profile-update-div">
              <button type="submit" class="user-profile-update-submit" id="user-profile-update-submit" placeholder="" > Save </button>
            </div>
          </form>
        </div>
      );
    }

  }

  
  function commonDivFunction(data) {
    //'Inside function commonDivFunction for id: ' + data.id + ' stateVariable is: ' + data.stateVariable + '  . Its value is: ' + eval(data.stateVariable));
    function handleCtaClick() {
      setEmailCtr(++emailCtr);
    }
    if (eval(data.stateVariable) % 2 == 1) {
      return (<div class="manage-profile-item">
        <div class="title"><span> {data.title} </span></div>
        <div class="cta"><span onClick={handleCtaClick}> {data.cta} </span></div>
        <div class="value"><span> {data.value} </span></div>
        <div class="helptext"><span> {data.helptext} </span></div>
      </div>);
    } else {
      return (
        <div class="manage-profile-item">
          <div class="title"><span> {data.title} </span></div>
          <div class="cta"><span onClick={handleCtaClick}> {data.ctaToggle} </span></div>

          <form class="user-profile-update-form" onSubmit={handleFormSubmit} method="post">
            <div class="user-profile-update-div">
              <label class="labelc" for={data.id}></label>
              <i class="zmdi zmdi-hc-lg"></i>
              <input type="text" id={data.id} name={data.id} class="user-profile-update-textbox" placeholder="Please enter" />
            </div>
            <div class="user-profile-update-div">
              <button type="submit" class="user-profile-update-submit" id="user-profile-update-submit" placeholder="" > Save </button>
            </div>
          </form>
        </div>
      );
    }
  }

  function getCommonDataObj(id, stateVariable, title, cta, value, helptext, ctaToggle) {
    return {
      ctr: 1,
      id: id,
      stateVariable: stateVariable,
      title: title,
      cta: cta,
      value: value,
      helptext: helptext,
      ctaToggle: ctaToggle
    }
  };

  let emailDivData = getCommonDataObj(
    'email',
    'emailCtr',
    'Email Address',
    'Edit',
    user.email,
    'To secure your account, please verify your email address',
    'Cancel');

  return (
    <div id="manage-user-profile-wrapper-id" class="manage-user-profile-wrapper">
      <h1>Personal information</h1>
      <div id="manage-user-profile-id" class="manage-user-profile">
        <h2>{manageUserProfileText}</h2>
        {profileImageDiv()}
        {nameDiv()}
        {phoneDiv()}
        {commonDivFunction(emailDivData)}
        {sexDiv()}
        {dateOfBirthDiv()}
        <AddressDiv handleFormSbt={handleFormSubmit} userData={user} />
        {/*<AddressDiv handleFormSbt={[handleFormSubmit, user]}/>*/}
      </div>
    </div>
  );
}