const germanStrings = {
    DOCTOR_REGISTER_PAGE: {
      GENERAL_INFORMATION: {
        TITLE: {
          label: 'Title',
          values: {
            1: 'Dr.',
            2: 'Mr.',
            3: 'Mrs.'
          }
        },
        FIRSTNAME:{
          label: 'First name'
        },
        LASTNAME: {
          label: 'Last name'
        },
        PROFESSION:{
          label: 'Select your profession',
          values: {
            "1": "Doctor",
            "2": "Physio",
            "3": "Hebama",
            "4": "Life Coach"
          }
        },
        SPECIALITY: {
          label: "Practice / Provider's Speciality"
        },
        LANGUAGES:{
          label: "Languages Spoken"
        },
        PRACTICE_SIZE:{
          label: "Enter Practice Size",
          values:{
            "A": "1-5",
            "B": "6-10",
            "C": "11-20",
            "D": "20+"
          }
        }
      },
      CONTACT_DETAILS:{
        COUNTRIES:{
          label: "Select Country"
        },
        STATES:{
          label: "Select State"
        },
        CITIES:{
          label: "Select City"
        },
        ADDRESS:{
          label: 'Address'
        },
        STREET: {
          label: 'Street'
        },
        AREA: {
          label: 'Area'
        },
        PINCODE: {
          label: 'Pincode'
        },
        MAIL: {
          label: 'Your E-Mail for reservations'
        },
        ISD_CODE: {
          label: 'Select Isd code',
          values:{
            "+91": "+91",
            "+43": "+43"
          }
        },
        PHONE: {
          label: 'Phone Number'
        },
      }
    },
    HOMEPAGE:{
      NAV_BAR_SECTION:{
        SITELOGO:{
          label: 'Finde dein medizinischen Dienstleister'
        },
        DOCTOR_OR_THERAPIST_BTN:{
          label: 'Sind Sie Ärzt:in oder Therapeut:in?'
        },
        HELP:{
          label: 'Hilfe'
        },
        LOGIN:{
          label: 'Login',
          label1: 'Logout'
        }
      },
      SEARCH_SECTION:{
        HEADER:{
          label: "Finden Sie Ihren",
          label1: "Hausarzt/",
          label2: "Hebamme/",
          label3: "",
          label4: "Kinderarzt",
          label5: "Orthopäde",
          label6: "Kardiologe",
          label7: "Gynäkologe",
          label8: "Hautarzt",
          label9: "Radiologe",
          label10: "Urologe",
          label11: "Neurologe",
          label12: "Nephrologe",
          label13: "HNO Arzt"
        },
        
        INPUT:{
          label: 'Suche nach einem Arzt oder Therapeuten',
          label1: 'Standort',
          label2: 'Suche',
          label3: 'Auf der Suche nach einem Arzt'
        },
        FILTER_CARDS:{
          label: 'Meistgesuchte Fachgebiete',
          label1: 'Coaching',
          label2: 'Hebamme',
          label3: 'Physiotherapeut:in',
          label4: 'Zahnärzt:in',
          label5: 'Gynäkloge',
          label6: 'Kinderärzt:in',
          label7: 'Hausärzt:in',
          label8: 'Orthopäde:in',
          label9: 'Augenärzt:in',
          label10: 'Plastische Chirurgie',
        },CITIES:{
        
          label: 'Anbieter nach Stadt finden',
          label1: 'Wien',
          label6: 'Klagenfurt',
          label11: 'Wiener Neustadt',
          label16: 'Leonding',
          label2: 'Graz',
          label7: 'Villach',
          label12: 'Wolfsberg',
          label17: 'Klosterneuburg',
          label3: 'Linz',
          label8: 'Wels',
          label13: 'Steyr',
          label18: 'Baden bei Wien',
          label4: 'Salzburg',
          label9: 'Sankt Pölten',
          label14: 'Feldkirch',
          label19: 'Leoben',
          label5: 'Innsbruck',
          label10: 'Dornbirn',
          label15: 'Bregenz',
          label20: 'Traun',
          label21: 'Hausärtzte',
          label22: 'Zahnärte',
          label23: 'Kinderzahnärte',
          label24: 'Kinderärzte',
          label25: 'Hautärzte',
          label26: 'Psychiater',
          label27: 'Therapeuten',
          label28: 'Augenärzte',
          label29: 'HNO-Ärzte',
          label30: 'Notfallversorgung',
          label32: 'Krankenhäuser',
          label33: 'Gynäkologen'
        },
        CITIES1:{
          label: 'Anbieter nach Stadt finden',
          label1: 'Wien',
          label2: 'Klagenfurt',
          label3: 'Wiener Neustadt',
          label4: 'Leonding',
          label5: 'Graz',
          label6: 'Villach',
          label7: 'Wolfsberg',
          label8: 'Klosterneuburg',
          label9: 'Linz',
          label10: 'Wels',
          label11: 'Steyr',
          label12: 'Baden bei Wien',
          label13: 'Salzburg',
          label14: 'Sankt Pölten',
          label15: 'Feldkirch',
          label16: 'Leoben',
          label17: 'Innsbruck',
          label18: 'Dornbirn',
          label19: 'Bregenz',
          label20: 'Traun',
          label21: 'Hausärtzte',
          label22: 'Zahnärte',
          label23: 'Kinderzahnärte',
          label24: 'Kinderärzte',
          label25: 'Hautärzte',
          label26: 'Psychiater',
          label27: 'Therapeuten',
          label28: 'Augenärzte',
          label29: 'HNO-Ärzte',
          label30: 'Notfallversorgung',
          label32: 'Krankenhäuser',
          label33: 'Gynäkologen'
        }
      },
      FOOTER_SECTION:{
          LINKS:{
            label: 'Home',
            label1: 'Über uns',
            label2: 'Blog',
            label3: 'Kontakt',
            label4: 'Allgemeine Nutzungsbedingungen',
            label5: 'Nutzungsbedingungen der Arztme Wesbsite',
            label6: 'Datenschutzhinweise',
            label7: 'Cookie Richtlinie',
            label8: 'Umgang mit Cookies und Zustimmung',
            label9: 'Rechthinweis',
            label10: 'Rechtswidrige Inhalte Melden',
            label11: 'IMPRESSIUM',
            label12: 'Ihre Praxis bei Artzme auflisten',
            label13: 'Marketing für Ihre Praxis',
            label14: 'Karriere',
            label15: 'Englisch',
          }
      },
      HAMBURG:{
        label:'Willkommen',
        label1:'Ihre Praxis',
        label2:' bei ARZTME auflisten'
      }
    },
    AreYouDoc:{
      HEADERS:{
        label: 'Der einfachste Weg zum Wachstum Ihrer Praxis',
        label1: 'Arztme hilft Ihnen, mehr neue Patienten zu gewinnen und zu binden - und spart Ihrer Praxis dabei wertvolle Zeit.',
        label2: 'Sind Sie ein Anbieter, der neue Patienten erreichen möchte?',
        label4: 'Füllen Sie Ihren Terminkalender mit freien Terminen in letzter Minute',
        label5: 'Steigern Sie Ihren Online-Ruf mit verifizierten Bewertungen',
        label6: 'Ihre Praxis bei Arztme auflisten',
        label3: 'Erreichen Sie Patienten in Ihrer Umgebung, die einen neuen Anbieter suchen'
      }
    },
    SEARCHFIL:{
      NAV_BAR_SECTION:{
        SITELOGO:{
          label: 'Finde dein medizinischen Dienstleister'
        },
        DOCTOR_OR_THERAPIST_BTN:{
          label: 'Sind Sie Ärzt:in oder Therapeut:in?'
        },
        HELP:{
          label: 'Hilfe'
        },
        LOGIN:{
          label: 'Login',
          label1:'Logout'
        }
      },
      INPUT:{
        label: 'Suche nach einem Arzt oder Therapeuten',
        label1: 'Standort',
        label2: 'Suche'
      },
      FILTERS:{
        label:'Sprache',
        label1:'Neuer Patienten Aufnahme',
        label2:'Versicherung',
        label3:'Verifiziert'
      },
      FILTERSINFO:{
        label:'Ja',
        label1:'Aufnahmestopp',
        label2:'Gesetzlich',
        label3:'Privatversicherte sowie Selbstzahlende',
        label4:'Englisch',
        label5:'Deutsch',
        label6:'Französisch',
        label7:'Italienisch',
        label8:'Kroatisch',
        label9:'Alle'
      },
      IMAGECARD:{
        label:'Aufnahme neuer Patienten',
        label1:'Durchschnittliche Wartezeit auf einen Termin',
        label2:'Modernizing practice',
        label3:'Google-Bewertung',
        label4:'Montag',
        label5:'Dienstag',
        label6:'Mittwoch',
        label7:'Donnerstag',
        label8:'Freitag',
        label9:'Samstag',
        label10:'Termin Buchen',
        label11:'Öffnungszeiten',
        label12:'Tage'
      },
      NOMATCHES:{
        label:'Leider wurden keine Treffer gefunden',
        label1:'Überprüfen Sie Ihre Schreibweise und versuchen Sie es erneut.',
        label2:'Versuchen Sie andere Schlüsselwörter.'
      },
      STARMODAL:{
        label:'Sie wird von vielen Gesundheitsdienstleistern angestrebt, aber nur wenigen Auserwählten gewährt, die sich wirklich auszeichnen.',
        label1:'Hochwertige Pflege',
        label2:'Hervorragende Betreuung',
        label3:'Außergewöhnlicher Gesundheitsdienstleister',
      }
    },
    FORGOTPAGE:{
      label:'Passwort Vergessen',
      label1:'E-Mail-Adresse',
      label2:'Link zum Zurücksetzen senden',
      label3:'Ein Link zum Zurücksetzen des Passworts wurde an Ihre E-Mail-Adresse gesendet.',
      label4:'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal'
    },
    CONTACTUS:{
      label:`Nehmen wir Kontakt auf`,
      label1:'Bei Arztme bieten wir exzellenten Service',
      label2:'Verbinden Sie sich mit uns:',
      label3:'Kontaktieren Sie uns',
      label4:'Name',
      label5:'Email',
      label6:'Telefon',
      label7:'Ihre Anfrage',
    },
    LOGINPAGE:{
      label:`Login`,
      label1:'Ihre E-Mail',
      label2:'Passwort',
      label3:'Login',
      label4:`Sie haben kein Konto`,
      label5:'Melden Sie sich an',
      label6:'Passwort vergessen?',
    },
    SIGNUPPAGE:{
      label:`Ein Konto erstellen`,
      label1:'Ihr Name',
      label2:'Ihre E-Mail',
      label3:'Passwort',
      label4:`Wiederholen Sie Ihr Passwort`,
      label5:'Ich stimme allen Aussagen zu',
      label6:'Nutzungsbedingungen',
      label7:'Registrieren',
      label8:'Sie haben bereits ein Konto?',
      label9:'Melden Sie sich hier an'
    },
    ABOUTUSPAGE:{
      label:'Über Arztme',
      label1:'Tausende von Anbietern.',
      label2:'Eine Website.',
      label3:'Der schnellste und einfachste Weg, Ärzte und Therapeuten in Ihrer Nähe zu finden',
      label4:'Beratung Vereinbaren',
    },
    IMPRESSIUMPAGE:{
      label:'Impressum'
    },
    CAREERSPAGE:{
      label:'Karrieren',
      label1:'Werden Sie Teil unseres Teams',
      label2:`Bei Arztme™ bauen wir etwas Neues, Kühnes und aufregend. Während wir wachsen, suchen wir nach leidenschaftlichen, innovativen und engagierten Personen, die bereit sind, etwas zu bewirken. Egal, ob Sie ein Designer, Entwickler, Vermarkter oder Stratege sind, wenn Sie nach einem Ort suchen, an dem Sie beitragen, lernen und wachsen können, würden wir uns freuen, von Ihnen zu hören.`,
      label3:'Wir sind ein schnelllebiges Startup, das von Kreativität, Zusammenarbeit und den Drang, reale Probleme zu lösen. Wir glauben an unser Team zu befähigen, Verantwortung zu übernehmen, neue Dinge auszuprobieren und die Grenzen des Möglichen zu erweitern.',
      label4:`Warum bei uns arbeiten?`,
      label5:'Flexibel Umfeld:',
      label6:'Wir legen Wert auf eine ausgewogene Work-Life-Balance und bieten Flexibilität bei und wann Sie arbeiten.',
      label7:'Wachstum:',
      label8:'Als wachsendes Startup-Unternehmen bieten wir Ihnen viel Raum, um Ihre Fähigkeiten zu erweitern, neue Herausforderungen anzunehmen und Ihre Karriere voranzutreiben.',
      label9:'Kollaborative Kultur:',
      label10:'Sie arbeiten eng mit einem leidenschaftlichen, unterstützenden Team von Denkern, die etwas bewirken wollen.',
      label11:'Machen Sie Einfluss:',
      label12:'Ihre Arbeit wird vom ersten Tag an von Bedeutung sein. Bei Arztme™ schätzen wir den Beitrag jedes Einzelnen und glauben, dass jeder eine wichtige Rolle für unseren Erfolg.',
      label13:'Aktuelle Stellenausschreibungen',
      label14:'Wir nehmen fortlaufend Bewerbungen an, wenn Sie also wenn Sie von unserer Arbeit begeistert sind, aber keine Stelle sehen, die perfekt passen, können Sie sich gerne bei uns melden! Wir sind immer auf der Suche nach talentierten Menschen, die unser Team verstärken.',
      label15:'Wie man sich bewirbt:',
      label16:'Bitte reichen Sie Ihren Lebenslauf und ein kurzes Anschreiben ein, in dem Sie erklären, warum Sie warum Sie sich für diese Gelegenheit interessieren. Fügen Sie unbedingt eine Mappe oder Arbeitsproben (falls zutreffend). Wir werden die eingehenden Bewerbungen prüfen und laden qualifizierte Bewerber zu einem Vorstellungsgespräch ein.',
      label17:'Sind Sie bereit, etwas zu bewirken? Bewerben Sie sich noch heute!',
      label18:'',
      label19:'',
      label20:'',
    },
    RESETPASSWORDPAGE:{
      label:`Neues Passwort erstellen`,
      label1:'Neues Passwort',
      label2:'Bestätigen Sie das neue Passwort',
      label3:'Passwort zurücksetzen',
    },
    MARKETTINGPAGE:{
      label:'Vermarktung Ihrer Praxis',
      label1:'Tausende von Anbietern.',
      label2:'Eine Website.',
      label3:'Der schnellste und einfachste Weg, Ärzte und Therapeuten in Ihrer Nähe zu finden',
      label4:'Beratung Vereinbaren',
    },
    DOCPROFILEPAGE:{
      label:'Bewertung',
      label1:'Bearbeiten Sie Ihr Profil',
      label2:'Termin buchen',
      label3:'Über mich',
      label4:'Karte anzeigen',
      label5:'Versicherung',
      label6:'Zeitangaben',
      label7:'Abschluss',
      label8:'Zertifikate',
      label9:'Fotos und Videos',
      label10:'Personen in meinem Netzwerk',
      label11:'Zusammenfassung der Arztme-Rezensionen',
      label12:'Schreiben Sie eine Bewertung',
      label13:'Melden Sie sich an, um eine Bewertung zu schreiben',
      label14:'Bewertungen',
      label15:'Mehr anzeigen',
      label16:'Bearbeiten Sie Ihre Bewertung',
      label17:'Veröffentlichen Sie Ihre Bewertung',
      label18:'',
      label19:'',
      label20:'',
    },
    BOOKINGMODAL:{
      label:'Buchen Sie einen Termin',
      label1:`Ich verstehe, dass Sie Online-Termine bei Ihrem Wunscharzt buchen möchten, diese sind jedoch nicht über GoArzt verfügbar. Wir sind hier, um zu helfen! Wenn Sie möchten, können wir Ihnen bei der Vereinbarung eines Termins in deren Büro oder bei einem anderen Anbieter in der Nähe behilflich sein.`,
      label2:'Bitte beantworten Sie die folgenden Fragen, damit wir die beste Option für Sie finden können',
      label3:'Vor- und Nachname',
      label4:'Welche Tageszeit passt am besten zu Ihnen?',
      label5:'Alle bestimmten Tage oder Zeiten, die Sie vermeiden sollten',
      label6:'Ist dieser Termin dringend?',
      label7:'Welche Krankenversicherung haben Sie?',
      label8:'Versicherungsnummer',
      label9:'Gibt es sonst noch etwas, was wir wissen sollten? (z. B. Sprachpräferenzen) (Optional)',
      label10:'Ihre Telefonnummer.',
      label11:'E-Mail-Adresse',
      label12:'Sie stimmen unseren Allgemeinen Geschäftsbedingungen zu',
      label13:'Einreichen',
      label14:'Morgen',
      label15:'Nachmittag',
      label16:'Abend',
      label17:'Ja',
      label18:'Nein, ich kann eine Woche oder länger warten',
      label19:'Öffentlich',
      label20:'Privat oder Selbstzahler',
      label21:'Versicherungsnummer',
      label22:'Telefonnummer',
      label23:'Geben Sie Ihre E-Mail-Adresse ein',
      label24:'Geben Sie Ihren Namen ein',
      label25:'Bestimmte Tage/Zeiten',
      label26:'Der vollständige Name ist erforderlich.',
      label27:'Bitte wählen Sie ein bevorzugtes Zeitfenster aus.',
      label28:'Bitte geben Sie die bevorzugten Tage/Uhrzeiten an, die Sie meiden möchten.',
      label29:'Bitte geben Sie an, ob der Termin dringend ist.',
      label30:'Bitte wählen Sie eine Versicherungsart aus.',
      label31:'Versicherungsnummer ist erforderlich.',
      label32:'Bitte geben Sie eine gültige 10-stellige Telefonnummer ein.',
      label33:'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
      label34:'Sie müssen den Allgemeinen Geschäftsbedingungen zustimmen.',
      label35:'',
    },
    DOCEDIT:{
      SIDEBAR:{
        label:'Grundlegende Informationen',
        label1:'Kontaktinformationen',
        label2:'Über Informationen',
        label3:'Versicherungsinformationen',
        label4:'Bildungsinformationen',
        label5:'Öffnungszeiten',
      },
      BASICINFO:{
        label:'Wählen Sie Ihren Titel aus',
        label1:'Vorname',
        label2:'Nachname',
        label3:'Beruf',
        label4:'Spezialität',
        label5:'Sprache',
        label6:'Änderungen speichern',
      },
      CONTACTINFO:{
        label:'Adresse',
        label1:'Straße',
        label2:'Bereich',
        label3:'Land',
        label4:'Zustand',
        label5:'Stadt',
        label6:'PIN Code',
        label7:'E-Mail-ID',
        label8:'Isd Code',
        label9:'Telefon',
        label10:'Änderungen speichern',
      },
      ABOUTINFO:{
        label:'Über mich',
        label1:'Neue Patientenakzeptanz',
        label2:'Durchschnittliche Wartezeit für einen Termin',
        label3:'Änderungen speichern',
      },
      INSURANCEINFO:{
        label:'Private Versicherung',
        label1:'Öffentliche Versicherung',
        label2:'Änderungen speichern',
      },
      EDUCATIONINFO:{
        label:'Abschluss',
        label1:'Zertifikate',
        label2:'Änderungen speichern',
      }
    }

  }
  
  export { germanStrings }; 