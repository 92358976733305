import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import HamburgerComponent from './HamburgerComponent';
import './../css/FinalDoctorRegisterComponent.css'
import Constants from '../Constants';
import { useNavigate } from "react-router-dom";


export default function FinalDoctorRegisterComponent() {

  const [searchTextTop, setSearchTextTop] = useState("Dear Doctor, please mention your practice details");

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");

  const templateApiUrl = process.env.REACT_APP_JAVA_BASE_URL + process.env.REACT_APP_TEMPLATE_API_REL_URL
  const doctorRegistrationUrl = process.env.REACT_APP_JAVA_BASE_URL + Constants.DOCTOR_REGISTRATION_REL_URL;

  const navigate = useNavigate();
  const gotToNewPage=()=>{
    navigate("/user-register-success");
  }

  
  const fetchTemplateApiData = async (url) => {


    const data = await callTemplateApi(url);
    setCountries(data.countryData);    
  }

  const callTemplateApi = async (url) => {

    const response = await fetch(url);
    const body = await response.json();
    //body);
    return body;
  }

  // componentDidMount
  useEffect(() => {
    fetchTemplateApiData(templateApiUrl)
  }, [])


  const handleCountryChange = (e) => {
    setCountry(e.target.value);

    let countryIdx = countries.findIndex(country => country.id == e.target.value);

    setStates(countries[countryIdx].states);
    setCities("");

    setState("");
    setCity("");
  };

  const handleStateChange = (e) => {
    setState(e.target.value);

    let stateIdx = states.findIndex(state => state.id == e.target.value);

    setCities(states[stateIdx].cities);
    setCity("");
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
  };



  async function handleFormSubmit(e) {

    // Prevent the browser from reloading the page
    e.preventDefault();

    // Read the form data
    const form = e.target;
    const formData = new FormData(form);
    // Or you can work with it as a plain object:
    const formJson = Object.fromEntries(formData.entries());
    

    let payloadObj = payloadFromFormData(formJson);
   

    setSearchTextTop("Registration submitted. Please check your email for next steps.");
    let resp = await callRegisterHandler(doctorRegistrationUrl, payloadObj);


    if (resp.success) {
      setSearchTextTop("Registration submitted. Please check your email for next steps.");
      gotToNewPage();
    } else {
      setSearchTextTop("Registration failed. Some issue. Please contact us or try again later");
      alert("Registration failed. Some issue. Please contact us or try again later");
    }

  }

  function payloadFromFormData(form){
    let requestBody = {
      displayName: form.displayName,
      languagesSpoken: form.languagesSpoken,
      centers:[]
    };

    let center = {
      countryId: form.country,
      stateId : form.state,
      cityId: form.city,
      address: form.address,
      street: form.street,
      area: form.area,
      pincode: form.pincode,
      lat: 1111.1111,
      lng: 2222.2222,
      phone: form.phone,
      status: 1
    }

    requestBody.centers.push(center);

      /*
      userId
      displayName
      centers: [{   
                  "countryId": 1,
                  "stateId" : 3,
                  "cityId": 2,
                  "address": "98765",
                  "street": "Alpha2 main road",
                  "area": "Alpha2",
                  "pincode": 201304,
                  "lat": 11.11,
                  "lng": 22.22,
                  "phone": "919891166236",
                  "status": 1
              }]
      languagesSpoken
      */
    
    return requestBody;
  }

  function registrationSuccessFunc(){
    // let div = document.getElementById("doctor-registration-form-id")
    // div.replaceChildren();

  }

  async function callRegisterHandler(url, body) {

    let resp = await callRegisterApi(url, body);

    if (resp && resp.status === 200)
      return { success: true };
    else
      return { success: false };

  }

  const callRegisterApi = async (url, body) => {

    // Post with fetchAPI
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
    let resp = await response;

    return resp;
  }

  return (
    <div id="doctor-registration-wrapper-id" class="doctor-registration-wrapper">

      <div id="doctor-registration-form-id" class="doctor-registration-form">

        <h1>{searchTextTop}</h1>

        <form class="form-horizontal" onSubmit={handleFormSubmit} method="post">

          <div class="doctor-registration-item-div">
            <label for="displayName">Display Name</label><br />
            <i class="zmdi zmdi-hc-lg"></i>
            <input type="text" id="displayName" name="displayName" class="dr-reg-textbox" placeholder="Please enter complete name. Eg. Dr. Stephan Hinz" />
          </div>

          <div class="doctor-registration-item-div">
            <label for="languagesSpoken">Languages Spoken</label>
            <i class="zmdi zmdi-hc-lg"></i>
            <input type="text" id="languagesSpoken" name="languagesSpoken" class="dr-reg-textbox" placeholder="Please mention languages spoken" />
          </div>

          <div class="doctor-registration-item-div">
            <label for="practice">Practice</label>
            <i class="zmdi zmdi-hc-lg"></i>
            <input type="text" id="fullname" name="fullname" class="dr-reg-textbox" placeholder="Please enter practice. Eg. Physician, Orthepaedic" />
          </div>





          <div class="doctor-registration-item-div">
            <label for="country">Country</label>
            <i class="zmdi zmdi-hc-lg"></i>
            {/*"haha chaaap diya country")*/}
            <select onChange={handleCountryChange} id="country" name="country" class="dr-reg-textbox" placeholder="Please select your country" >
              <option value="">Select Country</option>
              {countries ? (countries.map(
                (country) => {
                 
                  return (
                    <option key={country.id} value={country.id} > {country.name} </option>
                  )
                })) : null

              }
            </select>
          </div>

          <div class="doctor-registration-item-div">
            <label for="state">State</label>
            <i class="zmdi zmdi-hc-lg"></i>
            {/*"haha chaaap diya state")*/}
            <select disabled={country == ""} onChange={handleStateChange} id="state" name="state" class="dr-reg-textbox" placeholder="Please select your state" >
              <option value="">Select State</option>
              {states ? (states.map(
                (state) => {
                  
                  return (
                    <option key={state.id} value={state.id} > {state.name} </option>
                  )
                })) : null

              }
            </select>
          </div>

          <div class="doctor-registration-item-div">
            <label for="city">City</label>
            <i class="zmdi zmdi-hc-lg"></i>
            {/*"haha chaaap diya city")*/}
            <select disabled={state == ""} onChange={handleCityChange} id="city" name="city" class="dr-reg-textbox" placeholder="Please select your city" >
              <option value="">Select City</option>
              {cities ? (cities.map(
                (city) => {
                  
                  return (
                    <option key={city.id} value={city.id} > {city.name} </option>
                  )
                })) : null

              }
            </select>
          </div>


          <div class="doctor-registration-item-div">
            <label for="address">Address</label>
            <i class="zmdi zmdi-hc-lg"></i>
            <input type="text" id="address" name="address" class="dr-reg-textbox" placeholder="Please enter your address. Eg. 123, Top4, Bayerhamerstrasse" />
          </div>

          <div class="doctor-registration-item-div">
            <label for="street">Street</label>
            <i class="zmdi zmdi-hc-lg"></i>
            <input type="text" id="street" name="street" class="dr-reg-textbox" placeholder="Please enter your street. Eg. PetersBrunnerstrasse" />
          </div>

          <div class="doctor-registration-item-div">
            <label for="address">Area</label>
            <i class="zmdi zmdi-hc-lg"></i>
            <input type="text" id="area" name="area" class="dr-reg-textbox" placeholder="Please enter your area. Eg. Maxglan" />
          </div>

          <div class="doctor-registration-item-div">
            <label for="pincode">Pincode</label>
            <i class="zmdi zmdi-hc-lg"></i>
            <input type="text" id="pincode" name="pincode" class="dr-reg-textbox" placeholder="Please enter pincode" />
          </div>


          <div class="doctor-registration-item-div">
            <label for="phone">Phone number</label>
            <i class="zmdi zmdi-hc-lg"></i>
            <input type="text" id="phone" name="phone" class="dr-reg-textbox" placeholder="Please enter your phone number" />
          </div>

    {/*
          <div class="doctor-registration-item-div">
            <label for="specialisation">Specialisation</label>
            <i class="zmdi zmdi-hc-lg"></i>
            <input type="text" id="specialisation" name="specialisation" class="dr-reg-textbox" placeholder="Eg. ortho, physician, paediatrician" />
          </div>

          <div class="doctor-registration-item-div">
            <label for="qualification">Qualification</label>
            <i class="zmdi zmdi-hc-lg"></i>
            <input type="text" id="qualification" name="qualification" class="dr-reg-textbox" placeholder="Eg. MBBS, MS, MD... I will keep this as bubbles later(multi value)" />
          </div>

          <div class="doctor-registration-item-div">
            <label for="profession">Profession</label>
            <i class="zmdi zmdi-hc-lg"></i>
            <input type="text" id="profession" name="profession" class="dr-reg-textbox" placeholder="Eg. MBBS doctor. Hata doon kya isko?" />
          </div>
    */}

          <div class="doctor-registration-item-div">
            <button type="submit" class="text-search-submit" id="doctor-register-submit" placeholder="" > Sign up</button>
          </div>

        </form>
      </div>

    </div>
  );
}


/*
Sample api hit



{   
    "userId": 1,
    "displayName": "Dr. Siddharth Singh",
    // "rating": 5,   
    // "ratingCount": 114,
    "centers":[
        {   
            "countryId": 1,
            "stateId" : 3,
            "cityId": 2,
            "address": "98765",
            "street": "Alpha2 main road",
            "area": "Alpha2",
            "pincode": 201304,
            "lat": 11.11,
            "lng": 22.22,
            "phone": "919891166236",
            "status": 1
        }, 
        {
            "countryId": 1,
            "stateId" : 3,
            "cityId": 2,
            "address": "56789",
            "street": "Alpha2 main road",
            "area": "Alpha2",
            "pincode": 201304,
            "lat": 11.11,
            "lng": 22.22,
            "phone": "919891166236",
            "status": 1
        }
    ]
    //,
    //"languagesSpoken": "1,3"
    //"practice":"",
    //"aboutUs": ""
}

*/