import Constants from '../../../Constants';

const templateApiUrl = process.env.REACT_APP_JAVA_BASE_URL + process.env.REACT_APP_TEMPLATE_API_REL_URL

export default async function getTemplate() {
  let resp = await callGetDataHandler(templateApiUrl);
  return resp;
}

async function callGetDataHandler(url) {

  let resp = await callHttpApi(url);

  let respJson = await resp.json();

  if (resp && resp.status === 200)
    return { success: true, respJson: respJson };
  else
    return { success: false, respJson: respJson };

}

const callHttpApi = async (url) => {

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  });
  let resp = await response;

  return resp;
}
