import UserRegisterComponent from "../components/UserRegisterComponent";
import HamburgerComponent from "../components/HamburgerComponent";
import ZocdocLogoComponent from "../components/ZocdocLogoComponent";

export default function Home() {
    return (
        <div class="homepage" id="homepage-id">
            <ZocdocLogoComponent />
            <HamburgerComponent />
            <UserRegisterComponent />
        </div>
    )
}