import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios'; 
import { useLanguage } from '../../../homepage/components/LanguageContext';

const EducationInfo = () => {
  const { user } = useOutletContext();
  const [graduation, setGraduation] = useState(user.education || "" );
  const [diplomaCertificate, setDiplomaCertificate] = useState(""); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updateData = {
      education: graduation,
    };

    try {
      const response = await axios.put(`${process.env.REACT_APP_JAVA_BASE_URL}/doctor?doctorId=${user.id}`, updateData);
      if (response.status === 200) {
        console.log('Data updated successfully:', response.data);
        alert("Doctor details updated successfully");
      } else {
        console.log('Failed to update data.');
      }
    } catch (err) {
      console.error('API error:', err);
    }
  };

  const { langStrings} = useLanguage();
  const ST = langStrings.DOCEDIT.EDUCATIONINFO;

  return (
    <div className="p-4 bg-white rounded shadow-sm">
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formGraduation">
          <Form.Label>{ST.label}</Form.Label>
          <Form.Control
            as="textarea"
            rows={4} 
            value={graduation}
            placeholder="Institute Name / Degree Name "
            onChange={(e) => setGraduation(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formDiplomaCertificate">
          <Form.Label>{ST.label1}</Form.Label>
          <Form.Control
            as="textarea"
            rows={4} 
            value={diplomaCertificate}
            placeholder="Your Certifications"
            onChange={(e) => setDiplomaCertificate(e.target.value)}
          />
        </Form.Group>
        <Button style={{ backgroundColor: '#f35b2a' }} type="submit">
          {ST.label2}
        </Button>
      </Form>
    </div>
  );
};

export default EducationInfo;
