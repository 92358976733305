import React from 'react';
import { BrowserRouter as Router, Routes, Route, Switch, Link } from 'react-router-dom';
import './css/SearchFilterFragment.css';

const SearchFilterFragment = (props) => {

  let count = 0;

  function showOrHideSideBar(e) {

    count++;

    if (count % 2 == 1) {
      document.getElementById("filter-sidebar").style.display = 'flex';
      document.getElementById("filter-show").style.display = 'none';
      document.getElementById("filter-cancel").style.display = 'flex';
    }
    else {
      document.getElementById("filter-sidebar").style.display = 'none';
      document.getElementById("filter-show").style.display = 'flex';
      document.getElementById("filter-cancel").style.display = 'none';
    }
  }

  /*
  function showLoginOrLogoutPageLink(){
    if(isAuthenticated()){
      return <li class="sidebar-li" onClick={() => signOut()} ><Link to="/"> <i class="zmdi zmdi-sign-in zmdi-hc-lg"></i> &nbsp;&nbsp; Logout </Link></li>
    } else {
      return <li class="sidebar-li"><Link to="/user-login">User & Doctor Login page</Link></li>
    }
  }
  */

  function getCompo(filter) {

      return (
          <div class="search-filter-each-div">
              <label class="labelc" htmlFor={filter.filterName}></label>
              <i class="zmdi zmdi-hc-lg"></i>

              <p>{filter.filterName}</p>
              <select multiple={true} name={filter.filterName} class="user-reg-textbox" placeholder="Please select your state" style={{ "height": "100%", "width": "100%" }}>
                 {/* <option value="">{props.data.selectDefaultValue}</option> */}
                 
                 
                  {filter.data ? (Object.keys(filter.mapData).map(
                      (each) => {
                          //"bhai ye hai individual unit ka naam" + { each });
                          return (
                              <option key={each} value={each} > {each} </option>
                          )
                      })) : null

                  }
              </select>
          </div>
      );
  }

  function populateFilter(){

  

    

    return (props.srpData.filterList ? (props.srpData.filterList.map(
        (filter) => {
         
          return getCompo(filter);
        })) : null
      );


  }

  return (

    <nav class="filterbar-parent" id="sidebar-parent-id">

      <nav class="filterbar-cta" id="hamburger-id">
        <ul>
          <li id="filter-show" class="sidebar-li-filter-show" onClick={showOrHideSideBar}><a href="#"> Show Filters HOHOHO </a></li>
          <li id="filter-cancel" class="sidebar-li-filter-show-cancel" onClick={showOrHideSideBar}><a href="#"> Hide Filter LALALA </a></li>
        </ul>
      </nav>

      <nav class="filterbar-filters" id="filter-sidebar">
        <ul>
          {/*<li class="sidebar-li" onClick={showSideBar}><a href="#"><svg xmlns="http://www.w3.org/2000/svg" height="26px" viewBox="0 -960 960 960" width="26px" fill="#5f6368"><path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z"/></svg></a></li> */}
          <li class="sidebar-li"><Link to="/">BEHENCHOD</Link></li>
          <li class="sidebar-li"><Link to="/search"> FILTERS YE HAIN SAARE NEECHE</Link></li>
          
        </ul>


        <select id="example-getting-started" multiple="multiple">
    <option value="cheese">Cheese</option>
    <option value="tomatoes">Tomatoes</option>
    <option value="mozarella">Mozzarella</option>
    <option value="mushrooms">Mushrooms</option>
    <option value="pepperoni">Pepperoni</option>
    <option value="onions">Onions</option>
    </select>



    
<div class="dropdown">
  <button>Select Fruits</button>
  <div class="dropdown-content">
    <label><input type="checkbox" name="fruits" value="apple"/> Apple</label>
    <label><input type="checkbox" name="fruits" value="orange"/> Orange</label>
    <label><input type="checkbox" name="fruits" value="banana"/> Banana</label>
    <label><input type="checkbox" name="fruits" value="grape"/> Grape</label>
  </div>
</div>

        <form class="user-profile-update-form" onSubmit={props.handleFormSubmit} method="get">
        {populateFilter()}

        <div class="user-profile-update-div">
            <button type="submit" class="user-profile-update-submit" id="user-profile-update-submit" placeholder="" > Submit Filters and Reload </button>
        </div>

        </form>
      </nav>

    </nav>

  );
};

export default SearchFilterFragment;