//import DocSearchComponent from "./../components/DocSearchComponent";
import HamburgerComponent from "../components/HamburgerComponent.js";
import ZocdocLogoComponent from "../components/ZocdocLogoComponent.js";

import DoctorRegisterNew from "../features_new/doctor_registration/components/DoctorRegisterNew.jsx"

export default function DoctorRegisterPageNew() {
    return (
        <div class="homepage" id="homepage-id">
            <DoctorRegisterNew />
        </div>
    )
}