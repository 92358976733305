//import DocSearchComponent from "./../components/DocSearchComponent";
import HamburgerComponent from "./../components/HamburgerComponent";
import ZocdocLogoComponent from "./../components/ZocdocLogoComponent";
import DocCategoryCarousel from "../components/DocCategoryCarousel";
import DoctorSearch from "./../features/search/components/DoctorSearch";

import Homepage from "./../features_new/homepage/components/Homepage"

export default function Home() {
    return (
        <div class="homepage" id="homepage-id">
            {/*<ZocdocLogoComponent />
            
            <HamburgerComponent />*/}
            {/*
            <DoctorSearch />
            <DocCategoryCarousel />
            */}
            <Homepage />
        </div>
    )
}