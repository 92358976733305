import React from 'react';
import "../../../css/AreYouDoc.css"
import { useLocation, useNavigate } from 'react-router-dom';
import { useLanguage } from './LanguageContext.js';
import { useIsAuthenticated, useAuthUser } from 'react-auth-kit';
import AreYouDocView from './AreYouDocView.jsx';


const AreYouDoc = () => {
  let isAuthenticated = useIsAuthenticated();
  const authUser = useAuthUser();
  const { langStrings } = useLanguage();
  const ST = langStrings.AreYouDoc;
  const navigate = useNavigate();
  const location = useLocation();
  const gotohome = () => {
    const redirectTo = location.state?.from?.pathname || '/'; 
    navigate(redirectTo);
  };

  const takeToDocRegister = () => {
    if(isAuthenticated() && authUser().isUser){
      navigate('/new-doctor-register');
    }else{
      alert('Please login to continue');
      navigate('/user-login', { state: { from: 'List Your Practice' } });
    }
  }

  return (
    <AreYouDocView 
      ST={ST}
      gotohome={gotohome}
      takeToDocRegister={takeToDocRegister}
    />
  );
};

export default AreYouDoc;
