//import DocSearchComponent from "./../components/DocSearchComponent";
import HamburgerComponent from "./../components/HamburgerComponent";
import ZocdocLogoComponent from "./../components/ZocdocLogoComponent";
import DoctorSearch from "./../features/search/components/DoctorSearch";

export default function Home() {
    return (
        <div class="homepage" id="homepage-id">
            <ZocdocLogoComponent />
            <HamburgerComponent />
            <DoctorSearch />
        </div>
    )
}