import Constants from '../../../Constants';

const userRegistrationUrl = process.env.REACT_APP_JAVA_BASE_URL + process.env.REACT_APP_USER_REGISTRATION_REL_URL;

export default async function updateUserData(e, userId) {
   

    // Prevent the browser from reloading the page
    e.preventDefault();

    // Read the form data
    const form = e.target;
    const formData = new FormData(form);
    // Or you can work with it as a plain object:
    const formJson = Object.fromEntries(formData.entries());
    // "formJson is -->");
    // formJson);

    let payloadObj = payloadFromFormData(formJson);
    // 'Sid transformed payload is : ' );
    // JSON.stringify(payloadObj));

    let resp = await callUpdateApiHandler(userRegistrationUrl + '?' + 'userId=' + userId, payloadObj);

    // 'SID THiS IS THE API RESP');
    // resp);

    return resp;
  }

  function payloadFromFormData(form){
    let requestBody = {
      firstname: form.firstname,
      lastname: form.lastname,
      dob: form.dob,
      sex: form.sex,
      email: form.email,
      password: form.password,
      phone: form.phone,
      form: form.profile_image,
      countryId: form.country,
      stateId : form.state,
      cityId: form.city,
      address: form.address,
      pincode: form.pincode,
      status: 1
    };

    return requestBody;
  }

  async function callUpdateApiHandler(url, body) {
    let resp = await callUpdateApi(url, body);

    let respJson = await resp.json();

    if (resp && resp.status === 200)
      return { success: true, respJson: respJson };
    else
      return { success: false, respJson: respJson };
  }

  const callUpdateApi = async (url, body) => {

    // Post with fetchAPI
    const response = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
    let resp = await response;

    return resp;
  }