import React, { useState } from 'react';
import { Modal, Button, Carousel } from 'react-bootstrap';
import './ImageModal.css'; // Add a custom CSS file for styling

const ImageModal = ({ images }) => {
  const [show, setShow] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = (index) => {
    setSelectedImageIndex(index); // Set which image to show first
    setShow(true);
  };

  // Limit the number of images to a maximum of 9
  const displayedImages = images.slice(0, 9);

  return (
    <>
      {/* Display thumbnails and trigger modal on click */}
      <div className="custom-image-gallery">
        <div className="row">
          {displayedImages.map((image, index) => (
            <div key={index} className="col-6 col-md-4 mb-3">
              <img
                src={image.url}
                alt={image.alt}
                className="img-fluid"
                style={{ cursor: 'pointer' }}
                onClick={() => handleShow(index)}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Modal with Carousel */}
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Image Gallery</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel
            activeIndex={selectedImageIndex}
            onSelect={(index) => setSelectedImageIndex(index)}
            interval={null}
          >
            {images.map((image, index) => (
              <Carousel.Item key={index}>
                <img
                  className="d-block w-100"
                  src={image.url}
                  alt={image.alt}
                  style={{ height: '500px', objectFit: 'contain' }}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImageModal;
