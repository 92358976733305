import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import { useLanguage } from '../../../homepage/components/LanguageContext';

const ContactInfo = () => {
  const { user} = useOutletContext();
  const center = user? user.centers[0] : {};

  const [address, setAddress] = useState(center.address || '');
  const [street, setStreet] = useState(center.street || '');
  const [area, setArea] = useState(center.area || '');
  const [email, setEmail] = useState(center.mail || '');
  const [pincode, setPincode] = useState(center.pincode || '');
  const [phone, setPhone] = useState(center.phone || '');
  const [isdCode, setisdCode] = useState(center.isdCode || '');

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);

  const { langStrings} = useLanguage();
  const ST = langStrings.DOCEDIT.CONTACTINFO;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_JAVA_BASE_URL}/template`);
        const result = await response.json();
        setCountries(result.countryData || []);
      } catch (error) {
        console.error('Error fetching location data:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      const country = countries.find((c) => c.id === parseInt(selectedCountry.value));
      setStates(country?.states || []);
      setSelectedState(null);
      setCities([]);
    }
  }, [selectedCountry, countries]);

  useEffect(() => {
    if (selectedState) {
      const state = states.find((s) => s.id === parseInt(selectedState.value));
      setCities(state?.cities || []);
      setSelectedCity(null);
    }
  }, [selectedState, states]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedData = {
      centers: [
        {
          id: center.id,
          doctorId: user.id,
          practiceSize:center.practiceSize,
          address,
          street,
          area,
          pincode,
          countryId: selectedCountry?.value || user.countryId,
          stateId: selectedState?.value || user.stateId,
          cityId: selectedCity?.value || user.cityId,
          mail:email,
          lat:center.lat,
          lng:center.lng,
          phone,
          isdCode,
          createdAt:center.createdAt,
          updatedAt: center.updatedAt,
          attributes: { 
            property1: "property1 value", 
            prop2: "property2 value" 
          },
          status: center.status
        }
      ]
    };


    try {
      const response = await fetch(`${process.env.REACT_APP_JAVA_BASE_URL}/doctor?doctorId=${user.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedData)
      });

      if (response.ok) {
        const result = await response.json();
        alert("Doctor details updated successfully");
        console.log('Doctor data updated successfully:', result);
      } else {
        console.error('Error updating doctor data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="p-4 bg-white rounded shadow-sm">
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formaddress">
          <Form.Label>{ST.label}</Form.Label>
          <Form.Control
            type="text"
            value={address}
            placeholder="Enter Your Address"
            onChange={(e) => setAddress(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formstreet">
          <Form.Label>{ST.label1}</Form.Label>
          <Form.Control
            type="text"
            value={street}
            placeholder="Enter Your Street name"
            onChange={(e) => setStreet(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formarea">
          <Form.Label>{ST.label2}</Form.Label>
          <Form.Control
            type="text"
            value={area}
            placeholder="Enter Your Locality"
            onChange={(e) => setArea(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formtitle">
            <Form.Label>{ST.label3}</Form.Label>
            <Select 
              options={countries.map(country => ({ value: country.id, label: country.name }))}
              value={selectedCountry?.id}
              onChange={(selectedOption) => setSelectedCountry(selectedOption)}
              placeholder={selectedCountry?.name || 'Select Country'}
              styles={{
                control: (base) => ({
                  ...base,
                  height: 'calc(1.5em + 0.75rem + 2px)', // Bootstrap's form-control height
                  border: '1px solid #ced4da', // Bootstrap's border
                  borderRadius: '0.375rem', // Bootstrap's border-radius
                  fontSize: '1rem', // Bootstrap's font-size
                  width: '100%', // Full-width
                  paddingRight: '0.75rem', // Bootstrap padding
                  boxShadow: 'none', // Remove shadow
                  transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out', // Bootstrap transition
                }),
                input: (base) => ({
                  ...base,
                  fontSize: '1rem', // Matching Bootstrap font-size
                  color: '#495057', // Bootstrap input text color
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 1000, // Ensure dropdown shows above other content
                  backgroundColor: 'white',
                  border: '1px solid #ced4da',
                  borderRadius: '0.375rem', // Match Bootstrap's dropdown menu styling
                  boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)', // Light shadow for the dropdown
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected ? '#0d6efd' : 'white', // Bootstrap primary color for selected
                  color: state.isSelected ? 'white' : '#495057', // White text when selected, black otherwise
                }),
                multiValue: (base) => ({
                  ...base,
                  backgroundColor: '#e9ecef', // Light gray background for selected values
                  borderRadius: '0.375rem',
                }),
                multiValueLabel: (base) => ({
                  ...base,
                  fontSize: '0.875rem', 
                  padding: '0.25rem 0.5rem',
                }),
                multiValueRemove: (base) => ({
                  ...base,
                  color: '#495057', 
                  ':hover': {
                    backgroundColor: '#ced4da', 
                    color: 'black',
                  },
                }),
              }}
            />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formtitle">
            <Form.Label>{ST.label4}</Form.Label>
            <Select 
               options={states.map(state => ({ value: state.id, label: state.name }))}
               value={selectedState?.id}
               onChange={(selectedOption) => setSelectedState(selectedOption)}
               placeholder={selectedState ? selectedState.label : 'Select State'}
               isDisabled={!selectedCountry}
              styles={{
                control: (base) => ({
                  ...base,
                  height: 'calc(1.5em + 0.75rem + 2px)', // Bootstrap's form-control height
                  border: '1px solid #ced4da', // Bootstrap's border
                  borderRadius: '0.375rem', // Bootstrap's border-radius
                  fontSize: '1rem', // Bootstrap's font-size
                  width: '100%', // Full-width
                  paddingRight: '0.75rem', // Bootstrap padding
                  boxShadow: 'none', // Remove shadow
                  transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out', // Bootstrap transition
                }),
                input: (base) => ({
                  ...base,
                  fontSize: '1rem', // Matching Bootstrap font-size
                  color: '#495057', // Bootstrap input text color
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 1000, // Ensure dropdown shows above other content
                  backgroundColor: 'white',
                  border: '1px solid #ced4da',
                  borderRadius: '0.375rem', // Match Bootstrap's dropdown menu styling
                  boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)', // Light shadow for the dropdown
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected ? '#0d6efd' : 'white', // Bootstrap primary color for selected
                  color: state.isSelected ? 'white' : '#495057', // White text when selected, black otherwise
                }),
                multiValue: (base) => ({
                  ...base,
                  backgroundColor: '#e9ecef', // Light gray background for selected values
                  borderRadius: '0.375rem',
                }),
                multiValueLabel: (base) => ({
                  ...base,
                  fontSize: '0.875rem', // Match Bootstrap label sizing for multi-select
                  padding: '0.25rem 0.5rem',
                }),
                multiValueRemove: (base) => ({
                  ...base,
                  color: '#495057', // Bootstrap text color
                  ':hover': {
                    backgroundColor: '#ced4da', // Hover effect for removing tag
                    color: 'black',
                  },
                }),
              }}
            />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formtitle">
            <Form.Label>{ST.label5}</Form.Label>
            <Select 
              options={cities.map(city => ({ value: city.id, label: city.name }))}
              value={selectedCity?.id}
              onChange={(selectedOption) => setSelectedCity(selectedOption)}
              placeholder={selectedCity?.name || 'Select City'}
              isDisabled={!selectedState}
              styles={{
                control: (base) => ({
                  ...base,
                  height: 'calc(1.5em + 0.75rem + 2px)', // Bootstrap's form-control height
                  border: '1px solid #ced4da', // Bootstrap's border
                  borderRadius: '0.375rem', // Bootstrap's border-radius
                  fontSize: '1rem', // Bootstrap's font-size
                  width: '100%', // Full-width
                  paddingRight: '0.75rem', // Bootstrap padding
                  boxShadow: 'none', // Remove shadow
                  transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out', // Bootstrap transition
                }),
                input: (base) => ({
                  ...base,
                  fontSize: '1rem', // Matching Bootstrap font-size
                  color: '#495057', // Bootstrap input text color
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 1000, // Ensure dropdown shows above other content
                  backgroundColor: 'white',
                  border: '1px solid #ced4da',
                  borderRadius: '0.375rem', // Match Bootstrap's dropdown menu styling
                  boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)', // Light shadow for the dropdown
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected ? '#0d6efd' : 'white', // Bootstrap primary color for selected
                  color: state.isSelected ? 'white' : '#495057', // White text when selected, black otherwise
                }),
                multiValue: (base) => ({
                  ...base,
                  backgroundColor: '#e9ecef', // Light gray background for selected values
                  borderRadius: '0.375rem',
                }),
                multiValueLabel: (base) => ({
                  ...base,
                  fontSize: '0.875rem', // Match Bootstrap label sizing for multi-select
                  padding: '0.25rem 0.5rem',
                }),
                multiValueRemove: (base) => ({
                  ...base,
                  color: '#495057', // Bootstrap text color
                  ':hover': {
                    backgroundColor: '#ced4da', // Hover effect for removing tag
                    color: 'black',
                  },
                }),
              }}
            />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formpincode">
          <Form.Label>{ST.label6}</Form.Label>
          <Form.Control
            type="text"
            value={pincode}
            placeholder="Enter Your PIN Code"
            onChange={(e) => setPincode(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formpemail">
          <Form.Label>{ST.label7}</Form.Label>
          <Form.Control
            type="email"
            value={email}
            placeholder="Enter Your Email Address"
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formisdCode">
          <Form.Label>{ST.label8}</Form.Label>
          <Form.Control
            type="text"
            value={isdCode}
            placeholder="Enter Your Isd Code"
            onChange={(e) => setisdCode(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formphone">
          <Form.Label>{ST.label9}</Form.Label>
          <Form.Control
            type="text"
            value={phone}
            placeholder="Enter Your Phone Number"
            onChange={(e) => setPhone(e.target.value)}
          />
        </Form.Group>

        <Button style={{ backgroundColor: '#f35b2a' }} type="submit">
          {ST.label10}
        </Button>
      </Form>
    </div>
  );
};

export default ContactInfo;
