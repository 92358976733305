import React from 'react';
import { BrowserRouter as Router, Routes, Route, Switch, Link } from 'react-router-dom';
import './../css/HamburgerComponent.css';
import {useSignOut, useIsAuthenticated, useAuthUser } from 'react-auth-kit';

const CustomBurgerIcon = () => <img src="./../hamburger.webp" />;

const HamburgerComponent = () => {

  let isAuthenticated = useIsAuthenticated();
  const authUser = useAuthUser();
  const signOut = useSignOut();

  let count = 0;

  function showOrHideSideBar(e) {
    const sidebar = document.querySelector('.sidebar');
    //sidebar.computedStyleMap.width=0;
    //e.target.style.display = 'flex';
    count++;

    if (count % 2 == 1) {
      document.getElementById("sidebar-id").style.display = 'flex';
      document.getElementById("hamburger-image").style.display = 'none';
      document.getElementById("hamburger-cancel-image").style.display = 'flex';
    }
    else {
      document.getElementById("sidebar-id").style.display = 'none';
      document.getElementById("hamburger-image").style.display = 'flex';
      document.getElementById("hamburger-cancel-image").style.display = 'none';
    }
  }

  function showUserRegisterOrProfileManageLink(){
    if(isAuthenticated() && authUser().isUser){
      //Show Manage User Profile Link
      return <li class="sidebar-li"><Link to="/manage-user-profile"> Manage User Profile </Link></li>
    } else {
      //Show User Registration Link
      return <li class="sidebar-li"><Link to="/user-register"> User Registration</Link></li>
    }
  }

  function showDoctorRegisterOrProfileManageLink(){
    if(isAuthenticated() && authUser().isUser){

      if(authUser().isDoctor){
        //Show Manage Doctor Profile Link (+ joint user profile Manage)
        return <li class="sidebar-li"><Link to="/manage-doctor-profile"> Manage Doctor Profile </Link></li>
      } else {
        //Show Manage User Profile Link
        //Show Doctor Registration Link

        return <li class="sidebar-li"><Link to="/final-doctor-register"> Doctor Registration</Link></li>
      }
    } else {
      //Show Nothing
      
    }
  }  

  function showLoginOrLogoutPageLink(){
    if(isAuthenticated()){
      return <li class="sidebar-li" onClick={() => signOut()} ><Link to="/"> <i class="zmdi zmdi-sign-in zmdi-hc-lg"></i> &nbsp;&nbsp; Sign out </Link></li>
    } else {
      return <li class="sidebar-li" ><Link to="/user-login"> <i class="zmdi zmdi-sign-in zmdi-hc-lg"></i> &nbsp;&nbsp; Sign in </Link></li>
    }
  }

  return (

    <nav class="sidebar-parent" id="sidebar-parent-id">

      <nav class="hamburger" id="hamburger-id">
        <ul>
          <li id="hamburger-image" class="sidebar-li-hamburger-image" onClick={showOrHideSideBar}><a href="#"><svg xmlns="http://www.w3.org/2000/svg" height="26px" viewBox="0 -960 960 960" width="26px" fill="#5f6368"><path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" /></svg></a></li>
          <li id="hamburger-cancel-image" class="sidebar-li-hamburger-image-cancel" onClick={showOrHideSideBar}><a href="#"><svg xmlns="http://www.w3.org/2000/svg" height="26px" viewBox="0 -960 960 960" width="26px" fill="#5f6368"><path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z" /></svg></a></li>
        </ul>
      </nav>

      <nav class="sidebar" id="sidebar-id">
        <ul>
          {/*<li class="sidebar-li" onClick={showSideBar}><a href="#"><svg xmlns="http://www.w3.org/2000/svg" height="26px" viewBox="0 -960 960 960" width="26px" fill="#5f6368"><path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z"/></svg></a></li> */}
          <li class="sidebar-li"><Link to="/">Zocdoc Homepage</Link></li>
          <li class="sidebar-li"><Link to="/search"> Doctor Search</Link></li>
          
          {/*
          {(()=>{if(isAuthenticated())
                      return <li class="sidebar-li"><Link to="/final-doctor-register"> Doctor Registration Page</Link></li>;
          })()}
                      */}

          {showUserRegisterOrProfileManageLink()}
          {showDoctorRegisterOrProfileManageLink()}
          
          {showLoginOrLogoutPageLink()}

          {/* <li class="sidebar-li"><Link to="/user-register-success"> User Registration Success Page(Common for everyone)</Link></li> */}
          
          {/* <li class="sidebar-li"><a href="#"> About us</a></li> */}
          {/* <li class="sidebar-li"><a href="#"> Contact</a></li> */}

          <li class="sidebar-li"><Link to="/">New Homepage</Link></li>
          <li class="sidebar-li"><Link to="/new-search"> New search</Link></li>
          <li class="sidebar-li"><Link to="/new-user-register"> Sign Up</Link></li>
          <li class="sidebar-li"><Link to="/new-doctor-register"> New Doctor Registration</Link></li>
          
        </ul>
      </nav>

    </nav>

  );
};

export default HamburgerComponent;