import _ from 'lodash';

import Constants from '../../../Constants';
import utils from '../../../utils';

const doctorRegistrationUrl = process.env.REACT_APP_JAVA_BASE_URL + Constants.DOCTOR_REGISTRATION_REL_URL;


export default async function updateDoctorData(e, doctorId) {
  
    // Prevent the browser from reloading the page
    e.preventDefault();

    // Read the form data
    const form = e.target;
    const formData = new FormData(form);
    // Or you can work with it as a plain object:
    
    
   

    const formJson = utils.myCustomFormJsonParser(formData.entries());
    //const formJson = Object.fromEntries(formData.entries());
    // "formJson is -->");
    // formJson);

    let payloadObj = payloadFromFormData(formJson);
    // 'Sid transformed payload is : ' );
    // JSON.stringify(payloadObj));

    let resp = await callUpdateApiHandler(doctorRegistrationUrl + '?' + 'doctorId=' + doctorId, payloadObj);

    // 'SID THiS IS THE API RESP');
    // resp);

    return resp;
  }

  function payloadFromFormData(form){
    let requestBody = {
      displayName: form.displayName,
      //profileImage: form.profile_image,
      practice: form.practice,
      aboutUs: form.aboutUs,
      languagesSpoken: form.languages_spoken,
      profession: form.profession
    };

    let center = {
        id: form['center.id'],
        doctorId: form['center.doctorId'],
        countryId: form['center.countryId'],
        stateId: form['center.stateId'],
        cityId: form['center.cityId'],
        address: form['center.address'],
        street: form['center.street'],
        area: form['center.area'],
        pincode: form['center.pincode'],
        phone: form['center.phone']
    }

    if(center.id)
      requestBody.centers = [center];

    return requestBody;
  }

  /*

  {
    "id": 8,
    "userId": null,
    "displayName": "Dr. Sandeep Sahay",
    "profileImage": null,
    "rating": null,
    "ratingCount": null,
    "centers": [
        {
            "id": 11,
            "doctorId": 8,
            "countryId": 1,
            "stateId": 3,
            "cityId": 2,
            "address": "1111111",
            "street": "Alpha2 main road",
            "area": "Alpha2",
            "pincode": 201304,
            "lat": 11.11,
            "lng": 22.22,
            "phone": "919891166236",
            "createdAt": "2024-07-20T06:59:23.000+00:00",
            "updatedAt": "2024-07-20T06:59:23.000+00:00",
            "status": 1
        }
    ],
    "practice": null,
    "aboutUs": null,
    "createdAt": "2024-07-20T06:59:22.000+00:00",
    "updatedAt": "2024-07-20T06:59:22.000+00:00",
    "status": 1
  }
*/

  async function callUpdateApiHandler(url, body) {
    let resp = await callUpdateApi(url, body);

    let respJson = await resp.json();

    if (resp && resp.status === 200)
      return { success: true, respJson: respJson };
    else
      return { success: false, respJson: respJson };
  }

  const callUpdateApi = async (url, body) => {

    // Post with fetchAPI
    const response = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
    let resp = await response;

    return resp;
  }