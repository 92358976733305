import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import './../css/UserRegisterComponent.css'
import Constants from '../Constants';
import { useNavigate } from "react-router-dom";


export default function UserRegisterComponent() {

  const [searchTextTop, setSearchTextTop] = useState("Please register your details.");

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");

  const templateApiUrl = process.env.REACT_APP_JAVA_BASE_URL + process.env.REACT_APP_TEMPLATE_API_REL_URL
  const userRegistrationUrl = process.env.REACT_APP_JAVA_BASE_URL + process.env.REACT_APP_USER_REGISTRATION_REL_URL;

  const navigate = useNavigate();
  const gotToNewPage=()=>{
    navigate("/user-register-success");
  }

  const fetchTemplateApiData = async (url) => {
 

    const data = await callTemplateApi(url);
    setCountries(data.countryData);
  }

  const callTemplateApi = async (url) => {

    const response = await fetch(url);
    const body = await response.json();
    //body);
    return body;
  }

  // componentDidMount
  useEffect(() => {
    fetchTemplateApiData(templateApiUrl)
  }, [])


  const handleCountryChange = (e) => {
    setCountry(e.target.value);

    let countryIdx = countries.findIndex(country => country.id == e.target.value);

    setStates(countries[countryIdx].states);
    setCities("");

    setState("");
    setCity("");
  };

  const handleStateChange = (e) => {
    setState(e.target.value);

    let stateIdx = states.findIndex(state => state.id == e.target.value);

    setCities(states[stateIdx].cities);
    setCity("");
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
  };



  async function handleFormSubmit(e) {
 

    // Prevent the browser from reloading the page
    e.preventDefault();

    // Read the form data
    const form = e.target;
    const formData = new FormData(form);
    // Or you can work with it as a plain object:
    const formJson = Object.fromEntries(formData.entries());


    let payloadObj = payloadFromFormData(formJson);


    setSearchTextTop("Registration submitted for you user. Please check your email for next steps.");
    let resp = await callRegisterHandler(userRegistrationUrl, payloadObj);

   

    if (resp.success) {
      setSearchTextTop("Registration submitted. Please check your email for next steps.");
      gotToNewPage();
    } else {
      setSearchTextTop("Registration failed. Some issue. Please contact us or try again later");
      alert("Registration failed. Some issue. Please contact us or try again later");
    }

  }

  function payloadFromFormData(form){
    let requestBody = {
      firstname: form.firstname,
      lastname: form.lastname,
      dob: form.dob,
      sex: form.sex,
      email: form.email,
      password: form.password,
      phone: form.phone,
      form: form.profile_image,
      countryId: form.country,
      stateId : form.state,
      cityId: form.city,
      address: form.address,
      pincode: form.pincode,
      status: 1
    };

    return requestBody;
  }
  
  function registrationSuccessFunc(){
    // let div = document.getElementById("user-registration-form-id")
    // div.replaceChildren();

  }

  async function callRegisterHandler(url, body) {

    let resp = await callRegisterApi(url, body);

    if (resp && resp.status === 200)
      return { success: true };
    else
      return { success: false };

  }

  const callRegisterApi = async (url, body) => {

    // Post with fetchAPI
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
    let resp = await response;

    return resp;
  }

  function passwordShowClickHandler(){


    const passwordField = document.getElementById("password");
    const togglePassword = document.querySelector(".password-toggle-icon-span i");

    //const togglePassword = document.getElementById("password-icon-click");


      if (passwordField.type === "password") {
        passwordField.type = "text";
        togglePassword.classList.remove("zmdi-eye");
        togglePassword.classList.add("zmdi-eye-off");
      } else {
        passwordField.type = "password";
        togglePassword.classList.remove("zmdi-eye-off");
        togglePassword.classList.add("zmdi-eye");
      }

  }
  
  return (
    <div id="user-registration-wrapper-id" class="user-registration-wrapper">

      <div id="user-registration-form-id" class="user-registration-form">

        <h1>{searchTextTop}</h1>

        <form class="form-horizontal" onSubmit={handleFormSubmit} method="post">

          <div class="user-registration-item-div">
            <label class="labelc" for="firstname">First name</label><br />
            <i class="zmdi zmdi-hc-lg"></i>
            <input type="text" id="firstname" name="firstname" class="user-reg-textbox" placeholder="Please enter first name" />
          </div>

          <div class="user-registration-item-div">
            <label class="labelc" for="lastname">Last name</label>
            <i class="zmdi zmdi-hc-lg"></i>
            <input type="text" id="lastname" name="lastname" class="user-reg-textbox" placeholder="Please enter last name" />
          </div>

          <div class="user-registration-item-div">
            <label class="labelc" for="dob">Date of Birth</label>
            <i class="zmdi zmdi-hc-lg"></i>
            <input type="text" id="dob" name="dob" class="user-reg-textbox" placeholder="Please enter DOB" />
          
            <div data-test="date-of-birth-input-container" class="booking-web-app__sc-5kts0k-0 fEckYs"><div role="spinbutton" aria-valuenow="21" aria-valuetext="Empty" aria-valuemin="1" aria-valuemax="31" id="react-aria3661409529-:r7:" aria-label="day" data-placeholder="true" contenteditable="true" spellcheck="false" autocapitalize="off" autocorrect="off" enterkeyhint="next" inputmode="numeric" tabindex="0" data-segment="true" class="booking-web-app__sc-1mi448a-0 hUzSFw" >dd</div><div aria-hidden="true" data-segment="true" class="booking-web-app__sc-1mi448a-0 cAfmqr">/</div><div role="spinbutton" aria-valuenow="7" aria-valuetext="Empty" aria-valuemin="1" aria-valuemax="12" id="react-aria3661409529-:rb:" aria-label="month" data-placeholder="true" contenteditable="true" spellcheck="false" autocapitalize="off" autocorrect="off" enterkeyhint="next" inputmode="numeric" tabindex="0" data-segment="true" class="booking-web-app__sc-1mi448a-0 hUzSFw" >mm</div><div aria-hidden="true" data-segment="true" class="booking-web-app__sc-1mi448a-0 cAfmqr">/</div><div role="spinbutton" aria-valuenow="2024" aria-valuetext="Empty" aria-valuemin="1" aria-valuemax="9999" id="react-aria3661409529-:rf:" aria-label="year" data-placeholder="true" contenteditable="true" spellcheck="false" autocapitalize="off" autocorrect="off" enterkeyhint="next" inputmode="numeric" tabindex="0" data-segment="true" class="booking-web-app__sc-1mi448a-0 hUzSFw" >yyyy</div></div>

          </div>

          <div class="user-registration-item-radio-div">
            <label class="labelc" for="sex">Sex</label>
            <i class="zmdi zmdi-hc-lg"></i>
            <div class = "user-registration-item-radio-options-div">
              <input type="radio" id="male" name="sex" value="1" />
                <label class="radio-class-text" for="male">Male</label>
              <input type="radio" id="female" name="sex" value="2" />
                <label class="radio-class-text" for="female">Female</label>
              <input type="radio" id="would_not_disclose" name="sex" value="3" />
                <label class="radio-class-text" for="would_not_disclose">Not disclose</label>
            </div>
          </div>

          <div class="user-registration-item-div">
            <label class="labelc" for="email">E-mail address</label>
            <i class="zmdi zmdi-hc-lg"></i>
            <input type="text" id="email" name="email" class="user-reg-textbox" placeholder="This email will be used to login" />
          </div>

          <div class="user-registration-item-div">
            <label class="labelc" for="password">Password</label>
            <i class="zmdi zmdi-hc-lg"></i>

            <div class = "user-registration-item-password-box-div">
              <input type="password" id="password" name="password" class="user-reg-textbox-password" placeholder="Please enter password. I will change it to text password" />
              <span onClick={passwordShowClickHandler} class="password-toggle-icon-span"><i id="password-icon-click" class="zmdi zmdi-eye"></i></span>
            </div>
          </div>

          <div class="user-registration-item-div">
            <label class="labelc" for="phone">Phone number</label>
            <i class="zmdi zmdi-hc-lg"></i>
            <input type="text" id="phone" name="phone" class="user-reg-textbox" placeholder="Please enter your phone number" />
          </div>

          <div class="user-registration-item-div">
            <label class="labelc" for="profileImage">Profile Image selector</label>
            <i class="zmdi zmdi-hc-lg"></i>
            <input type="text" id="profileImage" name="profileImage" class="user-reg-textbox" placeholder="Please enter your phone number" />
          </div>


          <div class="user-registration-item-div">
            <label class="labelc" for="country">Country</label>
            <i class="zmdi zmdi-hc-lg"></i>
            {/*"haha chaaap diya country")*/}
            <select onChange={handleCountryChange} id="country" name="country" class="user-reg-textbox" placeholder="Please select your country" >
              <option value="">Select Country</option>
              {countries ? (countries.map(
                (country) => {
                 
                  return (
                    <option key={country.id} value={country.id} > {country.name} </option>
                  )
                })) : null

              }
            </select>
          </div>

          <div class="user-registration-item-div">
            <label class="labelc" for="state">State</label>
            <i class="zmdi zmdi-hc-lg"></i>
            {/*"haha chaaap diya state")*/}
            <select disabled={country == ""} onChange={handleStateChange} id="state" name="state" class="user-reg-textbox" placeholder="Please select your state" >
              <option value="">Select State</option>
              {states ? (states.map(
                (state) => {
                 
                  return (
                    <option key={state.id} value={state.id} > {state.name} </option>
                  )
                })) : null

              }
            </select>
          </div>

          <div class="user-registration-item-div">
            <label class="labelc" for="city">City</label>
            <i class="zmdi zmdi-hc-lg"></i>
            {/*"haha chaaap diya city")*/}
            <select disabled={state == ""} onChange={handleCityChange} id="city" name="city" class="user-reg-textbox" placeholder="Please select your city" >
              <option value="">Select City</option>
              {cities ? (cities.map(
                (city) => {
                 
                  return (
                    <option key={city.id} value={city.id} > {city.name} </option>
                  )
                })) : null

              }
            </select>
          </div>


          <div class="user-registration-item-div">
            <label class="labelc" for="address">Address</label>
            <i class="zmdi zmdi-hc-lg"></i>
            <input type="text" id="address" name="address" class="user-reg-textbox" placeholder="Please enter your address" />
          </div>

          <div class="user-registration-item-div">
            <label class="labelc" for="pincode">Pincode</label>
            <i class="zmdi zmdi-hc-lg"></i>
            <input type="text" id="pincode" name="pincode" class="user-reg-textbox" placeholder="Please enter your address" />
          </div>

          <div class="user-registration-item-div">
            <button type="submit" class="text-search-submit" id="user-register-submit" placeholder="" > Sign up</button>
          </div>

        </form>
      </div>

    </div>
  );
}