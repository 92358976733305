import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FaBars } from 'react-icons/fa';
import './Navbar.css'; 
import {useSignOut, useIsAuthenticated, useAuthUser } from 'react-auth-kit';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLanguage } from '../../homepage/components/LanguageContext';
import englighFlag from "./../../../../src/images/english_flag.svg";
import germanFlag from "./../../../../src/images/german_flag.png";

function NavBar() {
  let isAuthenticated = useIsAuthenticated();
  const authUser = useAuthUser();
  const signOut = useSignOut();
  const navigate =  useNavigate();
  const location = useLocation();
  const handleLogin = ()=>{
    navigate('/user-login', { state: { from: location } });
  }
  const { language, langStrings, toggleLanguage } = useLanguage();
  const ST = langStrings.HOMEPAGE;
  return (
    <Navbar expand="lg" fixed="top" className="bg-body-tertiary custom-navbar">
      <Container fluid>
        <Navbar.Brand href="/" className="navbar-brand">
        <img src='/BrowserIcon.png' height={40} width={40} />
        Arztme</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" className="ms-auto">
          <FaBars size={24} color="black" />  {/* Use custom icon */}
        </Navbar.Toggle>
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-2 my-lg-0" navbarScroll>
          </Nav>
          <Nav className="nav-links">
            <Nav.Link href="" className="nav-link-custom">
              {language === 'English' ? (
                <img onClick={toggleLanguage} className="navImgEng1" src={englighFlag} alt="English flag" />
              ) : (
                <img onClick={toggleLanguage} className="navImgGer1" src={germanFlag} alt="German flag" />
              )}
            </Nav.Link>
            <Nav.Link href="/new-search" className="nav-link-custom">Listing</Nav.Link>
            {isAuthenticated() && authUser().isUser?<Nav.Link href="#" onClick={() => signOut()} className="nav-link-custom">Logout</Nav.Link>:<Nav.Link onClick={handleLogin} className="nav-link-custom">Login/SignUp</Nav.Link>  }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
