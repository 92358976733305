import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import Constants from '../../../../Constants';
import { useSignIn, useIsAuthenticated, useAuthUser } from 'react-auth-kit';
import { useNavigate, Link } from "react-router-dom";
import utils from './../../../../utils'

export function getCompoDataMultiselect(id, title, cta, ctaToggle, helptext, valueSelected, valueSuperset, selectDefaultValue) {
  return {
    componentType: Constants.ENUMS.EDIT_COMPONENT_TYPES.MULTISELECT,
    id: id,
    title: title,
    cta: cta,
    ctaToggle: ctaToggle,
    helptext: helptext,
    valueSelected: valueSelected,
    valueSuperset: valueSuperset,
    selectDefaultValue: selectDefaultValue
  }
};

export default function ToggledItemDisplayEdit(props) {

  const [doctorData, setDoctorData] = useState(props.doctorData);
  let [toggler, setToggler] = useState(1);

  useEffect(() => {
    setDoctorData(props.doctorData);
  }, [props.doctorData]);

  function handleCtaClick() {
    //'SID_LOG inside setState variable value earlier.  : ' + eval(toggler) + '. Incrementing by 1 now');
    setToggler(++toggler);
  }

  function valueShowMultiselect(){
    let ans = utils.getStringFromEntityIdAndTemplateData(props.data.valueSelected, props.data.valueSuperset);
    return ans;
  }
  
  async function toggleAndSubmit(e){
    await props.handleFormSubmit(e);
    handleCtaClick();
  }

  switch(props.data.componentType){
    case Constants.ENUMS.EDIT_COMPONENT_TYPES.TEXT: 
    
    if (eval(toggler) % 2 == 1) {
      return (<div class="manage-profile-item">
        <div class="title"><span> {props.data.title} </span></div>
        <div class="cta"><span onClick={handleCtaClick}> {props.data.cta} </span></div>
        <div class="value"><span> {props.data.value} </span></div>
        <div class="helptext"><span> {props.data.helptext} </span></div>
      </div>);
    } else {
      return (
        <div class="manage-profile-item">
          <div class="title"><span> {props.data.title} </span></div>
          <div class="cta"><span onClick={handleCtaClick}> {props.data.ctaToggle} </span></div>

          <form class="user-profile-update-form" onSubmit={toggleAndSubmit} method="post">
            <div class="user-profile-update-div">
              <label class="labelc" htmlFor={props.data.id}></label>
              <i class="zmdi zmdi-hc-lg"></i>
              <input type="text" id={props.data.id} name={props.data.id} class="user-profile-update-textbox" placeholder="Please enter" />
            </div>
            <div class="user-profile-update-div">
              <button type="submit" class="user-profile-update-submit" id="user-profile-update-submit" placeholder="" > Save </button>
            </div>
          </form>
        </div>
      );
    }
    break;

    case Constants.ENUMS.EDIT_COMPONENT_TYPES.MULTISELECT: 
    if (eval(toggler) % 2 == 1) {
      return (<div class="manage-profile-item">
        <div class="title"><span> {props.data.title} </span></div>
        <div class="cta"><span onClick={handleCtaClick}> {props.data.cta} </span></div>
        <div class="value"><span> {valueShowMultiselect()} </span></div>
        <div class="helptext"><span> {props.data.helptext} </span></div>
      </div>);
    } else {
      return (
        <div class="manage-profile-item">
          <div class="title"><span> {props.data.title} </span></div>
          <div class="cta"><span onClick={handleCtaClick}> {props.data.ctaToggle} </span></div>

          <form class="user-profile-update-form" onSubmit={toggleAndSubmit} method="post">
            <div class="user-profile-update-div">
              <label class="labelc" htmlFor={props.data.id}></label>
              <i class="zmdi zmdi-hc-lg"></i>
              
              <select multiple={true} name={props.data.id} class="user-reg-textbox" placeholder="Please select your state" style={{"height" : "100%", "width" : "100%"}}>
                <option value="">{props.data.selectDefaultValue}</option>
                {props.data.valueSuperset ? (props.data.valueSuperset.map(
                  (each) => {
                    //"bhai ye hai individual unit ka naam" + { each });
                    return (
                      <option key={each.id} value={each.id} > {each.name} </option>
                    )
                  })) : null

                }
              </select>

            </div>
            <div class="user-profile-update-div">
              <button type="submit" class="user-profile-update-submit" id="user-profile-update-submit" placeholder="" > Save </button>
            </div>
          </form>
        </div>
      );
    }
    break;


  }
}