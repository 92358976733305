import React from 'react';
import { Nav } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useLanguage } from '../../../homepage/components/LanguageContext';


const ProfileSidebar = ({user,docLocation,setUser,toggleSidebar}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { langStrings} = useLanguage();
  const ST = langStrings.DOCEDIT.SIDEBAR;
  return (
    <div className="p-3" style={{ minWidth: '250px' }}>
      <Nav className="flex-column">
        <Nav.Link onClick={() =>{ toggleSidebar(); navigate(`/edit-doctor-profile/${id}`, { state: { user,docLocation} })}}>{ST.label}</Nav.Link>
        <Nav.Link onClick={() => {toggleSidebar(); navigate(`/edit-doctor-profile/${id}/contact-info`, { state: { user ,docLocation} })}}>{ST.label1}</Nav.Link>
        <Nav.Link onClick={() => {toggleSidebar();navigate(`/edit-doctor-profile/${id}/about-info`, { state: { user ,docLocation} })}}>{ST.label2}</Nav.Link>
        <Nav.Link onClick={() => {toggleSidebar();navigate(`/edit-doctor-profile/${id}/insurance-info`, { state: { user ,docLocation} })}}>{ST.label3}</Nav.Link>
        <Nav.Link onClick={() => {toggleSidebar();navigate(`/edit-doctor-profile/${id}/education-info`, { state: { user ,docLocation} })}}>{ST.label4}</Nav.Link>
        <Nav.Link onClick={() => {toggleSidebar();navigate(`/edit-doctor-profile/${id}/opening-times`, { state: { user ,docLocation} })}}>{ST.label5}</Nav.Link>
        {/* <Nav.Link onClick={() => navigate(`/edit-doctor-profile/${id}/social-info`, { state: { user ,docLocation} })}>Social Info</Nav.Link> */}
      </Nav>
    </div>
  );
};

export default ProfileSidebar;
