import React, { useState, useEffect } from 'react';
import { IoSearch, IoLocation } from "react-icons/io5";
import Select from 'react-select';
import { useNavigate,useLocation } from 'react-router-dom';
import axios from 'axios';  
import debounce from 'lodash/debounce';  
import { useLanguage } from '../../homepage/components/LanguageContext';
import EditableSelect from './EditableSelect';

const SearchBar = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const { language,langStrings} = useLanguage();
  const ST = langStrings.HOMEPAGE;

  const defaultLocationOptions = [
    { value: 'Vienna', label: 'Vienna' },
    { value: 'Salzburg', label: 'Salzburg' },
    { value: 'Innsbruck', label: 'Innsbruck' },
    { value: 'Graz', label: 'Graz' },
    { value: 'Linz', label: 'Linz' },
    { value: 'Klagenfurt', label: 'Klagenfurt' },
    { value: 'St_pölten', label: 'St. Pölten' },
    { value: 'Bregenz', label: 'Bregenz' },
    { value: 'Villach', label: 'Villach' },
    { value: 'Wels', label: 'Wels' },
  ];

  const [specialtyOptions, setSpecialtyOptions] = useState();
  const [locationOptions, setLocationOptions] = useState(defaultLocationOptions);
  const [selectedSpecialty, setSelectedSpecialty] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectType,setSelectedType] = useState('');
  const [urlNikalo, UrlSetKaro] = useState(''); 
  const [placeholderText, setPlaceholderText] = useState('Searching for a Doctor or Therapist');
  const [specialtyInputValue, setSpecialtyInputValue] = useState(''); 
  const [locationInputValue, setLocationInputValue] = useState(''); 

  let SUGGEST_URL = process.env.REACT_APP_JAVA_BASE_URL+process.env.REACT_APP_SUGGEST_URL;
  const fetchDefaultSpecialtyOptions = async () => {
      try {
        const response = await axios.get(`${SUGGEST_URL}`);
        const data = response.data && response.data.data ? response.data.data : [];
        const options = data.map(item => ({
          value: item.value,
          label: item.title,
          url: item.url,
        }));
        setSpecialtyOptions(options);
      } catch (error) {
        console.error('Error fetching default specialty options:', error);
        setSpecialtyOptions([{ value: '', label: 'Error fetching options' }]);
      }
  };

  useEffect(()=>{
    fetchDefaultSpecialtyOptions();
  }, []);
  
  const fetchSpecialtyOptions = debounce((inputValue) => {
    if (inputValue) {
      axios.get(`${SUGGEST_URL}?text=${inputValue}&type=searchkey`)
        .then(response => {
          const data = response.data && response.data.data ? response.data.data : [];   
          if (data.length > 0) {
            const options = data.map(item => ({
              value: item.value,  
              label: item.title,
              url: item.url
            }));
            setSpecialtyOptions(options);
          } else {
            setSpecialtyOptions([{ value: '', label: 'No options found' }]);
          }
        })
        .catch(error => {
          console.error('Error fetching specialty options:', error);
          setSpecialtyOptions([{ value: '', label: 'Error fetching options' }]); 
        });
    } else {
      fetchDefaultSpecialtyOptions();
    }
  }, 300);
  
  const fetchLocationOptions = debounce((inputValue) => {
    if (inputValue) {
      axios.get(`${SUGGEST_URL}?text=${inputValue}&type=locationkey`)
        .then(response => {
          const data = response.data && response.data.data ? response.data.data : [];
          if (data.length > 0) {
            
            const options = data.map(item => ({
              value: item.value,  
              label: item.title, 
            }));
            setLocationOptions(options);
            setSelectedType(data[0].type);
          } else {
            setLocationOptions([{ value: '', label: 'No options found' }]);
          }
        })
        .catch(error => {
          console.error('Error fetching location options:', error);
          setLocationOptions([{ value: '', label: 'Error fetching options' }]); 
        });
    } else {
      setLocationOptions(defaultLocationOptions);
    }
  }, 100);

  const [specialtyPlaceholder, setSpecialtyPlaceholder] = useState(ST.SEARCH_SECTION.INPUT.label3);
  const [locationPlaceholder, setLocationPlaceholder] = useState(ST.SEARCH_SECTION.INPUT.label1);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const searchKey = params.get('searchkey');
    const speciality = params.get('speciality');
    const locationKey = params.get('locationkey');
    const placeText = ST.SEARCH_SECTION.INPUT.label3;
    setSpecialtyPlaceholder(searchKey || speciality || placeText);
    setLocationPlaceholder(locationKey || ST.SEARCH_SECTION.INPUT.label1);
  }, [location,language,window.innerWidth]);

  let Myurl = '/new-search?';
  const leJaoFiltersPar = (e) => {
    e.preventDefault(); 
    const params = new URLSearchParams(location.search);
    const searchKey = params.get('searchkey');
    const speciality = params.get('speciality');
    const locationKey = params.get('locationkey');
    const specialty = selectedSpecialty ? selectedSpecialty.value : searchKey;
    const loc = selectedLocation ? selectedLocation.value : locationKey;
    const url = urlNikalo;
    Myurl += `searchkey=${encodeURIComponent(specialty)}`
    Myurl += `&locationkey=${encodeURIComponent(loc)}`;
    if(url){
      Myurl +=  `&${url}`;
    }
    navigate(Myurl);
  }

  return (
    <div className="container">
      <div className="row justify-content-center ">
        <form className="row g-3 " method="get" style={{padding:'0px'}} >
          <div className="col-lg-4 col-md-6 col-sm-12 position-relative mb-3 no-gap">
            <IoSearch style={{ zIndex: '99', position: 'absolute', left: '14px', top: '50%', transform: 'translateY(-50%)', fontSize: '35px', color: '#f35b2a' }} />
            <EditableSelect 
              onInputChange={(newValue) => {
                setSpecialtyInputValue(newValue); 
                fetchSpecialtyOptions(newValue); 
              }}
              onChange={(option) => {
                setSelectedSpecialty(option);
                setSpecialtyInputValue(option ? option.label : '');
                setSelectedType(option ? option.type : '');
                UrlSetKaro(option ? option.url : '');
              }}
              options={specialtyOptions}
              placeholder={specialtyPlaceholder}
            />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 position-relative mb-3 no-gap">
            <IoLocation style={{ zIndex: '1', position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', fontSize: '35px', color: '#f35b2a' }} />
            <EditableSelect
              options={locationOptions}
              onInputChange={(newValue) =>{
                setLocationInputValue(newValue);
                fetchLocationOptions(newValue)
              }}
              onChange={(option) =>{
                setLocationPlaceholder(option ? option.label : '')
                setSelectedLocation(option);
                setLocationInputValue(option ? option.label : '');
                setSelectedType(option ? option.type : '');
                UrlSetKaro(option ? option.url : '');
              }}
              placeholder={locationPlaceholder}
            />
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 no-gap width-reduce">
            <button 
              onClick={leJaoFiltersPar}
              type="submit" 
              name="text-search-submit-btn" 
              className="btn btn-primary w-100"
              style={{ height: '60px', fontSize: '25px', fontWeight: 'bold', backgroundColor: '#f35b2a', border: 'solid', borderWidth: '1px', borderColor: 'black', borderRadius: '0px',boxShadow:'none' }}
            >
              {ST.SEARCH_SECTION.INPUT.label2}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SearchBar;


