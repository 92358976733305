import Constants from '../../../Constants';

const doctorDataGetUrl = process.env.REACT_APP_JAVA_BASE_URL + process.env.REACT_APP_DOCTOR_DATA_GET_REL_URL;

export default async function getDoctorData(doctorId) {
  let reqUrl = reqUrlFromQuery(doctorDataGetUrl,doctorId);

  let resp = await callGetDataHandler(reqUrl);

  // 'Doctor api get response is');
  // resp);

  return resp;
}

function reqUrlFromQuery(userLoginUrl, doctorId) {
    return userLoginUrl + '?doctorId=' + doctorId;
}

async function callGetDataHandler(url) {

  let resp = await callUserGetApi(url);

  let respJson = await resp.json();
  // 'YE HUYI NAA BAAT');
  // respJson);

  if (resp && resp.status === 200)
    return { success: true, respJson: respJson };
  else
    return { success: false, respJson: respJson };

}

const callUserGetApi = async (url) => {

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  });
  let resp = await response;

  return resp;
}
