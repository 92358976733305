import { useState, useEffect } from 'react';
import utils from './../../../utils.js';
import imgRatingStar from "./../../../rating_star.png"
import { useParams } from 'react-router';
import Constants from './../../../Constants.js';
import { useIsAuthenticated, useAuthUser } from 'react-auth-kit';
import handleSubmit from '../services/getSearch.js';
import SearchFilterFragment from './doctor_search/SearchFilterFragment.js'
import './css/DoctorSearch.css'
import RenderMap from './doctor_search/RenderMap.js';


export default function DoctorSearch() {
  const { profession } = useParams();

  const [count, setCount] = useState(0);
  const [users, setUsers] = useState({doctorList:[],filterList:[]});
  const [searchTextTop, setSearchTextTop] = useState("Find doctors.");

  const [selectedDoc, setSelectedDoc] = useState(0);

  let isAuthenticated = useIsAuthenticated();
  const authUser = useAuthUser();

  const profileImagePrefix = process.env.REACT_APP_JAVA_BASE_URL + process.env.REACT_APP_USER_PROFILE_IMAGE_CONTENT_REL_PATH;

  useEffect(() => {
    if(isAuthenticated() ){
      setSearchTextTop("Welcome " + authUser().userDto.firstname + ".   Start searching for top doctors near you");
    }
  }, [])

  /*
  let searchObj;
  if (profession) {
    //handleSubmit(profession);

    searchObj = { searchkey: 'wolf', location: 'salzburg' };
    dataCrunchAndCallApi(searchObj);
  }
    */


  async function handleFormSubmit(e) {
    
    var resp;
    
    //PRD: If nothing is set in textField, set it by default to General Practitioner.

    if(document.getElementById("searchkey").value == '')
        document.getElementById("searchkey").value = "General Practitioner";

    resp = await handleSubmit(e);

    setCount(count + 1);
    setSearchTextTop("");
    
    if (resp.success) {
      setUsers(resp.respJson.data);
    } else {
      alert('Could not fetch listing due to some error');
    }
  }

  function getDocLocAddressStr(loc){
    let str='';
    let ar = [];
    if(loc.address)
        ar.push(loc.address);
    if(loc.street)
        ar.push(loc.street);
    if(loc.area)
        ar.push(loc.area);
    if(loc.cityStr)
        ar.push(loc.cityStr)


    str= ar.join(', ');
    return str;
  }

  function getDocCountMatchingStr(docList){

    if(!docList  || docList.length == 0)
        return '';
    else 
        return docList.length + " Doctors matched";
  }

  function unsetAllDocs(){
    users.doctorList.forEach((doc) => {
        doc.isSelected = false;
    })
  }

  return (
    <div class = "dummy-wrapper-srp-maps">
    <div id="searchbar-component-wrapper-div" class="searchbar-component-wrapper">

      <div id="search-form-id" class="search-form">

        <h1>{searchTextTop}</h1>

        <form class="form-horizontal" onSubmit={handleFormSubmit} method="get">

          <div class="search-searchkey-div">
            <i class="zmdi zmdi-search zmdi-hc-lg"></i>
            <input type="text" name="searchkey" class="text-search-searchkey" id="searchkey" placeholder="Searching for? Eg. Dentist or doctor name" />
          </div>

          <div class="search-searchkey-div">
            <i class="zmdi zmdi-pin zmdi-hc-lg"></i>
            <input type="text" name="location" class="text-search-searchkey" id="location" placeholder="Location (City / Area)" />
          </div>

          <div class="search-searchkey-div">
            <button type="submit" name="text-search-submit-btn" class="text-search-submit-btn" id="text-search-submit-btn-id" placeholder="Location (City / Area)" > Search doctors near you {count}</button>
          </div>

        </form>
      </div>

      <div id="search-filters-id" class = "search-filters">
        <SearchFilterFragment handleFormSbt={handleFormSubmit} srpData={users}/>
      </div>

      <div id="search-results-id" class="search-results">
        <h5> {getDocCountMatchingStr(users.doctorList)} </h5>
        {users.doctorList.map((user) => (

          <div id="search-result-list-item-id" class="search-result-list-item" onClick={(e) => {unsetAllDocs(); user.isSelected = true; setSelectedDoc(user.id); }}>

            <div id="image-profile-id" class="image-profile-div">
              <picture>
                {/*<img src = "https://images.docfinder.at/QtvVRz-G0e/dr-wernfried-gappmayer-frauenarzt-5020-salzburg-2cc3.jpg?d=50X150" class="doc-listing-profile-logo" />*/}
                <img src={profileImagePrefix + user.profileImage} class="doc-listing-profile-logo" />
              </picture>
            </div>

            <div id="base-info-id" class="base-info-div">
              <h2> {user.displayName} </h2>
              <h3> {user.professionList.join(', ')} </h3>
            
              {user.centers && user.centers.length ? <h4> {getDocLocAddressStr(user.centers[0])} </h4> : <h4></h4>}
            {/*
              <div id="rating-id" class="rating-class-div">
                <picture>
                  <img src={imgRatingStar} className="img-rating-star" alt="Rating" />
                </picture>
                <p class="p1"> {user.rating}</p>
                <p class="p2">   ({user.ratingCount}) </p>
              </div>
              */}
            </div>

          </div>

        ))}
      </div>
        
      
      {/*
      <div id="maps-id" class="maps">
        <iframe width="600" height="450" loading="lazy" allowfullscreen
        src="https://www.google.com/maps/embed/v1/search?q=salzburg&key=AIzaSyCvbO98mUlgO-JQ6Df3VnYQOhVe8Tp-okU"></iframe>
      </div>
      */}

    </div>

    <RenderMap  srpData={users} selectedDoc={selectedDoc}/>

    </div>


  );
}